import React, {useState} from 'react';
import {TextField, Autocomplete, FormControl, InputLabel, NativeSelect} from '@mui/material';
import {UNIT_OF_MEASURE_OPTIONS, ITEM_TYPES, STORAGE_REQ} from 'pages/ItemEdit/ItemEdit.list';
import {NumberFormatInteger, DecimalFormatCustom} from 'components/primitives';
import {toShortDateOnly} from 'common/utils/DateTime';
import useStyles from './BaysView.styles';

export interface IBaysItemDetailsFormProps {
  readOnly: boolean;
  results: any;
  pvDataID: string;
  totalDistributed?: number;
  onResultsChange: (field: string, newValue: any) => void;
}

const BaysItemDetailsForm: React.FC<IBaysItemDetailsFormProps> = ({
  readOnly = true,
  results,
  totalDistributed = 0,
  pvDataID,
  onResultsChange,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState<string[]>(ITEM_TYPES);
  const getACValue = (value: string) => {
    const idx = ITEM_TYPES.indexOf(value);
    return idx > -1 ? ITEM_TYPES[idx] : value ? value : '';
  };

  return (
    <>
      <div className="layout horizontal center section-title form-margin">Item Details</div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <Autocomplete
          freeSolo
          className={classes.wrapper}
          options={options}
          value={getACValue(results?.pvItemType || '')}
          readOnly={readOnly}
          onChange={(event: any, newValue: string | null) => {
            onResultsChange('pvItemType', newValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputLabelProps={{shrink: true}}
                required={true}
                label="Item type"
                placeholder={!readOnly ? 'Select item type' : ''}
                variant="standard"
                onChange={(e) => (!readOnly ? onResultsChange('pvItemType', e.target.value) : null)}
              />
            );
          }}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <TextField
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          label="Manufacturer"
          variant="standard"
          placeholder={!readOnly ? 'Enter Manufacturer' : ''}
          value={results?.pvManufacturer || ''}
          InputProps={{
            readOnly,
          }}
          onChange={(e) => onResultsChange('pvManufacturer', e.target.value)}
        />
        <TextField
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          label="Product/Lot #"
          variant="standard"
          placeholder={!readOnly ? 'Enter product/lot #' : ''}
          value={results?.pvLotNumber || ''}
          InputProps={{
            readOnly,
          }}
          onChange={(e) => onResultsChange('pvLotNumber', e.target.value)}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <TextField
          error={!readOnly ? !results?.pvQuantityReceived : false}
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          required={!readOnly ? true : false}
          label="Quantity Received"
          variant="standard"
          placeholder={!readOnly ? 'Enter quantity received' : ''}
          value={results?.pvQuantityReceived || 0}
          InputProps={{
            inputComponent: NumberFormatInteger,
            readOnly,
          }}
          onChange={(e) => onResultsChange('pvQuantityReceived', e.target.value)}
        />
        <TextField
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          label="Quantity distributed"
          variant="standard"
          placeholder={!readOnly ? 'Enter quantity distributed' : ''}
          // value={results?.pvQuantityDistributed || ''}
          value={totalDistributed}
          InputProps={{
            inputComponent: NumberFormatInteger,
            readOnly: true,
          }}
          hidden={!pvDataID}
        />
        <FormControl
          variant="standard"
          className={classes.wrapper}
          size="small"
          sx={{paddingBottom: '8px'}}>
          <InputLabel shrink>Unit of measure</InputLabel>
          <NativeSelect
            value={results?.pvUnitOfMeasure || ''}
            placeholder={!readOnly ? 'Select unit of measure' : ''}
            className={classes.selectWrapper}
            sx={{minHeight: 'auto'}}
            disabled={readOnly}
            onChange={(e) => onResultsChange('pvUnitOfMeasure', e.target.value)}>
            {UNIT_OF_MEASURE_OPTIONS.map((opt, index) => (
              <option
                key={index}
                value={opt}
                disabled={index === 0}>
                {opt}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        <TextField
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          label="Cost per unit"
          variant="standard"
          placeholder={!readOnly ? 'Enter cost' : ''}
          value={results?.pvReqDetCostEst || ''}
          InputProps={{
            inputComponent: DecimalFormatCustom,
            readOnly,
          }}
          onChange={(e) => onResultsChange('pvReqDetCostEst', e.target.value)}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <FormControl
          variant="standard"
          className={classes.wrapper}
          size="small"
          sx={{width: '50%', paddingBottom: '8px'}}>
          <InputLabel>Storage requirements</InputLabel>
          <NativeSelect
            value={results?.pvStorageRequirements || ''}
            className={classes.selectWrapper}
            placeholder={!readOnly ? 'Select storage requirements' : ''}
            disabled={readOnly}
            inputProps={{
              name: 'selectStorageRequirements',
            }}
            onChange={(e) =>
              !readOnly ? onResultsChange('pvStorageRequirements', e.target.value) : null
            }>
            {STORAGE_REQ.map((opt, index) => (
              <option
                key={index}
                value={opt}
                disabled={index === 0}>
                {opt}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <TextField
          multiline
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          label="Notes"
          variant="standard"
          placeholder={!readOnly ? 'Enter notes' : ''}
          value={results?.pvItemNotes || ''}
          inputProps={{
            readOnly,
          }}
          onChange={(e) => onResultsChange('pvItemNotes', e.target.value)}
        />
      </div>
    </>
  );
};

export default BaysItemDetailsForm;
