import React, {useState, useEffect} from 'react';
import {Controller, Control, UseFormSetValue} from 'react-hook-form';
import {TextField} from '@mui/material';
import {SecondaryButton} from '@zawarski/palmetto-ui-components';
import {NumberFormatInteger} from 'components/primitives';
import {BarcodeScanner, FormDialog} from 'components/fragments';
import {IBays} from 'interfaces/Bays';
import useStyle from './BaysEdit.styles';

interface BaysEditFormProps {
  control: Control<Partial<IBays>, any>;
  setValue: UseFormSetValue<Partial<IBays>>;
  fromBay: boolean;
  isItemNew: boolean;
  results: any;
  quantityDistributed: number;
  setQuantityDistributed: React.Dispatch<React.SetStateAction<number>>;
}

const BaysEditForm = (props: BaysEditFormProps) => {
  const {control, setValue, isItemNew} = props;

  const classes = useStyle();
  const [showQRDialog, setShowQRDialog] = useState<boolean>(false);

  const onFrameRead = (reading: any) => {
    try {
      if (reading && reading.length > 0) {
        setValue('pvBay', reading[0].barcodeText || '');
        setShowQRDialog(false);
      }
    } catch (error) {
      console.log('onFrameRead', error);
    }
  };

  return (
    <>
      <div className="layout horizontal center section-title form-margin">Bay Details</div>
      <div className="layout horizontal wrap">
        <Controller
          name={'pvBayQuantity'}
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              error={Boolean(error)}
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              required={true}
              label="Quantity"
              variant="standard"
              placeholder="Enter quantity"
              value={value}
              InputProps={{
                inputComponent: NumberFormatInteger,
              }}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name={'pvBay'}
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              error={Boolean(error)}
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              required={true}
              label="Bay"
              variant="standard"
              placeholder="Enter bay"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name={'pvQuantityDistributed'}
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              error={Boolean(error)}
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              required={true}
              disabled={isItemNew}
              label="Quantity Distributed"
              variant="standard"
              placeholder="Enter quantity distributed"
              value={value}
              InputProps={{
                inputComponent: NumberFormatInteger,
              }}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="layout horizontal form-margin">
        <div className="flex" />
        <SecondaryButton
          setwidth="true"
          onClick={() => setShowQRDialog(true)}>
          Scan Barcode
        </SecondaryButton>
      </div>
      <div className="layout horizontal wrap">
        <Controller
          name={'pvBayInfo'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              multiline
              label="Info"
              variant="standard"
              placeholder="Enter info"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <FormDialog
        show={showQRDialog}
        title="Scan Barcode"
        handleClose={() => {
          setShowQRDialog(false);
        }}
        handleSave={() => null}>
        <BarcodeScanner callback={onFrameRead} />
      </FormDialog>
    </>
  );
};

export default BaysEditForm;
