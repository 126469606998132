import {makeCreateActions, presetActions} from 'store/utils/Redux';
import {LocationState} from 'store/state/LocationState';

const ns = 'LOCATION';
const createLocationActions = makeCreateActions(ns);

const locationActions = createLocationActions({
  update: presetActions.makeIdentity<Partial<LocationState>>(),
  clear: presetActions.noPayload,
});

export default locationActions;
