export const UNIT_OF_MEASURE_OPTIONS = ['', 'Box', 'Case', 'Each', 'Gallon', 'Liter', 'Pallet'];

export const STATUS_OPTIONS = [
  '',
  'Ready',
  'In Use',
  'Maintenance Required',
  'Replacing Required',
  'In Transit',
];

export const ITEM_TYPES = [
  '',
  'Mask, N95',
  'Mask, Surgical',
  'Mask, Cloth',
  'Shield, Face',
  'Goggles, Safety',
  'Gloves, Nitrile (SM)',
  'Gloves, Nitrile (MD)',
  'Gloves, Nitrile (LG)',
  'Gloves, Nitrile (XL)',
  'Gowns, Isolation',
  'Coveralls, (SM)',
  'Coveralls, (MD)',
  'Coveralls, (LG)',
  'Coveralls, (XL)',
  'Sanitizer, Hand (4oz)',
  'Sanitizer, Hand (16oz)',
  'Sanitizer, Hand (1gal)',
  'Disinfect, Spray',
  'Disinfect, Cleaner',
  'Disinfect, Wipes',
  'Protection, Foot',
  'Transport Media, Tube',
  'Swabs, NP',
  'Swabs, OP',
  'Cots, Mattress',
  'Cots, Medical (REG)',
  'Cots, Medical (LG)',
  'Cots, Army',
  'Bags, Body',
  'Liners, Can',
  'Coolers (SM)',
  'Coolers (LG)',
  'Battery, AA',
  'Battery, AAA',
];

export const STORAGE_REQ = ['', 'Dry', 'Refrigirated'];

export default {
  STATUS_OPTIONS,
  STORAGE_REQ,
  UNIT_OF_MEASURE_OPTIONS,
  ITEM_TYPES,
};
