import {useForm} from 'react-hook-form';
import {useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {IEquipments} from 'interfaces/Equipments';
import {toShortDateOnly} from 'common/utils/DateTime';

function useEquipmentForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        pvItemType: yup
          .string()
          .max(45, 'Value in this input is too long.')
          .required('Equipment Name is required'),
        pvItemQuantity: yup.number().integer().required('Quantity is required').min(1),
        pvExpirationDate: yup.string().required('Due Date is required'),
        pvQuantityReceived: yup.string().nullable(),
        pvQuantityDistributed: yup.string().nullable(),
        pvRRNumber: yup.string().nullable(),
        pvStatus: yup.string().nullable(),
        pvItemNotes: yup.string().nullable(),
        pvItemSpecialInstructions: yup.string().nullable(),
        pvTechSpecs: yup.string().nullable(),
        pvStorageRequirements: yup.string().nullable(),
      }),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
    reset,
  } = useForm<IEquipments>({
    defaultValues: {
      pvItemType: '',
      pvItemQuantity: 0,
      pvExpirationDate: '',
      pvQuantityReceived: '',
      pvQuantityDistributed: '0',
      pvRRNumber: '',
      pvStatus: 'Ready',
      pvItemNotes: '',
      pvItemSpecialInstructions: '',
      pvTechSpecs: '',
      pvStorageRequirements: '',
    },
    resolver: yupResolver(validationSchema),
  });

  return {
    control,
    errors,
    watch,
    reset,
    handleSubmit,
  };
}

export default useEquipmentForm;
