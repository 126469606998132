import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
  };
});

export default useStyles;
