import React from 'react';
import {NormalTextField} from '@zawarski/palmetto-ui-components';
import {INormalTextField} from './NormalTextField.props';

export const NormalTextFieldView = (props: INormalTextField) => {
  const {results} = props;

  return (
    <NormalTextField
      {...props}
      id={props.field}
      value={props.value || (results && results[props.field || '']) || '' || ''}
      label={props.label}
      field={props.field}
      className="flex"
      custommargin="true"
      InputProps={{
        readOnly: true,
        ...props.InputProps,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default NormalTextFieldView;
