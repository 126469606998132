import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdMoreVert} from 'react-icons/md';
import {DualTitleHeaderComponent, HeaderButton} from '@zawarski/palmetto-ui-components';
import {FormControl, IconButton, InputLabel, Menu, MenuItem, NativeSelect} from '@mui/material';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {ActivityFeed, FormDialog} from 'components/fragments';
import {ArrowBackButton, UploadFileDialog} from 'components/primitives';
import {NormalTextField as Field} from 'components/zawarski';

import {LocationProps} from './Location.props';
import NumberFormatPhone from 'components/primitives/NumberFormatPhone';

const LocationView: React.FC<LocationProps> = ({pvLocation, ...props}: LocationProps) => {
  const navigate = useNavigate();

  const appStore = useSelectorSafe((state) => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUploadSNSDialog, setOpenUploadSNSDialog] = useState<boolean>(false);
  const [openPrintInventory, setOpenPrintInventory] = useState<boolean>(false);
  const [snsFiles, setSNSFiles] = useState<File[]>([]);
  const roleEdit = useSelectorSafe((state) => {
    const roles = state.app.appPermissions;
    return !(roles.indexOf('UPDATE') > -1);
  });

  const onClickMore = (e: any) => {
    setAnchorEl(e.target);
  };

  const onClickEdit = () => {
    props.handleItemEdit(pvLocation?.pvDataID);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuSNSUpload = () => {
    setOpenUploadSNSDialog(true);
  };

  const menuPrintInventory = () => {
    setOpenPrintInventory(true);
  };

  return (
    <div className="layout vertical full-height">
      <DualTitleHeaderComponent
        icon={<ArrowBackButton onClick={() => navigate('/')} />}
        titleBig={<span> {pvLocation?.pvSiteName || appStore?.app.appName} - View </span>}
        titleSmall={
          <span>
            {pvLocation?.pvSiteID ? ` SITE ${pvLocation?.pvSiteID} ->` : ''}
            {pvLocation?.pvSitePOC ? ` POC ${pvLocation?.pvSitePOC}` : ''}
          </span>
        }
        suffix={
          <>
            <div
              className="form-margin-16"
              hidden={roleEdit}>
              <HeaderButton
                style={{fontSize: '16px', marginRight: 15, paddingRight: 0}}
                onClick={onClickEdit}>
                EDIT
              </HeaderButton>
            </div>
            <IconButton
              aria-label="menu"
              style={{marginRight: 5}}
              onClick={onClickMore}>
              <MdMoreVert className="icon" />
            </IconButton>
          </>
        }
      />
      <Menu
        id="overflow_menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem onClick={() => menuSNSUpload()}>Import SNS Items</MenuItem>
        <MenuItem onClick={() => null}>Print Daily Activity Report</MenuItem>
        <MenuItem onClick={() => menuPrintInventory()}>
          Print Warehouse Inventory Tracker Report
        </MenuItem>
      </Menu>
      <div className="flex form-shell container-overflow-y">
        <div className="layout horizontal center section-title form-margin">
          General Information
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="Type of site"
            value={pvLocation?.pvLocationType || ''}
            field="pvLocationType"
          />
          <Field
            label="Site name"
            field="pvSiteName"
            value={pvLocation?.pvSiteName || ''}
          />
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="Site ID"
            field="pvSiteID"
            value={pvLocation?.pvSiteID || ''}
          />
          <Field
            label="Site POC"
            field="pvSitePOC"
            value={pvLocation?.pvSitePOC || ''}
          />
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="Phone number"
            field="pvPhoneNumber"
            value={pvLocation?.pvPhoneNumber || ''}
            InputProps={{
              inputComponent: NumberFormatPhone,
            }}
          />
          <Field
            label="Fax number"
            field="pvFaxNumber"
            value={pvLocation?.pvFaxNumber || ''}
            InputProps={{
              inputComponent: NumberFormatPhone,
            }}
          />
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="Cell phone number"
            field="pvCellPhoneNumber"
            value={pvLocation?.pvCellPhoneNumber || ''}
            InputProps={{
              inputComponent: NumberFormatPhone,
            }}
          />
          <Field
            label="Email address"
            field="pvEmailAddress"
            value={pvLocation?.pvEmailAddress || ''}
          />
        </div>

        <div className="layout horizontal center section-title form-margin">Location Details</div>

        <div className="layout horizontal wrap half-width">
          <Field
            label="Address"
            field="pvAddress"
            value={pvLocation?.pvAddress || ''}
          />
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="Address 2"
            field="pvAddress2"
          />
          <Field
            label="City"
            field="pvCity"
            value={pvLocation?.pvCity || ''}
          />
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="County"
            field="pvCounty"
          />
          <Field
            label="State"
            field="pvState"
            value={pvLocation?.pvState || ''}
          />
        </div>

        <div className="layout horizontal wrap half-width">
          <Field
            label="Zip code"
            field="pvZip"
            value={pvLocation?.pvZip || ''}
          />
        </div>

        <div className="layout horizontal wrap">
          <Field
            label="Latitude"
            field="pvLatitude"
            value={pvLocation?.pvLatitude || ''}
          />
          <Field
            label="Longitude"
            field="pvLongitude"
            value={pvLocation?.pvLongitude || ''}
          />
        </div>

        <span className="layout horizontal center section-title form-margin">Activity Feed</span>
        {pvLocation && (
          <div className="layout vertical form-margin">
            <ActivityFeed
              tab=""
              locationID={pvLocation.pvDataID || null}
              pvServiceName="warehouse"
              pvDataID={pvLocation.pvDataID}
              pvServiceID={appStore?.app.pvServiceID || -1}
              pvSubEntryID="location"
              commentsFieldReference={{
                __overrides: {
                  author: ['pvPersonGivenName', 'pvPersonSurName'],
                  datetime: 'pvCreateDate',
                  comment: 'pvComment',
                },
              }}
              historyFieldReference={{
                __overrides: {
                  author: ['pvFirstName', 'pvLastName'],
                  datetime: 'pvEntryDate',
                },
                pvLocationType: 'Type updated',
                pvSiteName: 'Site Name updated',
                pvSitePOC: 'Site POC updated',
                pvPhoneNumber: 'Phone updated',
                pvFaxNumber: 'Fax updated',
                pvCellPhoneNumber: 'Cell updated',
                pvEmailAddress: 'Email updated',
                pvAddress: 'Address updated',
                pvAddress2: 'Address (cont) updated',
                pvCounty: 'County updated',
                pvLatitude: 'Latitude updated',
                pvLongitude: 'Longitude updated',
                pvCity: 'City updated',
                pvState: 'State updated',
                pvZip: 'Zip updated',
                pvSiteID: 'Site ID updated',
              }}
            />
          </div>
        )}
      </div>
      <UploadFileDialog
        filesLimit={1}
        open={openUploadSNSDialog}
        dialogTitle="Upload SNS File"
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        onClose={() => setOpenUploadSNSDialog(false)}
        onSave={(files) => {
          setSNSFiles(files);
          setOpenUploadSNSDialog(false);
        }}
      />
      <FormDialog
        show={openPrintInventory}
        saveLabel="Generate"
        title="Generate Warehouse Inventory Tracker Report"
        handleClose={() => {
          setOpenPrintInventory(false);
        }}
        handleSave={() => {
          setOpenPrintInventory(false);
        }}>
        <FormControl
          variant="standard"
          size="small"
          sx={{width: '100%'}}>
          <InputLabel htmlFor="uncontrolled-native">Select incident</InputLabel>
          <NativeSelect
            required={true}
            value=""
            placeholder="Select incident"
            inputProps={{
              name: 'selectinsident',
            }}>
            <option
              key={0}
              value="">
              Select incident
            </option>
          </NativeSelect>
        </FormControl>
      </FormDialog>
    </div>
  );
};

export default LocationView;
