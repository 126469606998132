import {makeCreateActions, presetActions} from 'store/utils/Redux';
import {AppState} from 'store/state/AppState';

const ns = 'APP';
const createAppActions = makeCreateActions(ns);

const appActions = createAppActions({
  update: presetActions.makeIdentity<Partial<AppState>>(),
  clear: presetActions.noPayload,
});

export default appActions;
