import {useForm} from 'react-hook-form';
import {useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {IBays} from 'interfaces/Bays';
import * as yup from 'yup';

function useBayForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        pvBayQuantity: yup.number().integer().required('Quantity is required'),
        pvBay: yup.string().required('Bay is required'),
        pvQuantityDistributed: yup.number().when(['pvBayQuantity'], {
          is: (pvBayQuantity: number) => {
            return pvBayQuantity > 0;
          },
          then: yup.number(),
        }),
        pvBayInfo: yup.string().nullable(),
      }),
    []
  );

  return useForm<Partial<IBays>>({
    defaultValues: {
      pvBayQuantity: 0,
      pvBay: '',
      pvQuantityDistributed: 0,
      pvBayInfo: '',
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useBayForm;
