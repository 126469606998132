import React from 'react';
import MuiTextField, {TextFieldProps} from '@mui/material/TextField';

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <MuiTextField
      {...props}
      variant={props?.variant || 'outlined'}
      fullWidth
    />
  );
};

export default TextField;
