import {Dispatch} from 'redux';
import _ from 'lodash';

import {Store} from 'store/state/Store';
import apiFetch, {getApps, getAccountsData, GetAccountGroups} from 'services/apiFetch';
import {accountActions, appActions} from 'store/actions';

const initAccount =
  () =>
  async (dispatch: Dispatch, getState: () => Store): Promise<any> => {
    const store = getState();

    if (store.token) {
      const accountsData = await apiFetch(getAccountsData(store.token.userId)).then((r) => r.data);
      // if the user does not have any groups
      if (
        !accountsData ||
        !accountsData.accountInfo ||
        !accountsData.accountInfo.account2groups ||
        !accountsData.accountInfo.account2groups.length
      ) {
        dispatch(accountActions.register({authorized: false}));
        throw new Error('No group found');
      }

      let selectedGroup = null;

      for (let i = 0; i < accountsData.settings.length; i++) {
        if (accountsData.settings[i].pvSettingType === 'selectedGroup') {
          selectedGroup = parseInt(accountsData.settings[i].pvSettingValue);
        }
      }

      if (!selectedGroup) {
        selectedGroup = accountsData.accountInfo.account2groups[0].pvGroupID;
      }

      // const groups = await apiFetch(GetAccountGroups).then((r) => r.data || []);
      const overallApps = await apiFetch(getApps(selectedGroup)).then((r) => r.data);

      // Check if the user is the admin of at least one group
      for (const group of accountsData.accountInfo.account2groups) {
        if (group.pvGroupAdmin === 1) {
          accountsData.accountInfo.pvGroupAdmin = true;
          break;
        }
      }

      // Just for WEBSOCKET, will remove it upon updation
      // window.palmettoToken = authObj;
      // window.palmettoUserAccount = userAccountObj.accountInfo;
      // window.activeGroup = +selectedGroup;

      // const defaultPayload = {
      //   selectedGroupID: selectedGroup,
      //   accountInfo: accountsData.accountInfo,
      //   settings: accountsData.settings,
      //   groups: groups,
      // };

      const app = _.find(overallApps?.apps || [], {
        appName: store.app.appName,
      });

      dispatch(appActions.update(app));
      dispatch(
        accountActions.register({
          authorized: true,
          selectedGroupID: selectedGroup,
        })
      );
    }
  };

export default initAccount;
