import React from 'react';
import _ from 'lodash';
import {FormHelperText} from '@mui/material';
import {NormalTextField} from '@zawarski/palmetto-ui-components';

import {MainLayout} from 'components/layouts';
import {NextButton} from 'components/primitives/Button';
import {LoginProps} from './Login.props';

import 'common/styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/common.login.css';
// import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout.css';
// import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout-classes.css';

const LoginView: React.FC<LoginProps> = ({errMessage, login, ...props}: LoginProps) => {
  const loginDisabled = !login.username || !login.password;

  return (
    <MainLayout>
      <div className="layout vertical center vertical-center full-height">
        <div className="layout vertical logincontainer">
          <div className="layout horizontal center center-justified app-logo-container">
            <img
              // sizing="contain"
              className="app-logo"
              src={process.env.REACT_APP_APP_IMAGE}
              alt="Palmetto Logo"
            />
          </div>

          <div className="layout horizontal center-justified">
            <span className="app-title">{process.env.REACT_APP_APP_TITLE}</span>
          </div>

          <div className="flex login-input-container">
            <div className="layout horizontal">
              <NormalTextField
                id="username-box"
                label="Username"
                name="username"
                placeholder="Enter username"
                className="flex"
                value={login?.username || ''}
                onChange={props.handleChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="layout horizontal">
              <NormalTextField
                id="password-box"
                label="Password"
                name="password"
                type="password"
                placeholder="Enter password"
                className="flex"
                value={login?.password || ''}
                onChange={props.handleChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {errMessage && (
              <FormHelperText
                sx={{mt: 1, lineHeight: '12px'}}
                error>
                {errMessage}
              </FormHelperText>
            )}

            <NextButton
              sx={{mt: errMessage ? 2 : 3}}
              disabled={loginDisabled}
              onClick={props.handleLogin}
              fullWidth>
              Login
            </NextButton>
          </div>

          <div className="layout horizontal center center-justified request-account-section">
            <span>Don’t have an account? </span> &nbsp;{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_PALMETTO_ENDPOINT + '/#/requestaccount'}
              className="bottom-link">
              Request access
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LoginView;
