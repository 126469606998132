import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {locationActions} from 'store/actions';
import {LocationsListProps, LocationsListPublicProps} from './LocationsList.props';
import LocationsListView from './LocationsList.view';

const LocationsListContainer: React.FC<LocationsListPublicProps> = (
  ownProps: LocationsListPublicProps
) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleRowSelect = (data: any) => {
    dispatch(locationActions.update({data}));
    navigation(`${process.env.REACT_APP_APP_URL_PATH}/${data.pvDataID}`);
  };

  const combinedProps: LocationsListProps = {
    ...ownProps,
    handleRowSelect,
  };

  return <LocationsListView {...combinedProps} />;
};

export default LocationsListContainer;
