import React from 'react';
import {LoadingProps} from './Loading.props';
import {ApplicationSpinner} from '@zawarski/palmetto-ui-components';

const LoadingView: React.FC<LoadingProps> = ({...props}) => {
  return (
    <div className="layout vertical vertical-center full-height">
      <ApplicationSpinner />
      <div className="height-20" />
      <span
        style={{padding: '0 16px'}}
        className="text-center title-big opacity-54">
        {props.message}
      </span>
    </div>
  );
};

export default LoadingView;
