// import DBR from "dynamsoft-javascript-barcode";
// DBR.BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@8.1.0/dist/";
// // Please visit https://www.dynamsoft.com/customer/license/trialLicense to get a trial license
// DBR.BarcodeReader.productKeys = "t0076xQAAALDESfavNxts1EL5MfdV7GpmRRQdSR2DTm5eAeQMEmsBO877LbfIlc5SxBk7EHQ1jEo1BIuS550TXkdlWag7R9h8GbYnC9Ipag==";
// // DBR.BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
// export default DBR;

import {BarcodeReader} from 'dynamsoft-javascript-barcode';
BarcodeReader.engineResourcePath =
  'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.0.2/dist/';
// Please visit https://www.dynamsoft.com/customer/license/trialLicense to get a trial license
BarcodeReader.productKeys =
  'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAwMTg2MzA0LTEwMDI2MDYwMSIsIm9yZ2FuaXphdGlvbklEIjoiMTAwMTg2MzA0In0=';

// DBR.BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
// export default BarcodeReader;
