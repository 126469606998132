import moment from 'moment';

export default function toShortDateOnly(dt: string | Date, format?: string): string {
  if (dt) {
    return moment(dt)
      .utc()
      .format(format || 'YYYY-MM-DD');
  } else {
    return '';
  }
}
