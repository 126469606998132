import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  const inputHeight = '40px';
  return {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0px !important',
        },
        '& .ui-comments-box': {
          borderRadius: '4px !important',
          border: '1.5px solid rgba(0,0,0,.46)',
        },
        '& .ui-comments-box:focus-within': {
          padding: '0 !important',
          borderRadius: '4px !important',
          border: '2px solid rgb(41,97,253)',
        },
      },
      // root: {
      //   'paddingRight': 0,
      //   'height': inputHeight,
      //   'backgroundColor': '#ffffff',
      //   '&.MuiInputBase-inputAdornedEnd': {
      //     paddingRight: 0
      //   },
      //   '& .MuiInputAdornment-positionEnd': {
      //     position: 'absolute',
      //     right: '12px'
      //   },
      //   '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
      //     paddingTop: 0,
      //     paddingBottom: 0,
      //     height: inputHeight
      //   },
      //   '& .MuiOutlinedInput-notchedOutline': {
      //     borderColor: '#F5F7FA'
      //   },
      //   '& .MuiSvgIcon-root': {
      //     color: '#D8D8D8'
      //   },
      //   '&.MuiInputBase-multiline': {
      //     'paddingTop': 0,
      //     'paddingBottom': 0,
      //     'height': 'auto',
      //     '& .MuiInputBase-input': {
      //       paddingTop: '7px',
      //       paddingBottom: '7px',
      //       height: '150px !important'
      //     }
      //   }
      // }
    },
  };
};
