import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {MdGpsFixed, MdMap} from 'react-icons/md';
import {NormalTextField} from 'components/zawarski';
import {DecimalFormatCustom} from 'components/fragments';
import SearchLocationInput, {
  geoCodeFromLatLonInput,
} from '../SearchLocationInput/SearchLocationInput.view';
import {Button, Switch} from '@mui/material';
import {AddressModuleProps} from './AddressModule.props';

const CommonFunc = (props: any) => {
  const [value, setValue] = useState('');
  const {callback, ...others} = props;

  const handleChange = (event: any) => {
    setValue(event.target.value);
    props.callback && props.callback(event.target.value);
  };

  // useEffect will be called when the updatedvalue property changes.
  useEffect(() => {
    setValue(props.updatedvalue);
  }, [props.updatedvalue]);

  return (
    <NormalTextField
      value={value}
      onChange={handleChange}
      {...others}
      placeholder={props.InputProps && props.InputProps.readOnly ? ' ' : props.placeholder}
    />
  );
};

export function AddressModule(props: Partial<AddressModuleProps>) {
  const [addressAutofill, setAddressAutofill] = useState(
    props.addressAutofill === false ? false : true
  );

  const [address, setAddress] = useState(props.address || '');
  const [city, setCity] = useState(props.city || '');
  const [county, setCounty] = useState(props.county || '');
  const [state, setState] = useState(props.state || '');
  const [zipCode, setZipCode] = useState(props.zipCode || '');
  const [address2, setAddress2] = useState(props.address2 || '');
  const [latitude, setLatitude] = useState(props.latitude || '');
  const [longitude, setLongitude] = useState(props.longitude || '');
  const [waitingOnMapInput, setWaitingOnMapInput] = useState(false);

  const [results, setResults] = useState({
    address: props.address || '',
    address2: props.address2 || '',
    city: props.city || '',
    county: props.county || '',
    state: props.state || '',
    zipCode: props.zipCode || '',
    latitude: props.latitude || '',
    longitude: props.longitude || '',
  });

  useEffect(() => {
    if (props) {
      setResults({
        address: props.address || '',
        address2: props.address2 || '',
        city: props.city || '',
        county: props.county || '',
        state: props.state || '',
        zipCode: props.zipCode || '',
        latitude: props.latitude || '',
        longitude: props.longitude || '',
      });
      setAddress(props.address || '');
      setCity(props.city || '');
      setCounty(props.county || '');
      setState(props.state || '');
      setZipCode(props.zipCode || '');
      setAddress2(props.address2 || '');
      setLatitude(props.latitude || '');
      setLongitude(props.longitude || '');
    }
  }, [props]);

  // Address change function
  const onAddressChange = (value: any) => {
    if ('address' in value || 'address1' in value) {
      results['address'] = value.address || value.address1;
      setAddress(value.address || value.address1);
    }
    if ('address2' in value) {
      results['address2'] = value.address2;
      setAddress2(value.address2);
    }
    if ('city' in value) {
      results['city'] = value.city;
      setCity(value.city);
    }
    if ('county' in value) {
      results['county'] = value.county;
      setCounty(value.county);
    }
    if ('state' in value) {
      results['state'] = value.state;
      setState(value.state);
    }
    if ('zipCode' in value) {
      results['zipCode'] = value.zipCode;
      setZipCode(value.zipCode);
    }
    if ('latitude' in value) {
      results['latitude'] = value.latitude;
      setLatitude(value.latitude || '');
    }
    if ('longitude' in value) {
      results['longitude'] = value.longitude;
      setLongitude(value.longitude || '');
    }

    setDataForCallback();
  };

  // If the callback is defined, this function would set the data for the parent component to use

  const setDataForCallback = () => {
    props.callback && props.callback(results);
  };

  // Address autofill
  const onAddressAutofillChange = (event: any) => setAddressAutofill(event.target.checked);

  // Address change function
  const onAddressChangeNoAutofill = (value: any) => {
    results['address'] = value;
    setDataForCallback();
  };
  // Address change function
  const onAddress2Change = (value: any) => {
    results['address2'] = value;
    setDataForCallback();
  };
  // City change function
  const onCityChange = (value: any) => {
    results['city'] = value;
    setDataForCallback();
  };
  // County change function
  const onCountyChange = (value: any) => {
    results['county'] = value;
    setDataForCallback();
  };

  // County change function
  const onStateChange = (value: any) => {
    results['state'] = value;
    setDataForCallback();
  };

  // Function called after zipCode has been updated
  const onZipCodeChange = (value: any) => {
    results['zipCode'] = value;
    setDataForCallback();
  };

  // Function called after latitude has been updated
  const onLatitudeChange = (value: any) => {
    results['latitude'] = value;
    setDataForCallback();
  };
  // Function called after Longitude has been updated
  const onLongitudeChange = (value: any) => {
    results['longitude'] = value;
    setDataForCallback();
  };

  const getAddressData = () => {
    return {
      address: results['address'],
      address2: results['address2'],
      city: results['city'],
      county: results['county'],
      state: results['state'],
      zipCode: results['zipCode'],
      latitude: results['latitude'],
      longitude: results['longitude'],
    };
  };

  // If getDataFunc property is defined, then we will pass the reference of the function that can aggregate data in the callback
  if (props.getDataFunc) {
    if (typeof props.getDataFunc === 'function') {
      props.getDataFunc(getAddressData);
    }
  }

  const getLatLongFromMap = () => {
    const handleMapEvent = async (e: any) => {
      await geoCodeFromLatLonInput(e.detail.lat, e.detail.lng, onAddressChange);
      window.removeEventListener('palmetto-map-click', handleMapEvent);
      setWaitingOnMapInput(false);
    };

    if (waitingOnMapInput) {
      // this case would occur when user is waiting on the map input but clicks the select point on map button
      window.removeEventListener('palmetto-map-click', handleMapEvent);
      setWaitingOnMapInput(false);
    } else {
      setWaitingOnMapInput(true);
      window.addEventListener('palmetto-map-click', handleMapEvent);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = parseFloat(position.coords.latitude.toFixed(8).toString());
          const lon = parseFloat(position.coords.longitude.toFixed(8).toString());
          await geoCodeFromLatLonInput(lat, lon, onAddressChange);
        },
        (err) => console.log(err),
        {maximumAge: 60000, timeout: 10000, enableHighAccuracy: true}
      );
    }
  };

  return (
    <div className="layout vertical address-module">
      <div className="layout horizontal wrap center">
        {props.readOnly ? (
          ''
        ) : (
          <>
            {props.displaySelectPointOnMap ? (
              // boxShadow: '0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 40%)'
              <Button
                variant="outlined"
                onClick={getLatLongFromMap}
                style={
                  waitingOnMapInput
                    ? {margin: '10px 6px', background: '#4285F4', color: 'white'}
                    : {margin: '10px 6px'}
                }>
                <MdMap style={{width: '20px', height: '20px', marginRight: '6px'}} /> Select point
                on map
              </Button>
            ) : (
              ''
            )}
            {props.displayUseCurrentLocation ? (
              <Button
                variant="outlined"
                onClick={getCurrentLocation}
                style={{margin: '10px 6px'}}>
                <MdGpsFixed style={{width: '20px', height: '20px', marginRight: '6px'}} /> Use
                Current Location
              </Button>
            ) : (
              ''
            )}
          </>
        )}
      </div>

      {props.readOnly ? (
        ''
      ) : (
        <div className="layout horizontal center">
          <Switch
            checked={addressAutofill}
            onChange={onAddressAutofillChange}
            color="primary"
            name="addressAutofill"
            inputProps={{'aria-label': 'primary checkbox'}}
          />
          <span>Address Autofill</span>
        </div>
      )}

      {addressAutofill ? (
        <div className="layout horizontal">
          <SearchLocationInput
            id="address"
            label="Address"
            placeholder="Enter address"
            updatedvalue={address}
            callback={(value: any) => onAddressChange(value)}
            className="flex"
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      ) : (
        <div className="layout horizontal wrap">
          <CommonFunc
            id="address"
            label="Address"
            placeholder="Enter address"
            callback={(value: any) => onAddressChangeNoAutofill(value)}
            updatedvalue={address2}
            className="flex"
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}

      <div className="layout horizontal wrap">
        <CommonFunc
          id="address2"
          label="Address 2"
          placeholder="Enter address 2"
          callback={(value: any) => onAddress2Change(value)}
          updatedvalue={address2}
          className="flex"
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <CommonFunc
          id="city"
          label="City"
          placeholder="Enter city"
          className="flex"
          callback={(value: any) => onCityChange(value)}
          updatedvalue={city}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      <div className="layout horizontal wrap">
        <CommonFunc
          id="county"
          label="County"
          placeholder="Enter county"
          className="flex"
          callback={(value: any) => onCountyChange(value)}
          updatedvalue={county}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <CommonFunc
          id="state"
          label="State"
          placeholder="Select state"
          callback={(value: any) => onStateChange(value)}
          className="flex"
          updatedvalue={state}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <CommonFunc
          id="zipCode"
          label="Zip code"
          callback={(value: any) => onZipCodeChange(value)}
          placeholder="Enter zip code"
          updatedvalue={zipCode}
          className="flex"
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      <div className="layout horizontal wrap">
        <CommonFunc
          id="latitude"
          label="Latitude"
          callback={(value: any) => onLatitudeChange(value)}
          placeholder="Enter latitude"
          updatedvalue={latitude}
          className="flex"
          InputProps={{
            inputComponent: DecimalFormatCustom,
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <CommonFunc
          id="longtitude"
          label="Longitude"
          callback={(value: any) => onLongitudeChange(value)}
          placeholder="Enter longitude"
          updatedvalue={longitude}
          className="flex"
          InputProps={{
            inputComponent: DecimalFormatCustom,
            readOnly: props.readOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
}

export default AddressModule;
