import {useForm} from 'react-hook-form';
import {useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {IItem} from 'common/interfaces';
import {EMPTY_RESULTS} from 'pages/ItemEdit/ItemEdit.view';

function useItemDetailsForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        bays: yup.array().min(1, 'Required at least 1 bay'),
        pvItemType: yup.string().required('Item Type is required'),
        pvManufacturer: yup.string().nullable(),
        pvLotNumber: yup.string().nullable(),
        pvExpirationDate: yup.string(),
        pvQuantityReceived: yup.number().min(1, 'Quantity Received minimum value of 1'),
        pvUnitOfMeasure: yup.string().nullable(),
        pvReqDetCostEst: yup.string().nullable(),
        pvStorageRequirements: yup.string().nullable(),
        pvItemNotes: yup.string().nullable(),
      }),
    []
  );

  return useForm<Partial<IItem>>({
    defaultValues: {
      ...EMPTY_RESULTS,
      pvItemType: '',
      pvManufacturer: '',
      pvLotNumber: '',
      pvExpirationDate: '',
      pvQuantityReceived: 0,
      pvUnitOfMeasure: 'Each',
      pvReqDetCostEst: '0',
      pvStorageRequirements: '',
      pvItemNotes: '',
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useItemDetailsForm;
