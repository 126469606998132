import {makeCreateActions, presetActions} from 'store/utils/Redux';
import {ItemState} from 'store/state/ItemState';

const ns = 'ITEM';
const createItemnActions = makeCreateActions(ns);

const itemIcons = createItemnActions({
  update: presetActions.makeIdentity<ItemState>(),
  clear: presetActions.noPayload,
});

export default itemIcons;
