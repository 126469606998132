import {useSelector} from 'react-redux';
import {Optional} from 'store/types/Optional';
import {fallback} from 'store/utils/Data';
import {Store} from 'store/state/Store';

function useSelectorSafe<Value>(
  fn: (optimisticObj: Required<Store>) => Optional<Value>,
  defaultVal: Value
): Value;

function useSelectorSafe<Value>(
  fn: (optimisticObj: Required<Store>) => Optional<Value>,
  defaultVal?: undefined
): Optional<Value>;

function useSelectorSafe<Value>(
  fn: (optimisticObj: Required<Store>) => Optional<Value>,
  defaultVal?: Value
) {
  return useSelector(
    defaultVal !== undefined
      ? fallback<Store, Value>(fn, defaultVal)
      : fallback<Store, Optional<Value>>(fn, undefined)
  );
}

export default useSelectorSafe;
