import {randomString} from '../String';

const compareTwoObjects = (item1, item2, fieldTitleReference) => {
  let str = '';
  for (const a in item1) {
    if (fieldTitleReference[a]) {
      if (item1[a] !== item2[a]) {
        str += a + ', ';
      }
    }
  }
  return str;
};

const buildAuthorFromOverrides = (overrides, item) => {
  if (!overrides) return false;

  // Author overrides should be in this
  let name = '';
  overrides.forEach((field) => {
    name += item[field] || '';
    name += ' ';
  });

  return name;
};

const formatDate = (isodate) => {
  const date = new Date(isodate);
  return (
    ('00' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + date.getDate()).slice(-2) +
    '-' +
    date.getFullYear() +
    ' ' +
    ('00' + date.getHours()).slice(-2) +
    ':' +
    ('00' + date.getMinutes()).slice(-2) +
    ':' +
    ('00' + date.getSeconds()).slice(-2)
  );
};

const buildDateFromOverrides = (override, item) => formatDate(item[override]);

const mergeHistoryAndComments = (
  historyWithState = [],
  commentsWithState = [],
  fieldTitleReference = {},
  commentsFieldReference = {}
) => {
  const history = [];
  const comments = [];
  Object.assign(history, historyWithState);
  Object.assign(comments, commentsWithState);

  const historyComments = [];
  // If the field title reference is present then we need to extract their values from the array of objects specified

  if (Object.keys(fieldTitleReference).length > 0 && history.length) {
    const dateTimeHistoryField = fieldTitleReference.__overrides.datetime || 'datetime';

    // Parse history and build the history object as listed in `/docs/ActivityFeed.md`

    // Sort history to get the base version to compare to
    // Below code will sort history in descending order. That would mean history[history.length - 1] will act as out base entry

    history.sort((a, b) => new Date(b[dateTimeHistoryField]) - new Date(a[dateTimeHistoryField]));

    let baseEntry = history[history.length - 1];

    historyComments.push({
      _ui_lib_id: randomString(),
      type: 'history',
      text: 'Entry Created',
      printText: 'Entry Created',
      datetime: buildDateFromOverrides(dateTimeHistoryField, baseEntry) || baseEntry.datetime,
      author:
        buildAuthorFromOverrides(fieldTitleReference.__overrides.author, baseEntry) ||
        baseEntry.author,
    });

    // Now compare every object in a step by step fashion

    if (history.length > 1) {
      // We compare versions in a step manner
      // [i-1]...[i-2] last 2nd object will be compared with last 3rd
      // [i-2]...[i-3]
      // [i-3]...[i-4]
      // [i-4]...[i-5]
      // ...
      // ...
      // ...
      // ...
      // [i-(i-1)]...[i-(i)] // 1st index will be matched with 0th index

      for (let i = history.length - 2; i >= 0; i--) {
        // This will give you a list of fields that got updated
        const stringifiedUpdatedFields = compareTwoObjects(
          baseEntry,
          history[i],
          fieldTitleReference
        );
        if (stringifiedUpdatedFields) {
          // Change in the object found
          const updatedFields = stringifiedUpdatedFields.split(',');

          updatedFields.forEach((field) => {
            // Only look for fields that we want to observe

            if (field && fieldTitleReference[field.trim()]) {
              historyComments.unshift({
                _ui_lib_id: randomString(),
                type: 'history',
                text: fieldTitleReference[field.trim()],
                original: false,
                path: field,
                datetime:
                  buildDateFromOverrides(dateTimeHistoryField, history[i]) || history[i].datetime,
                author:
                  buildAuthorFromOverrides(fieldTitleReference.__overrides.author, history[i]) ||
                  history[i].author,
              });
            }
          });

          // This line is important to perform a step checking else the results you will get, will
          // always be compared with the object on last index.
          baseEntry = history[i];
        }
      }
    }
  }

  // Comments

  if (comments && comments.length) {
    const dateTimeCommentsField = commentsFieldReference.__overrides.datetime || 'datetime';
    const commentField = commentsFieldReference.__overrides.comment || 'comment';

    for (let i = 0, len = comments.length; i < len; i++) {
      historyComments.push({
        _ui_lib_id: randomString(),
        type: 'comment',
        text: comments[i][commentField],
        datetime:
          buildDateFromOverrides(dateTimeCommentsField, comments[i]) || comments[i].datetime,
        author:
          buildAuthorFromOverrides(commentsFieldReference.__overrides.author, comments[i]) ||
          comments[i].author,
      });
    }
  }

  historyComments.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
  historyComments.length && (historyComments[historyComments.length - 1].hideVertLine = true);

  return historyComments;
};

export default mergeHistoryAndComments;
