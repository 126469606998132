/* eslint-disable operator-linebreak */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {HeaderButton, DualTitleHeaderComponent} from '@zawarski/palmetto-ui-components';
import {getEntry} from 'helpers/functions';
import {NormalTextField as Field} from 'components/zawarski';
import {MainLayout} from 'components/layouts';
import {ArrowBackButton} from 'components/primitives';
import {ActivityFeed, Loading} from 'components/fragments';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import ReceivedForm from 'pages/ReceivedView/ReceivedForm.view';
import BaysItemDetailsForm from './BaysItemDetailsForms.view';
import {IBays} from 'common/interfaces';

const EMPTY_BAYS: IBays = {
  pvApproximateLocation: null,
  pvBarcode: null,
  pvBay: null,
  pvBayID: null,
  pvBayInfo: null,
  pvBayQuantity: 0,
  pvBenefactor: null,
  pvCreateDate: null,
  pvDataID: null,
  pvDescription: null,
  pvDomainID: null,
  pvEntryDate: null,
  pvExpirationDate: null,
  pvFederallyDonatedBy: null,
  pvFirstName: null,
  pvGroupID: null,
  pvGroupName: null,
  pvItemID: null,
  pvItemNotes: null,
  pvItemQuantity: null,
  pvItemSpecialInstructions: null,
  pvItemType: null,
  pvLastName: null,
  pvLocationID: null,
  pvLotNumber: null,
  pvManufacturer: null,
  pvPOReferenceNumber: null,
  pvProjectMission: null,
  pvPurchaseOrderRefNumber: null,
  pvQuantityDistributed: null,
  pvQuantityReceived: null,
  pvRRNumber: null,
  pvReceivedDate: null,
  pvReqDetCostEst: null,
  pvSiteName: null,
  pvStatus: null,
  pvStorageRequirements: null,
  pvSubmittedBy: null,
  pvTechSpecs: null,
  pvTotalCases: null,
  pvType: 'item',
  pvUnitOfMeasure: null,
  pvUserID: null,
  pvUsername: null,
  pvVoid: 0,
  v2: null,
};

const BaysView: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const appState = useSelectorSafe((state) => state);
  const pvLocation = appState?.location?.data;
  const roles = appState?.app?.appPermissions || [];
  const roleEdit = !(roles.indexOf('UPDATE') > -1);
  const serviceID = appState?.app.pvServiceID;
  const [itemEntry, setItemEntry] = useState<IBays>(EMPTY_BAYS);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'bay', (data, error) => {
      if (!error) {
        setItemEntry(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error(`[getBaysEntry] caught an error: ${error.status} - ${error.message}`);
      }
    });
  };

  const handleClickEdit = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/bays/${params?.pvDataID}/edit`
    );
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={
            <ArrowBackButton
              onClick={() =>
                navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/bays`)
              }
            />
          }
          titleBig={<span>{pvLocation?.pvSiteName} - View</span>}
          titleSmall={
            <span>
              {'Bay' +
                (isLoading
                  ? '  - Loading'
                  : (itemEntry?.pvPurchaseOrderRefNumber
                      ? ' - PO/Ref# ' + itemEntry?.pvPurchaseOrderRefNumber
                      : itemEntry?.pvBenefactor
                      ? ' - Benefactor ' + itemEntry?.pvBenefactor
                      : '') +
                    (itemEntry?.pvItemType ? ' - ' + itemEntry?.pvItemType : '') +
                    (itemEntry?.pvBay ? ' - ' + itemEntry?.pvBay : ''))}
            </span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleEdit}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={() => handleClickEdit()}>
                  EDIT
                </HeaderButton>
              </div>
            </div>
          }
        />
        {isLoading ? (
          <Loading message="Please wait . . ." />
        ) : (
          <div className="flex form-shell container-overflow-y">
            <div className="layout horizontal center section-title form-margin">Bay Details</div>
            <div className="layout horizontal wrap">
              <Field
                label="Bay"
                field="pvBay"
                value={itemEntry?.pvBay || ''}
              />
              <Field
                label="Bay Quantity"
                field="pvBayQuantity"
                value={itemEntry?.pvBayQuantity || '0'}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Bay Info"
                field="pvBayInfo"
                value={itemEntry?.pvBayInfo || ''}
              />
            </div>
            <BaysItemDetailsForm
              readOnly
              results={itemEntry}
              pvDataID={''}
              onResultsChange={() => null}
            />
            <ReceivedForm itemEntry={itemEntry} />
            <div className="layout horizontal wrap">
              {pvLocation && (
                <div
                  className="layout vertical form-margin"
                  style={{width: '100%'}}>
                  <ActivityFeed
                    tab="bay"
                    locationID={Number(params?.locationId) || null}
                    pvServiceName="warehouse"
                    pvDataID={Number(params?.pvDataID)}
                    pvServiceID={serviceID}
                    pvSubEntryID="bay"
                    commentsFieldReference={{
                      __overrides: {
                        author: ['pvPersonGivenName', 'pvPersonSurName'],
                        datetime: 'pvCreateDate',
                        comment: 'pvComment',
                      },
                    }}
                    historyFieldReference={{
                      __overrides: {
                        author: ['pvFirstName', 'pvLastName'],
                        datetime: 'pvEntryDate',
                      },
                      pvBayQuantity: 'Bay quantity changed',
                      pvBay: 'Bay ID changed',
                      pvBayInfo: 'Bay info changed',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default BaysView;
