import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Nullable} from 'common/utils';
import apiFetch, {authLogin} from 'services/apiFetch';
import {tokenActions} from 'store/actions';
import {LoginProps, LoginPublicProps, LoginUser} from './Login.props';
import LoginView from './Login.view';

const LoginErrMessage = {
  MISSING: 'Please enter the username and password',
  INVALID: 'You have entered an invalid username and/or password',
  SERVER: 'Sorry, something went wrong there. Please try again.',
};

const LoginContainer: React.FC<LoginPublicProps> = (ownProps: LoginPublicProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [login, setLogin] = useState<Partial<LoginUser>>({});

  const authenticate = async (username: string, password: string) => {
    setErrMessage(null);

    try {
      const auth = await apiFetch(authLogin(username, password));

      if (auth?.data.id) {
        dispatch(tokenActions.authorize(auth?.data));
        navigate('/');
      } else {
        setErrMessage(LoginErrMessage.INVALID);
      }
    } catch (e) {
      setErrMessage(LoginErrMessage.SERVER);
      console.error(e);
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const field = e.currentTarget.name;
    setLogin({...login, [field]: e.currentTarget.value});
    setErrMessage(null);
  };

  const handleLogin = async () => {
    const {username, password} = login;

    if (username && password) {
      authenticate(username, password);
    } else {
      setErrMessage(LoginErrMessage.MISSING);
    }
  };

  const combinedProps: LoginProps = {
    ...ownProps,
    errMessage,
    login,
    handleChange,
    handleLogin,
  };

  return <LoginView {...combinedProps} />;
};

export default LoginContainer;
