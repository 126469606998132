import React from 'react';
import {useNavigate} from 'react-router-dom';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {MdAdd} from 'react-icons/md';
import {makeStyles} from '@mui/styles';
import {PaperFabButton} from '@zawarski/palmetto-ui-components';
import {WarehouseTableColumns} from 'helpers/tables';
import {MainLayout} from 'components/layouts';
import {TableList} from 'components/fragments';
import {LocationsListProps} from './LocationsList.props';

const useStyles = makeStyles({
  fab: {
    bottom: '80px !important',
  },
});

const LocationsListView: React.FC<LocationsListProps> = ({...props}: LocationsListProps) => {
  const appStore = useSelectorSafe((state) => state);
  const {app, token} = appStore || {};
  const navigate = useNavigate();
  const classes = useStyles();
  const roles = app?.appPermissions || [];
  const roleSave = !(roles.indexOf('UPDATE') > -1);

  return (
    <MainLayout>
      <TableList
        auth={{
          accessToken: token?.id,
          userId: token?.userId,
          rooms: ['warehouse_location'],
        }}
        title={app?.title}
        subtitleSuffix="sites"
        columns={WarehouseTableColumns}
        onRowSelect={(e: any) => props.handleRowSelect(e.row)}
      />
      <PaperFabButton
        hidden={roleSave}
        onClick={() => {
          navigate(`${process.env.REACT_APP_APP_URL_PATH}/new`);
        }}
        className={classes.fab}>
        <MdAdd className="icon" />
      </PaperFabButton>
    </MainLayout>
  );
};

export default LocationsListView;
