/* eslint-disable operator-linebreak */
import {Nullable} from 'common/utils';
import apiFetch, {getItemEntry} from 'services/apiFetch';
import {ICallBackError} from 'interfaces/CallBackError';

const getEntry = async (
  locationId: Nullable<string | number>,
  pvDataId: Nullable<string | number>,
  type: string,
  cb: (data: any, error: Nullable<ICallBackError>) => void
) => {
  if (locationId && pvDataId) {
    const res = await apiFetch(getItemEntry(type, locationId, pvDataId));

    if (res && res.status === 200) {
      cb(res.data, null);
    } else cb(null, {status: res.status, message: `[getEntry - ${type}]: Failed to get data`});
  } else {
    cb(null, {
      status: 0,
      message: `[getEntry - ${type}]: Required Fields not met please check your parameter`,
    });
  }
};

export default getEntry;
