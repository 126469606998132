/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {toShortDateOnly} from 'common/utils/DateTime';

const ReceivedTableColumns: any = [
  {
    flex: 1,
    field: 'pvPurchaseOrderRefNumber',
    numeric: false,
    headerName: 'PO/Ref#',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvBenefactor',
    numeric: false,
    headerName: 'Benefactor',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvReceivedDate',
    numeric: false,
    headerName: 'Date Received',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any): string => toShortDateOnly(p.value),
  },
  {
    flex: 1,
    field: 'pvDescription',
    numeric: false,
    headerName: 'Description',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvFileCount',
    numeric: false,
    headerName: 'Files',
    enabled: false,
    minWidth: 200,
  },
];

export default ReceivedTableColumns;
