import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Nullable} from 'common/utils';
import {MainProps, MainPublicProps, TabName} from './Main.props';
import MainView from './Main.view';

const MainContainer: React.FC<MainPublicProps> = (ownProps: MainPublicProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const tab = params?.tabName as TabName;
  const [tabName, setTabName] = useState<Nullable<TabName>>(tab || null);

  const handleTabChange = (event: React.SyntheticEvent, nextTab: TabName) => {
    navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params.locationId}/${nextTab || ''}`);
    setTabName(nextTab);
  };

  const combinedProps: MainProps = {
    ...ownProps,
    pvDataID: Number(params?.pvDataID) || 0,
    tabName,
    handleTabChange,
  };

  return <MainView {...combinedProps} />;
};

export default MainContainer;
