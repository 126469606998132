import {useForm} from 'react-hook-form';
import {useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Nullable} from 'common/utils';

export interface ILocationForm {
  pvLocationType: Nullable<string>;
  pvSiteName: Nullable<string>;
  pvSiteID: Nullable<string>;
  pvSitePOC: Nullable<string>;
  pvPhoneNumber: Nullable<string>;
  pvFaxNumber: Nullable<string>;
  pvCellPhoneNumber: Nullable<string>;
  pvEmailAddress: Nullable<string>;
}

function useLocationForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        pvLocationType: yup.string().required('Location Type is required'),
        pvSiteName: yup.string().required('Site Name is required'),
        pvSiteID: yup.string(),
        pvSitePOC: yup.string().required('Site POC is required'),
        pvPhoneNumber: yup.string(),
        pvFaxNumber: yup.string(),
        pvCellPhoneNumber: yup.string(),
        pvEmailAddress: yup.string().email('Not a valid email').required('Email is required'),
      }),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
    reset,
  } = useForm<ILocationForm>({
    defaultValues: {
      pvLocationType: 'NDIP',
      pvSiteName: '',
      pvSiteID: '',
      pvSitePOC: '',
      pvPhoneNumber: '',
      pvFaxNumber: '',
      pvCellPhoneNumber: '',
      pvEmailAddress: '',
    },
    resolver: yupResolver(validationSchema),
  });

  return {
    control,
    errors,
    watch,
    reset,
    handleSubmit,
  };
}

export default useLocationForm;
