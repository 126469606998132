import {alpha, Theme} from '@mui/material';

const TabStyles = (theme: Theme): any => ({
  root: {
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
    color: alpha(theme.palette.common.white, 0.7),
  },
});

export {TabStyles};
