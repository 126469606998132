import {handleActions} from 'redux-actions';
import {presetReducers} from 'store/utils/Redux';
import {LocationState, LocationEmptyState} from 'store/state/LocationState';
import {locationActions} from '../actions';

const reducer = handleActions<LocationState, any>(
  {
    [String(locationActions.update)]: presetReducers.makeMerge(),
    [String(locationActions.clear)]: presetReducers.makeReset(LocationEmptyState),
  },
  LocationEmptyState
);

export default reducer;
