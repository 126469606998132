import React from 'react';
import {MdAdd} from 'react-icons/md';
import {makeStyles} from '@mui/styles';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {PaperFabButton} from '@zawarski/palmetto-ui-components';
import {joinPathFormat} from 'common/utils/String';
import {TableList} from 'components/fragments';
import {EquipmentTableColumns} from 'helpers/tables';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const useStyles = makeStyles({
  fab: {
    bottom: '80px !important',
  },
});

const EquipmentListView: React.FC = () => {
  const appState = useSelectorSafe((state) => state);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const roles = appState?.app?.appPermissions || [];
  const roleSave = !(roles.indexOf('UPDATE') > -1);

  const handleRowSelect = (item: any) => {
    navigate(joinPathFormat([location.pathname, item.pvDataID]));
  };

  return (
    <>
      <TableList
        auth={{
          accessToken: appState?.token?.id,
          userId: appState?.token?.userId,
          rooms: ['warehouse_equipment'],
        }}
        columns={EquipmentTableColumns}
        location={appState?.location?.data}
        reqEndpoint="equipment"
        subtitlePrefix={'Equipment'}
        onRowSelect={(r) => handleRowSelect(r.row)}
      />
      <PaperFabButton
        hidden={roleSave}
        onClick={() => {
          navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/equipment/new`);
        }}
        className={classes.fab}>
        <MdAdd className="icon" />
      </PaperFabButton>
    </>
  );
};

export default EquipmentListView;
