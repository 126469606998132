import React from 'react';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {LocationProps, LocationPublicProps} from './Location.props';
import LocationView from './Location.view';
import {useNavigate} from 'react-router-dom';

const LocationContainer: React.FC<LocationPublicProps> = (ownProps: LocationPublicProps) => {
  const appStore = useSelectorSafe((state) => state);
  const pvLocation = appStore?.location?.data || null;
  const navigation = useNavigate();

  const handleItemEdit = (locationID?: number) => {
    navigation(`${process.env.REACT_APP_APP_URL_PATH}/${locationID}/edit`);
  };

  const combinedProps: LocationProps = {
    ...ownProps,
    pvLocation,
    handleItemEdit,
  };

  return <LocationView {...combinedProps} />;
};

export default LocationContainer;
