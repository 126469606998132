/* eslint-disable operator-linebreak */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import _ from 'lodash';
import {Card, CardContent, Typography} from '@mui/material';
import {HeaderButton, DualTitleHeaderComponent} from '@zawarski/palmetto-ui-components';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {IItem} from 'common/interfaces';
import {Nullable} from 'common/utils';
import {toShortDateOnly} from 'common/utils/DateTime';
import {MainLayout} from 'components/layouts';
import {ArrowBackButton} from 'components/primitives';
import {ActivityFeed, Loading} from 'components/fragments';
import {NormalTextField as Field} from 'components/zawarski';
import {getEntry} from 'helpers/functions';
import 'common/styles/form-styles.css';

const EMPTY_ITEMS: Partial<IItem> = {
  bays: [],
  pvDataID: null,
  pvDomainID: null,
  pvEntryDate: null,
  pvExpirationDate: toShortDateOnly(new Date()),
  pvFederallyDonatedBy: null,
  pvLocationID: '',
  pvGlobalID: null,
  pvGroupID: null,
  pvItemNotes: null,
  pvItemQuantity: null,
  pvItemSpecialInstructions: null,
  pvItemType: null,
  pvLotNumber: null,
  pvManufacturer: null,
  pvPOReferenceNumber: null,
  pvProjectMission: null,
  pvQuantityDistributed: null,
  pvQuantityReceived: 0,
  pvRRNumber: null,
  pvReceivedID: null,
  pvReqDetCostEst: null,
  pvStatus: null,
  pvStorageRequirements: null,
  pvTechSpecs: null,
  pvTotalCases: null,
  pvType: 'item',
  pvUnitOfMeasure: null,
  pvVoid: 0,
};

const getResultsForQuantityDistributed = (itemEntry: Partial<IItem>) => {
  const ttlQtyReceived = itemEntry?.pvQuantityReceived || 0;
  let quantityDistributed = 0;
  let sumTotalBayQty = 0;

  if (itemEntry && _.isArray(itemEntry?.bays) && itemEntry?.bays.length > 0) {
    sumTotalBayQty = _.reduce(itemEntry.bays, (sum, b) => sum + Number(b.pvBayQuantity || 0), 0);

    if (
      Number(itemEntry?.pvQuantityDistributed || 0) > 0 &&
      Number(itemEntry?.pvQuantityDistributed || 0) === sumTotalBayQty
    ) {
      quantityDistributed = Number(itemEntry?.pvQuantityDistributed || 0);
    } else {
      quantityDistributed = ttlQtyReceived - sumTotalBayQty;
    }
  }

  return {
    pvQuantityDistributed: quantityDistributed.toString(),
  };
};

const ItemsView: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const appState = useSelectorSafe((state) => state);
  const pvLocation = appState?.location?.data;
  const [itemEntry, setItemEntry] = useState<Partial<IItem>>(EMPTY_ITEMS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const roles = appState?.app?.appPermissions || [];
  const roleEdit = !(roles.indexOf('UPDATE') > -1);

  const handleClickEdit = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items/${params?.pvDataID}/edit`
    );
  };

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'item', (data, error) => {
      if (!error) {
        setItemEntry(data);
        setIsLoading(false);
      } else {
        console.error('getReceivedEntry caught an error: ', error);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getItems();
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={
            <ArrowBackButton
              onClick={() =>
                navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items`)
              }
            />
          }
          titleBig={<span>{pvLocation?.pvSiteName} - View</span>}
          titleSmall={
            <span>
              {'Item' +
                (itemEntry?.pvPurchaseOrderRefNumber
                  ? ' PO/Ref# ' + itemEntry?.pvPurchaseOrderRefNumber
                  : itemEntry?.pvBenefactor
                  ? ' Benefactor ' + itemEntry?.pvBenefactor
                  : '') +
                (itemEntry?.pvItemType ? ' - ' + itemEntry?.pvItemType : '')}
            </span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleEdit}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={() => handleClickEdit()}>
                  EDIT
                </HeaderButton>
              </div>
            </div>
          }
        />
        {isLoading ? (
          <Loading message="Please Wait . . ." />
        ) : (
          <div className="flex form-shell container-overflow-y">
            <div className="layout horizontal center section-title form-margin">Item Details</div>

            <div className="layout horizontal wrap">
              <Field
                label="Item type"
                field="itemEntryType"
                value={itemEntry?.pvItemType}
              />
            </div>

            <div className="layout horizontal wrap">
              <Field
                className="flex"
                label="Manufacturer"
                field="pvManufacturer"
                value={itemEntry?.pvManufacturer || ''}
              />
              <Field
                className="flex"
                label="Product/Lot #"
                field="pvLotNumber"
                value={itemEntry?.pvLotNumber || ''}
              />
              <Field
                className="flex"
                label="Expiration Date"
                field="pvExpirationDate"
                value={toShortDateOnly(itemEntry?.pvExpirationDate || '')}
              />
            </div>

            <div className="layout horizontal wrap">
              <Field
                label="Quantity Received"
                field="pvQuantityReceived"
                value={itemEntry?.pvQuantityReceived || ''}
              />
              <Field
                results={getResultsForQuantityDistributed(itemEntry)}
                label="Quantity Distributed"
                field="pvQuantityDistributed"
              />
              <Field
                className="flex"
                label="Unit of measure"
                field="pvUnitOfMeasure"
                value={itemEntry?.pvUnitOfMeasure || ''}
              />
              <Field
                label="Cost per unit"
                field="pvReqDetCostEst"
                value={`$${itemEntry?.pvReqDetCostEst || ''}` || ''}
              />
            </div>

            <div className="layout horizontal wrap">
              <Field
                className="flex"
                label="Storage Location"
                field="pvStorageRequirements"
                value={itemEntry?.pvStorageRequirements || ''}
              />
            </div>

            <div className="layout horizontal wrap">
              <Field
                multiline={true}
                label="Notes"
                field="pvItemNotes"
                value={itemEntry?.pvItemNotes || ''}
              />
            </div>
            <div className="layout horizontal center section-title form-margin">
              Received Details
            </div>

            <div className="layout horizontal wrap">
              <Field
                className="flex"
                label="Date received"
                field="pvReceivedDate"
                value={toShortDateOnly(itemEntry?.pvReceivedDate || '')}
              />
              <Field
                className="flex"
                label="Entry submitted by"
                field="pvSubmittedBy"
                value={itemEntry?.pvSubmittedBy || ''}
              />
            </div>

            <div className="layout horizontal wrap">
              <Field
                className="flex"
                label="Purchase order / ref #"
                field="pvPurchaseOrderRefNumber"
                value={itemEntry?.pvPurchaseOrderRefNumber || ''}
              />
              <Field
                className="flex"
                label="Benefactor"
                field="pvBenefactor"
                value={itemEntry?.pvBenefactor || ''}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                multiline={true}
                label="Description"
                field="pvDescription"
                value={itemEntry?.pvDescription || ''}
              />
            </div>
            <div className="layout horizontal center section-title form-margin">Bays Assigned</div>
            <div className="layout horizontal wrap">
              {(itemEntry?.bays || []).map((bay: Partial<IItem>, index: number) => {
                if (!bay?.pvVoid) {
                  return (
                    <Card
                      variant="outlined"
                      key={bay.pvDataID || index}
                      className="cursor-pointer"
                      style={{width: 288, margin: 10}}>
                      <CardContent>
                        <div className="layout horizontal">
                          <div className="layout horizontal center flex">
                            <Typography variant="h5">Bay: {bay.pvBay}</Typography>
                          </div>
                        </div>
                        <Typography
                          variant="body1"
                          className="truncate">
                          Quantity:{' '}
                          {bay?.pvBayQuantity && bay?.pvBayQuantity !== null
                            ? bay.pvBayQuantity
                            : 0}
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                }
              })}
            </div>
            <span className="layout horizontal center section-title form-margin">
              Activity Feed
            </span>
            <div className="layout horizontal wrap">
              {pvLocation && (
                <div
                  className="layout vertical form-margin"
                  style={{width: '100%'}}>
                  <ActivityFeed
                    tab="item"
                    locationID={Number(params?.locationId) || null}
                    pvServiceName="warehouse"
                    pvDataID={Number(params?.pvDataID)}
                    pvServiceID={appState?.app.pvServiceID}
                    pvSubEntryID="item"
                    commentsFieldReference={{
                      __overrides: {
                        author: ['pvPersonGivenName', 'pvPersonSurName'],
                        datetime: 'pvCreateDate',
                        comment: 'pvComment',
                      },
                    }}
                    historyFieldReference={{
                      __overrides: {
                        author: ['pvFirstName', 'pvLastName'],
                        datetime: 'pvEntryDate',
                      },
                      pvItemType: 'Item type updated',
                      pvItemQuantity: 'Item quantity updated',
                      pvUnitOfMeasure: ' Unit of measure updated',
                      pvItemNotes: 'Item notes updated',
                      pvItemSpecialInstructions: 'Item special instructions updated',
                      pvTechSpecs: 'Tech specs updated',
                      pvStorageRequirements: 'Storage Location updated',
                      pvStatus: 'Status updated',
                      pvQuantityReceived: 'Quantity Received updated',
                      pvQuantityDistributed: 'Deployment updated',
                      pvRRNumber: 'Resource request number updated',
                      pvLotNumber: 'Lot number updated',
                      pvPOReferenceNumber: 'PO reference number updated',
                      pvPurchaseOrderRefNumber: 'Purchase order ref number updated',
                      pvBenefactor: 'Benefactor updated',
                      pvReqDetCostEst: 'Cost per unit updated',
                      pvManufacturer: 'Manufacturer updated',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default ItemsView;
