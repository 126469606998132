import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

const CloseButton: React.FC<{iconColor?: string} & IconButtonProps> = ({iconColor, ...props}) => {
  return (
    <IconButton
      aria-label="close"
      {...props}>
      <CloseIcon htmlColor={iconColor || '#ffffff'} />
    </IconButton>
  );
};

export default CloseButton;
