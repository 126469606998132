import React from 'react';
import _ from 'lodash';
import {withStyles} from '@mui/styles';
import Tabs, {TabsProps} from '@mui/material/Tabs';
import MuiTab, {TabProps} from '@mui/material/Tab';

import {TabStyles} from './BottomTabs.styles';

const Tab = withStyles(TabStyles)(MuiTab);

const BottomTabs: React.FC<{items: TabProps[]} & TabsProps> = ({items, ...props}) => {
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      {...props}>
      {_.map(items, (item, i) => (
        <Tab
          key={`tab-item[${i}]`}
          {...item}
        />
      ))}
    </Tabs>
  );
};

export default BottomTabs;
