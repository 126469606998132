import React from 'react';
import {List as ListIcon, Info as InfoIcon} from '@mui/icons-material';
import {Container, Stack} from '@mui/material';

import {Nullable} from 'common/utils';
import {MainLayout} from 'components/layouts';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {BottomTabs} from 'components/primitives';
import BaysList from 'pages/BaysList';
import EquipmentList from 'pages/EquipmentList';
import ItemsList from 'pages/ItemsList';
import ReceivedList from 'pages/ReceivedList';
import Location from 'pages/Location';
import {MainProps, TabItem, TabName} from './Main.props';
import useStyles from './Main.styles';

const TAB_ITEMS: TabItem[] = [
  {label: 'General Info', value: null, icon: <InfoIcon />},
  {label: 'Received', value: 'received', icon: <ListIcon />},
  {label: 'Items', value: 'items', icon: <ListIcon />},
  {label: 'Bays', value: 'bays', icon: <ListIcon />},
  {label: 'Equipment', value: 'equipment', icon: <ListIcon />},
];

const TabPanel: React.FC<{
  children?: React.ReactNode;
  name?: Nullable<TabName>;
  selected: Nullable<TabName>;
}> = ({children, name = null, selected}) => {
  const tabKey = ['warehouse-tabpanel', name].filter(Boolean).join('-');
  return (
    <div
      id={tabKey}
      aria-labelledby={tabKey}
      role="tabpanel"
      hidden={name !== selected}
      style={{height: '100%'}}>
      {name === selected && children}
    </div>
  );
};

const MainView: React.FC<MainProps> = ({pvDataID, tabName, ...props}: MainProps) => {
  const classes = useStyles();
  const appStore = useSelectorSafe((state) => state);

  return (
    <MainLayout>
      <Stack className={classes.wrapper}>
        <Container
          maxWidth={false}
          className={classes.page}
          disableGutters>
          {appStore?.location?.data && (
            <React.Fragment>
              <TabPanel selected={tabName}>
                <Location />
              </TabPanel>
              <TabPanel
                name="bays"
                selected={tabName}>
                <BaysList />
              </TabPanel>
              <TabPanel
                name="equipment"
                selected={tabName}>
                <EquipmentList />
              </TabPanel>
              <TabPanel
                name="items"
                selected={tabName}>
                <ItemsList />
              </TabPanel>
              <TabPanel
                name="received"
                selected={tabName}>
                <ReceivedList />
              </TabPanel>
            </React.Fragment>
          )}
        </Container>
        <BottomTabs
          value={tabName}
          variant="fullWidth"
          className={classes.tabs}
          onChange={props.handleTabChange}
          items={TAB_ITEMS}
        />
      </Stack>
    </MainLayout>
  );
};

export default MainView;
