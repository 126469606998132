import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        // 'marginBottom': '10px',
        minWidth: '250px',
        minHeight: '56px',
        fontSize: '16px',
      },
    },
  };
};
