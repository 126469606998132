enum QueryState {
  UNDEFINED = -1,
  AWAIT = 0,
  FAIL = 1,
  READY = 2,
  CREATED = 3,
  UPDATED = 4,
}

export default QueryState;
