import {ILocation} from 'common/interfaces';
import {Nullable} from 'common/utils';

export type LocationState = {
  data: Nullable<ILocation> | Partial<ILocation>;
  history: any[];
};

export const LocationEmptyState: LocationState = {
  data: null,
  history: [],
};
