import React from 'react';
import {ArrowForward} from '@mui/icons-material';
import {ButtonProps} from './Button.props';
import Button from './Button.view';

const NextButton: React.FC<ButtonProps> = (props: ButtonProps) => (
  <Button
    {...props}
    endIcon={<ArrowForward fontSize="small" />}
  />
);

export {NextButton};

export default Button;
