import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layoutWrapper: {
      marginBottom: '10px',
    },
  })
);

const styles = makeStyles((theme: Theme) => ({
  appBarButton: {
    backgroundColor: theme.palette.common.white,
    color: `${theme.palette.text.primary}`,
  },
}));

export {styles};
export default useStyles;
