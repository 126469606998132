/* eslint-disable operator-linebreak */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {NumberFormatInteger} from 'components/primitives';
import YesCancelDialog from 'components/fragments/YesCancelDialog';
import {Alert, FormControl, NativeSelect, TextField, InputLabel, IconButton} from '@mui/material';
import {MdClear} from 'react-icons/md';
import {
  HeaderButton,
  DualTitleHeaderComponent,
  CardComponentDownloadCard,
  DeleteButton,
} from '@zawarski/palmetto-ui-components';
import useWindowDimensions, {
  MOBILE_SIZE,
} from 'common/utils/UseWindowDimensions/UseWindowDimensions';
import apiFetch, {
  createNewEquipmentEntry,
  uploadFile,
  deleteFile,
  deleteEquipmentEntry,
  updateEquipmentEntry,
} from 'services/apiFetch';
import {getEntry, getFiles, getFileImage} from 'helpers/functions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {toShortDateOnly, toISOFormat} from 'common/utils/DateTime';
import {Nullable} from 'common/utils';
import {Loading, FormDialog} from 'components/fragments';
import {MainLayout} from 'components/layouts';
import {CloseButton, UploadFile} from 'components/primitives';
import useStyles from './EquipmentEdit.styles';
import axios from 'axios';
import {IEquipments, IFile} from 'common/interfaces';
import {Controller, SubmitHandler} from 'react-hook-form';
import useEquipmentForm from 'helpers/forms/useEquipmentForm';

const STATUS_ITEMS = [
  'Ready',
  'In Use',
  'Maintenance Required',
  'Replacing Required',
  'In Transit',
];

export const DEPLOYED_OPTIONS = [
  {label: 'No', value: 0},
  {label: 'Yes', value: 1},
];

const EMPTY_EQUPMENT = {
  pvApproximateLocation: null,
  pvBarcode: null,
  pvBay: null,
  pvBayInfo: null,
  pvBayQuantity: null,
  pvBenefactor: null,
  pvCreateDate: null,
  pvDataID: null,
  pvDescription: null,
  pvDomainID: null,
  pvEntryDate: null,
  pvExpirationDate: null,
  pvFederallyDonatedBy: null,
  pvFirstName: null,
  pvFileCount: null,
  pvGlobalID: null,
  pvGroupName: null,
  pvItemID: null,
  pvItemNotes: null,
  pvItemQuantity: null,
  pvItemSpecialInstructions: null,
  pvItemType: null,
  pvLastName: null,
  pvLocationID: null,
  pvLotNumber: null,
  pvManufacturer: null,
  pvPOReferenceNumber: null,
  pvProjectMission: null,
  pvPurchaseOrderRefNumber: null,
  pvQuantityDistributed: null,
  pvQuantityReceived: null,
  pvRRNumber: null,
  pvReceivedDate: null,
  pvReceivedID: null,
  pvReqDetCostEst: null,
  pvSiteName: null,
  pvStatus: null,
  pvStorageRequirements: null,
  pvSubmittedBy: null,
  pvTechSpecs: null,
  pvTotalCases: null,
  pvType: null,
  pvUnitOfMeasure: null,
  pvUsername: null,
  pvVoid: 0,
  v2: null,
};

const EquipmentEdit: React.FC = () => {
  const [itemEntry, setItemEntry] = useState<Nullable<Partial<IEquipments>>>(EMPTY_EQUPMENT);
  const [results, setResults] = useState<Nullable<Partial<IEquipments>>>(EMPTY_EQUPMENT);
  const [sFiles, setSFiles] = useState<IFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const {width} = useWindowDimensions();
  const params = useParams();
  const classes = useStyles();
  const appState = useSelectorSafe((state) => state);
  const pvLocation = appState?.location?.data;
  const roles = appState?.app?.appPermissions || [];
  const roleSave = !(roles.indexOf('UPDATE') > -1);

  const {control, errors, watch, reset, handleSubmit} = useEquipmentForm();

  if (!params?.pvDataID) {
    Object.assign(results || {}, {pvGlobalID: uuidv4(), pvLocationID: params?.locationId});
  }

  const NotiAlert = () => {
    return (
      <Alert
        sx={{color: '#fff'}}
        onClose={() => setShowAlert(false)}
        variant="filled"
        severity={hasError ? 'error' : 'success'}>
        {hasError ? 'Save Failed! Problem Occurred While Saving.' : 'Successfully Save!'}
      </Alert>
    );
  };

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'equipment', (data, error) => {
      if (!error) {
        setItemEntry(data);
        setResults(data);

        // If block to check existing data and set on react hook form initial value for edit and view
        if (data) {
          const parsedData = ['pvEntryDate', 'pvExpirationDate', 'pvReceivedDate'].reduce(
            (obj, o: string) => {
              Object.assign(obj, {[o]: toShortDateOnly(obj[o])});
              return obj;
            },
            data
          );
          reset(parsedData);
        }

        // Get All Uploaded Files
        getFiles(data.pvGlobalID, (data, error) => {
          if (!error) {
            setSFiles(data);
            results!.pvFileCount = data.length || 0;
          } else console.error('getFiles caught an error: ', error);

          setIsLoading(false);
        });
      } else {
        console.error('getReceivedEntry caught an error: ', error);
        setIsLoading(false);
      }
    });
  };

  const addFileToUploadQueue = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      const headers: any = {
        'Content-Type': 'multipart/form-data',
        uuid: results?.pvGlobalID,
        userid: appState?.token?.userId,
        username: pvLocation?.pvUserName,
      };

      formData.append('file', files[i]);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_PALMETTO_ENDPOINT}/files/uploadWithoutID`,
        data: formData,
        headers,
        params: {
          access_token: appState?.token?.id,
          filter: {where: {pvGlobalGroupID: results?.pvGlobalID}},
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            const ftUpload = [...filesToUpload];
            ftUpload.push(resp.data);
            setFilesToUpload(ftUpload);
            results!.pvFileCount = filesToUpload.length + 1;
          }
        })
        .catch((resp) => {
          throw new Error(resp.message);
        });
    }
  };

  const handleClickSave: SubmitHandler<IEquipments> = async (data) => {
    setIsLoading(true);

    const toSaveObj: Partial<IEquipments> = {
      ...results,
      ...data,
      ...{
        pvUserID: appState?.token?.userId || null,
        pvGroupID: appState?.account?.selectedGroupID || null,
      },
    };

    // Upload File
    if (filesToUpload.length > 0) {
      for (let i = 0; i < filesToUpload.length; i++) {
        const filePayload = {
          pvDataID: filesToUpload[i].pvDataID,
          pvGlobalGroupID: results?.pvGlobalID,
        };
        const fileRes = await apiFetch(uploadFile(filePayload));
        if (fileRes && fileRes.status !== 200) {
          setHasError(true);
          setShowAlert(true);
          setIsLoading(false);
        }
      }
    }

    // Delete File
    if (filesToDelete.length > 0) {
      for (let i = 0; i < filesToDelete.length; i++) {
        const file = filesToDelete[i];

        if (file) {
          const fileRes = await apiFetch(deleteFile(file?.pvDataID || ''));

          if (!fileRes || fileRes?.status !== 200) {
            setHasError(true);
            setShowAlert(true);
            setIsLoading(false);
          }
        }
      }
    }

    const res = await apiFetch(
      !params?.pvDataID
        ? createNewEquipmentEntry(toSaveObj, params?.locationId || '')
        : updateEquipmentEntry(toSaveObj, params?.locationId || '', params?.pvDataID || '')
    );

    if (res && res?.status === 200) {
      setItemEntry(toSaveObj);
      setResults(toSaveObj);
      setHasFormError(false);
      setHasError(false);
      setShowAlert(true);
      setIsLoading(false);

      setTimeout(
        () =>
          navigate(
            `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/equipment${
              params?.pvDataID ? `/${params?.pvDataID}` : ''
            }`
          ),
        2000
      );
    } else {
      setHasError(true);
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  const onResultsChange = (field: string, value: any) => {
    let dateValue = null;

    if (field === 'pvExpirationDate') dateValue = toShortDateOnly(value);

    const temp: Partial<IEquipments> = {};
    Object.assign(temp, results);
    temp[field] = field === 'pvExpirationDate' ? dateValue : value;
    setResults(temp);
  };

  const handleClickClose = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/equipment${
        params?.pvDataID ? `/${params?.pvDataID}` : ''
      }`
    );
  };

  const handleDeleteFile = (file: IFile) => {
    const idx = sFiles.findIndex((f: IFile) => f.pvDataID === file.pvDataID);
    const newSFiles = [...sFiles];

    if (idx > -1) newSFiles.splice(idx, 1);

    const ftDelete = [...filesToDelete];
    ftDelete.push(file);
    setFilesToDelete(ftDelete);
    setSFiles(newSFiles);
  };

  const handleDeleteItemEntry = async () => {
    if (params?.locationId && params?.pvDataID) {
      setIsLoading(true);

      const res = await apiFetch(deleteEquipmentEntry(params?.locationId, params?.pvDataID));

      if (res && res.status === 200) {
        setIsLoading(false);
        setHasError(false);
        setShowAlert(false);
        setShowDialog(false);

        // Redirect To ListView Page
        setTimeout(
          () => navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/equipment`),
          500
        );
      } else {
        setHasError(true);
      }
    }
  };

  const parseFileName = (fileName: Nullable<string> = null) => {
    if (fileName) {
      const hasValue = fileName.includes('_DUMMYSEARCHSTRING_');

      if (!!hasValue) {
        const wArr = fileName.split('_');
        return wArr[2];
      }
    }

    return 'No_File_Name.png';
  };

  const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  useEffect(() => {
    setIsLoading(true);
    if (params?.pvDataID && params?.pvDataID) {
      getItems();
    } else setIsLoading(false);
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={<CloseButton onClick={() => handleClickClose()} />}
          titleBig={<span>{pvLocation?.pvSiteName} - Edit</span>}
          titleSmall={
            <span>
              {'Equipment' +
                (isLoading
                  ? '  - Loading'
                  : (itemEntry?.pvItemType ? ' - ' + itemEntry?.pvItemType : '') +
                    (itemEntry?.pvExpirationDate
                      ? ' - Expires ' + toShortDateOnly(itemEntry?.pvExpirationDate)
                      : ''))}
            </span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleSave}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={handleSubmit(handleClickSave)}>
                  SAVE
                </HeaderButton>
              </div>
            </div>
          }
        />
        {isLoading ? (
          <Loading message="Please Wait . . ." />
        ) : (
          <div className="flex form-shell container-overflow-y">
            {showAlert && <NotiAlert />}
            <form>
              <div className="layout horizontal center section-title form-margin">
                Equipment Details
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvItemType"
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => {
                    return (
                      <TextField
                        error={Boolean(error)}
                        required={true}
                        InputLabelProps={{shrink: true}}
                        className={classes.wrapper}
                        label="Equipment name"
                        variant="standard"
                        placeholder="Enter equipment name"
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvItemQuantity"
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => {
                    return (
                      <TextField
                        error={Boolean(error)}
                        required={true}
                        InputLabelProps={{shrink: true}}
                        className={classes.wrapper}
                        label="Quantity"
                        variant="standard"
                        placeholder="Enter quantity"
                        value={value}
                        InputProps={{
                          inputComponent: NumberFormatInteger,
                        }}
                        onChange={onChange}
                      />
                    );
                  }}
                />
                <Controller
                  name="pvExpirationDate"
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => {
                    return (
                      <TextField
                        error={Boolean(error)}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        required={true}
                        className={classes.wrapper}
                        label="Service/Inspection Due Date"
                        variant="standard"
                        placeholder="Enter expiration date"
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvQuantityReceived"
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => {
                    return (
                      <TextField
                        error={Boolean(error)}
                        required={false}
                        InputLabelProps={{shrink: true}}
                        className={classes.wrapper}
                        label="Asset Tag #"
                        variant="standard"
                        placeholder="Enter tag number"
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
                <Controller
                  name="pvQuantityDistributed"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <FormControl
                      variant="standard"
                      size="small"
                      sx={{width: '50%'}}>
                      <InputLabel htmlFor="uncontrolled-native">Deployed</InputLabel>
                      <NativeSelect
                        value={value}
                        className={classes.selectWrapper}
                        placeholder=""
                        inputProps={{
                          name: 'Select deployed status',
                        }}
                        onChange={onChange}>
                        {DEPLOYED_OPTIONS.map((opt, index) => (
                          <option
                            key={index}
                            value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  )}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvRRNumber"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      required={false}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      label="Resource request Number"
                      variant="standard"
                      placeholder="Enter resource request number"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="pvStatus"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <FormControl
                      variant="standard"
                      size="small"
                      sx={{width: '50%'}}>
                      <InputLabel htmlFor="uncontrolled-native">Status</InputLabel>
                      <NativeSelect
                        value={value}
                        className={classes.selectWrapper}
                        placeholder="Select status"
                        inputProps={{
                          name: 'selectStatus',
                        }}
                        onChange={onChange}>
                        {STATUS_ITEMS.map((opt, index) => (
                          <option
                            key={index}
                            value={opt}>
                            {opt}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  )}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvItemNotes"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      multiline
                      required={false}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      label="Comments"
                      variant="standard"
                      placeholder="Enter comments"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvItemSpecialInstructions"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      multiline
                      required={false}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      label="Est. Value"
                      variant="standard"
                      placeholder="Enter Est. Value"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvTechSpecs"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      multiline
                      required={false}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      label="Technical Specifications"
                      variant="standard"
                      placeholder="Enter technical specifications"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name="pvStorageRequirements"
                  control={control}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      multiline
                      required={false}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      label="Storage Location"
                      variant="standard"
                      placeholder="Enter Storage Location"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="layout horizontal center section-title form-margin">
                File Attachments
              </div>
              <div
                className={`${classes.layoutWrapper} layout horizontal wrap`}
                style={{gap: '10px'}}>
                {sFiles.map((file: IFile) => {
                  return (
                    <div
                      key={file?.pvDataID}
                      className={classes.fileAttachementContainer}>
                      <CardComponentDownloadCard
                        className="set-width"
                        icon={
                          <img
                            style={{width: 40, height: 40}}
                            alt=" "
                            src={getFileImage(file)}
                          />
                        }
                        title={
                          <div className="layout vertical">
                            <span className="truncate">
                              {parseFileName(file?.cbrnDataFileName)}
                            </span>
                            <span className="opacity-54">
                              {bytesToSize(Number(file?.cbrnDataFileSize))}
                            </span>
                          </div>
                        }
                        suffix={
                          <IconButton
                            aria-label="Delete"
                            aria-haspopup="true"
                            onClick={(e) => handleDeleteFile(file)}>
                            <MdClear className="icon-2" />
                          </IconButton>
                        }
                      />
                    </div>
                  );
                })}
                <UploadFile
                  previewText="Selected files"
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  onChange={(files) => {
                    addFileToUploadQueue(files);
                  }}
                />
              </div>
            </form>
            {results?.pvDataID ? (
              <div className="layout horizontal form-margin">
                {width && width >= MOBILE_SIZE ? <div className="flex" /> : ''}
                <DeleteButton
                  setwidth={width && width >= MOBILE_SIZE ? 'true' : 'false'}
                  sx={{marginTop: '10px'}}
                  onClick={() => setShowDialog(true)}>
                  DELETE
                </DeleteButton>
              </div>
            ) : (
              <></>
            )}
            <YesCancelDialog
              show={showDialog}
              title="Delete Entry Confirmation"
              message="Deleting this entry cannot be undone. Are you sure you want to continue?"
              handleClose={() => setShowDialog(false)}
              handleAgree={() => {
                handleDeleteItemEntry();
              }}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default EquipmentEdit;
