import apiFetch, {getUploadedFiles} from 'services/apiFetch';
import {IFile, ICallBackError} from 'common/interfaces';
import {Nullable} from 'common/utils';

const getFiles = async (
  pvGlobalID: string,
  cb: (data: IFile[], error: Nullable<ICallBackError>) => void
) => {
  const res = await apiFetch(getUploadedFiles(pvGlobalID));

  if (res && res.status === 200) {
    cb(res.data, null);
  } else cb([], {status: res.status, message: '[getFiles]: Failed to get data'});
};

export default getFiles;
