import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      fontSize: '16px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  },
});
