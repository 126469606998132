import React from 'react';
import {MdAccessTime} from 'react-icons/md';
import {Button, Stack, TextField} from '@mui/material';

import {ActivityFeedEntry, ActivityFeedProps} from './ActivityFeed.props';
import useStyles from './ActivityFeed.styles';

const HistoryFeed: React.FC<{item: Partial<ActivityFeedEntry>}> = ({item}) => (
  <div className="layout horizontal ui-history-container">
    {/* This is the icon container */}
    {item.hideVertLine ? (
      <div className="layout vertical ui-history-icon-container">
        <MdAccessTime className="ui-history-icon" />
      </div>
    ) : (
      <div className="layout vertical center-justified ui-history-icon-container">
        <MdAccessTime className="ui-history-icon" />
        <div className="ui-history-vert-line" />
      </div>
    )}
    {/* This is the text container */}
    <div className="flex layout vertical">
      <div className="ui-history-title">
        {item.id} {item.text}
      </div>
      <div className="layout horizontal ui-history-metadata">
        <span className="ui-activityfeed-author">{item.author}</span>
        <span className="ui-activityfeed-datetime"> &nbsp; @ {item.datetime}</span>
      </div>
    </div>
  </div>
);

const CommentFeed: React.FC<{item: Partial<ActivityFeedEntry>}> = ({item}) => (
  <div className="layout vertical">
    <div className="ui-comment-container ui-activity-comment">{item.text}</div>

    <div className="layout horizontal">
      <div className="ui-flipped-right-triangle" />
      <div
        style={{paddingTop: '8px'}}
        className="layout horizontal">
        <span className="ui-activityfeed-author">{item.author}</span>
        <span className="ui-activityfeed-datetime"> @ {item.datetime}</span>
      </div>
    </div>
  </div>
);

const ActivityFeedView: React.FC<ActivityFeedProps> = ({
  comments,
  entries,
  ...props
}: ActivityFeedProps) => {
  const classes = useStyles();
  return (
    <div className="layout vertical ui-activity-feed-container">
      <div
        className="layout horizontal ui-comments-box"
        style={{paddingLeft: 0}}>
        <Stack
          flexDirection="row"
          sx={{width: '100%'}}>
          <TextField
            placeholder="Add comment..."
            sx={{border: 'none', flex: '1 1 1px'}}
            inputProps={{
              style: {paddingLeft: 15},
            }}
            value={comments || ''}
            onChange={props.handleChangeComment}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.handleSaveComment();
              }
            }}
          />
          <Button onClick={() => props.handleSaveComment()}>POST</Button>
        </Stack>
      </div>
      {entries.map((item, index) => {
        if (item.type === 'history') {
          return (
            <HistoryFeed
              key={`activity-feed[${index}]`}
              item={item}
            />
          );
        }
        return (
          <CommentFeed
            key={`comment-feed[${index}]`}
            item={item}
          />
        );
      })}
    </div>
  );
};

export default ActivityFeedView;
