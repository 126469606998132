import {useForm} from 'react-hook-form';
import {useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {IItem} from 'common/interfaces';
import {toShortDateOnly} from 'common/utils/DateTime';

export const defaultValueReceivedItem = {
  pvItemType: '',
  pvManufacturer: '',
  pvLotNumber: '',
  pvExpirationDate: '',
  pvQuantityReceived: 0,
  pvUnitOfMeasure: 'Each',
  pvReqDetCostEst: '0',
  pvStorageRequirements: '',
  pvItemNotes: '',
};

function useReceivedItemForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        pvItemType: yup.string().required('Item Type is required'),
        pvManufacturer: yup.string(),
        pvLotNumber: yup.string(),
        pvExpirationDate: yup.string(),
        pvQuantityReceived: yup.number().min(1, 'Received Qty cannot be zero'),
        pvUnitOfMeasure: yup.string().required('Unit Measure is required'),
        pvReqDetCostEst: yup.string(),
        pvStorageRequirements: yup.string(),
        pvItemNotes: yup.string(),
      }),
    []
  );

  const {
    control,
    formState: {errors},
    watch,
    reset,
    setValue,
    handleSubmit,
  } = useForm<Partial<IItem>>({
    defaultValues: defaultValueReceivedItem,
    resolver: yupResolver(validationSchema),
  });

  return {
    control,
    errors,
    watch,
    reset,
    setValue,
    handleSubmit,
  };
}

export default useReceivedItemForm;
