/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {toShortDateOnly} from 'common/utils/DateTime';

const BayQuantity = (value: number | null) => {
  return value !== null ? value : 0;
};

const BaysTableColumns: any = [
  {
    flex: 1,
    field: 'pvBay',
    numeric: false,
    headerName: 'Bay',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvBayInfo',
    numeric: false,
    headerName: 'Bay Info',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvBayQuantity',
    numeric: true,
    headerName: 'Bay Quantity',
    enabled: true,
    minWidth: 200,
    renderCell: (param: any) => BayQuantity(param.value),
  },
  {
    flex: 1,
    field: 'pvBenefactor',
    numeric: false,
    headerName: 'Benefactor',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvManufacturer',
    numeric: false,
    headerName: 'Manufacturer',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvItemNotes',
    numeric: false,
    headerName: 'Notes',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvPurchaseOrderRefNumber',
    numeric: false,
    headerName: 'PO # / Reference',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvLotNumber',
    numeric: false,
    headerName: 'Product / Lot #',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvItemType',
    numeric: false,
    headerName: 'Product Type',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvQuantityReceived',
    numeric: true,
    headerName: 'Asset Tag #',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvReceivedDate',
    numeric: false,
    headerName: 'Received Date',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any): string => toShortDateOnly(p.value),
  },
  {
    flex: 1,
    field: 'pvRRNumber',
    numeric: false,
    headerName: 'Resource Request',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvItemSpecialInstructions',
    numeric: false,
    headerName: 'Est. Value',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvStorageRequirements',
    numeric: false,
    headerName: 'Storage Location',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvSubmittedBy',
    numeric: false,
    headerName: 'Submitted By',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvTechSpecs',
    numeric: false,
    headerName: 'Tech Specs',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvUnitOfMeasure',
    numeric: false,
    headerName: 'Unit of Measure',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvEntryDate',
    numeric: false,
    headerName: 'Update Date',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any): string => toShortDateOnly(p.value),
  },
];

export default BaysTableColumns;
