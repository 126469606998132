import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {ApplicationSpinner} from '@zawarski/palmetto-ui-components';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {accountThunk, appThunk} from 'thunks';
import {MainLayout} from 'components/layouts';

const ProtectedPageView: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const appStore = useSelectorSafe((state) => state);
  const account = appStore?.account;
  const token = appStore?.token;

  useEffect(() => {
    if (!token || (account && !account?.authorized)) {
      navigate(`${process.env.REACT_APP_APP_URL_PATH}/login`);
    }
    if (!account) {
      dispatch(accountThunk.initAccount());
    }
  }, [account, token]);

  useEffect(() => {
    dispatch(appThunk.initHistory(params));
  }, []);

  if (!appStore?.account) {
    return (
      <MainLayout>
        <div className="layout vertical vertical-center full-height">
          <ApplicationSpinner />
          <div className="height-20" />
          <span
            style={{padding: '0 16px'}}
            className="text-center title-big opacity-54">
            Please wait while we process your request
          </span>
        </div>
      </MainLayout>
    );
  }
  return <Outlet />;
};

export default ProtectedPageView;
