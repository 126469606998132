/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {phoneFormat} from 'common/utils/Number';

const WarehouseTableColumns: any = [
  {
    flex: 1,
    field: 'pvLocationType',
    numeric: false,
    headerName: 'Type',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvSiteName',
    numeric: false,
    headerName: 'Name',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvSitePOC',
    numeric: false,
    headerName: 'POC',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvSiteID',
    numeric: false,
    headerName: 'ID',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvPhoneNumber',
    numeric: false,
    headerName: 'Phone',
    enabled: true,
    minWidth: 100,
    renderCell: (p: any): string => {
      return phoneFormat(p.value);
    },
  },
  {
    flex: 1,
    field: 'pvFaxNumber',
    numeric: false,
    headerName: 'Fax',
    enabled: true,
    minWidth: 100,
    renderCell: (p: any): string => {
      return phoneFormat(p.value);
    },
  },
  {
    flex: 1,
    field: 'pvCellPhoneNumber',
    numeric: false,
    headerName: 'Mobile',
    enabled: true,
    minWidth: 100,
    renderCell: (p: any): string => {
      return phoneFormat(p.value);
    },
  },
  {
    flex: 1,
    field: 'pvAddress',
    numeric: false,
    headerName: 'Address',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvCounty',
    numeric: false,
    headerName: 'County',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvLatitude',
    numeric: false,
    headerName: 'Latitude',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvLongitude',
    numeric: false,
    headerName: 'Longitude',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvAddress2',
    numeric: false,
    headerName: 'Address 2',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvCity',
    numeric: false,
    headerName: 'City',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvState',
    numeric: false,
    headerName: 'State',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvZip',
    numeric: false,
    headerName: 'Zip',
    enabled: true,
    minWidth: 100,
  },
  {
    flex: 1,
    field: 'pvEmailAddress',
    numeric: false,
    headerName: 'Email',
    enabled: true,
    minWidth: 100,
  },
];

export default WarehouseTableColumns;
