import React from 'react';
import {useNavigate} from 'react-router-dom';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {TableList} from 'components/fragments';
import {joinPathFormat} from 'common/utils/String';
import {BaysTableColumns} from 'helpers/tables';

const BaysListView: React.FC = () => {
  const appState = useSelectorSafe((state) => state);
  const navigate = useNavigate();

  const handleRowSelect = (item: any) => {
    navigate(joinPathFormat([location.pathname, item.pvDataID]));
  };

  return (
    <TableList
      auth={{
        accessToken: appState?.token?.id,
        userId: appState?.token?.userId,
        rooms: ['warehouse_bay'],
      }}
      columns={BaysTableColumns}
      location={appState?.location?.data}
      reqEndpoint="bay"
      subtitlePrefix={'Bays'}
      onRowSelect={(r) => handleRowSelect(r.row)}
    />
  );
};

export default BaysListView;
