import {Dispatch} from 'redux';

import apiFetch, {getLocation} from 'services/apiFetch';
import {Store} from 'store/state/Store';
import {locationActions} from 'store/actions';

const initHistory =
  (params: any) =>
  async (dispatch: Dispatch, getState: () => Store): Promise<any> => {
    const {location} = getState();

    if (params.locationId && !location?.data) {
      const res = await apiFetch(getLocation(params.locationId));
      dispatch(locationActions.update({data: res.data}));
    }
  };

export default initHistory;
