/* eslint-disable @typescript-eslint/no-explicit-any */
import AES from 'crypto-js/aes';
import {AuthProtect} from 'common/enums';
import {IEndpoint} from 'common/interfaces';

import {CommentParams} from 'common/interfaces/Params';
// Accounts and Users EndPoints
export const authLogin: (username: string, password: string) => IEndpoint = (
  username: string,
  password: string
) => {
  const passwordKey = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
  if (!passwordKey) {
    throw new Error('Missing password encryption key');
  }
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'accounts/login',
    method: 'POST',
    data: {
      password: AES.encrypt(password, passwordKey).toString(),
      username,
    },
  };
};

export const getAccountsData: (userId: number) => IEndpoint = (userId) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'accounts/fetchData',
  method: 'GET',
  params: {
    filter: {
      where: {
        id: userId,
      },
      include: [
        {
          relation: 'account2positions',
          scope: {
            include: {
              relation: 'positions',
            },
          },
        },
        {
          relation: 'account2groups',
        },
      ],
    },
  },
});

export const GetAccountGroups: IEndpoint = {
  authorization: AuthProtect.REQUIRED,
  url: 'groups',
  method: 'GET',
};

export const getApps: (pvGroupID: number) => IEndpoint = (pvGroupID) => ({
  authorization: AuthProtect.REQUIRED,
  url: `accounts/apps/${pvGroupID}`,
  method: 'GET',
});

// Comments EndPoints
export const getComments: (pvServiceID: number, pvEntryDataID: number) => IEndpoint = (
  pvServiceID,
  pvEntryDataID
) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'comments',
  method: 'GET',
  params: {
    filter: {
      where: {pvServiceID, pvEntryDataID},
    },
  },
});

export const putComment: (payload: Partial<CommentParams>) => IEndpoint = (payload) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'comments',
  method: 'PUT',
  data: payload,
});

// Location EndPoints
export const getLocationHistory: (pvServiceName: string, pvDataID: number) => IEndpoint = (
  pvServiceName,
  pvDataID
) => ({
  authorization: AuthProtect.REQUIRED,
  url: `${pvServiceName}/${pvDataID}/history`,
  method: 'GET',
});

export const getHistory: (
  pvServiceName: string,
  locationID: number,
  pvDataID: number,
  tab: string
) => IEndpoint = (pvServiceName, locationID, pvDataID, tab) => ({
  authorization: AuthProtect.REQUIRED,
  url: `${pvServiceName}/${locationID}/${tab.length > 0 ? `${tab}/` : ''}${pvDataID}/history`,
  method: 'GET',
});

export const getLocation: (pvDataID?: number | string) => IEndpoint = (pvDataID = '') => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${pvDataID}`,
  method: 'GET',
});

export const createNewLocationdEntry: (payload: any) => IEndpoint = (payload) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: 'warehouse',
  method: 'POST',
  data: payload,
});

export const updateLocationEntry: (payload: any, locationId: string) => IEndpoint = (
  payload,
  locationId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}`,
  method: 'PUT',
  data: payload,
});

export const deleteLocationEntry: (locationId: string) => IEndpoint = (locationId) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}`,
  method: 'DELETE',
});

// Receive Endpoints
export const createNewReceivedEntry: (payload: any, locationId: string) => IEndpoint = (
  payload,
  locationId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/received`,
  method: 'POST',
  data: payload,
});

export const updateReceivedEntry: (
  payload: any,
  locationId: string,
  receivedId: string
) => IEndpoint = (payload, locationId, receivedId) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/received/${receivedId}`,
  method: 'PUT',
  data: payload,
});

export const deleteReceiveEntry: (locationId: string, itemId: string) => IEndpoint = (
  locationId,
  itemId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/received/${itemId}`,
  method: 'DELETE',
});

// Files EndPoints
export const getUploadedFiles: (pvGlobalID: string) => IEndpoint = (pvGlobalID) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'files',
  method: 'GET',
  params: {
    filter: {where: {pvGlobalGroupID: pvGlobalID}},
  },
});

export const uploadFile: (payload: any) => IEndpoint = (payload) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'files/updateID',
  data: payload,
  method: 'PUT',
});

// export const deleteFile: (
//   payload: any
// ) => IEndpoint = (payload) => ({
//   authorization: AuthProtect.REQUIRED,
//   url: 'files/delete',
//   data: payload,
//   method: 'PUT'
// });

export const deleteFile: (pvDataID: string | number) => IEndpoint = (pvDataID) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `filelibrary/${pvDataID}`,
  method: 'PUT',
});

// Item EndPoints
export const updateItemEntry: (payload: any, locationId: string, itemId: string) => IEndpoint = (
  payload,
  locationId,
  itemId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/item/${itemId}`,
  method: 'PUT',
  data: payload,
});

export const createNewItemEntry: (payload: any, locationId: string) => IEndpoint = (
  payload,
  locationId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/item`,
  method: 'POST',
  data: payload,
});

export const deleteItemEntry: (locationId: string, itemId: string) => IEndpoint = (
  locationId,
  itemId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/item/${itemId}`,
  method: 'DELETE',
});

// Bay EndPoints
export const getBayEntry: (
  locPVDataID?: number | string,
  itemPVDataID?: number | string
) => IEndpoint = (locPVDataID = '', itemPVDataID) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locPVDataID}/bay/${itemPVDataID}`,
  method: 'GET',
});

export const getItemEntry: (
  type: string,
  locationId?: number | string,
  pvDataId?: number | string
) => IEndpoint = (type, locationId, pvDataId) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/${type}/${pvDataId}`,
  method: 'GET',
});

export const createNewBaysEntry: (payload: any, locationId: string) => IEndpoint = (
  payload,
  locationId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/bay`,
  method: 'POST',
  data: payload,
});

export const updateBayEntry: (payload: any, locationId: string, itemId: string) => IEndpoint = (
  payload,
  locationId,
  itemId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/bay/${itemId}`,
  method: 'PUT',
  data: payload,
});

export const deleteBayEntry: (locationId: string, itemId: string) => IEndpoint = (
  locationId,
  itemId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/bay/${itemId}`,
  method: 'DELETE',
});

// Equipment EndPoints
export const updateEquipmentEntry: (
  payload: any,
  locationId: string,
  itemId: string
) => IEndpoint = (payload, locationId, itemId) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/equipment/${itemId}`,
  method: 'PUT',
  data: payload,
});

export const createNewEquipmentEntry: (payload: any, locationId: string) => IEndpoint = (
  payload,
  locationId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/equipment`,
  method: 'POST',
  data: payload,
});

export const deleteEquipmentEntry: (locationId: string, itemId: string) => IEndpoint = (
  locationId,
  itemId
) => ({
  authorization: AuthProtect.REQUIRED,
  baseURL: `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/`,
  url: `warehouse/${locationId}/equipment/${itemId}`,
  method: 'DELETE',
});
