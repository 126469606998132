import React from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {FormDialogProps} from './FormDialog.props';

const FormDialog = (props: FormDialogProps) => {
  return (
    <Dialog
      open={props?.show}
      onClose={props?.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props?.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button
          onClick={props?.handleClose}
          autoFocus>
          {props?.cancelLabel || 'Cancel'}
        </Button>
        <Button
          variant="contained"
          onClick={props?.handleSave}>
          {props?.saveLabel || 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
