import React, {useState} from 'react';
import {Controller, Control, UseFormSetValue} from 'react-hook-form';
import {TextField, Autocomplete, FormControl, InputLabel, NativeSelect} from '@mui/material';
import {UNIT_OF_MEASURE_OPTIONS, ITEM_TYPES, STORAGE_REQ} from 'pages/ItemEdit/ItemEdit.list';
import {NumberFormatInteger, DecimalFormatCustom} from 'components/primitives';
import {toShortDateOnly} from 'common/utils/DateTime';
import {IItem} from 'common/interfaces';
import useStyles from './ReceivedEdit.styles';

export interface IReceivedItemDetailsFormProps {
  control: Control<Partial<IItem>, unknown>;
  setValue: UseFormSetValue<Partial<IItem>>;
  readOnly: boolean;
  pvDataID: string;
  totalDistributed?: number;
}

const ReceivedItemDetailsForm: React.FC<IReceivedItemDetailsFormProps> = ({
  control,
  setValue,
  readOnly = true,
  totalDistributed = 0,
  pvDataID,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState<string[]>(ITEM_TYPES);
  const getACValue = (value: string) => {
    const idx = ITEM_TYPES.indexOf(value);
    return idx > -1 ? ITEM_TYPES[idx] : value ? value : '';
  };

  return (
    <>
      <div className="layout horizontal center section-title form-margin">Item Details</div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <Controller
          name={'pvItemType'}
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <Autocomplete
              freeSolo
              className={classes.wrapper}
              options={options}
              value={getACValue(value || '')}
              readOnly={readOnly}
              onChange={(_event: unknown, newValue: string | null) => {
                setValue('pvItemType', newValue);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={Boolean(error)}
                    InputLabelProps={{shrink: true}}
                    required={true}
                    label="Item type"
                    placeholder={!readOnly ? 'Select item type' : ''}
                    variant="standard"
                    onChange={onChange}
                  />
                );
              }}
            />
          )}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <Controller
          name={'pvManufacturer'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              label="Manufacturer"
              variant="standard"
              placeholder={!readOnly ? 'Enter Manufacturer' : ''}
              value={value}
              InputProps={{
                readOnly,
              }}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name={'pvLotNumber'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              label="Product/Lot #"
              variant="standard"
              placeholder={!readOnly ? 'Enter product/lot #' : ''}
              value={value}
              InputProps={{
                readOnly,
              }}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name={'pvExpirationDate'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              InputLabelProps={{shrink: true}}
              type={!readOnly ? 'date' : 'input'}
              className={classes.wrapper}
              label="Expiration date"
              placeholder={!readOnly ? 'Enter Expiration date' : ''}
              variant="standard"
              value={toShortDateOnly(value || '')}
              InputProps={{
                readOnly,
              }}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <Controller
          name={'pvQuantityReceived'}
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              error={Boolean(error)}
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              required={true}
              label="Received"
              variant="standard"
              placeholder={!readOnly ? 'Enter quantity received' : ''}
              value={value}
              InputProps={{
                inputComponent: NumberFormatInteger,
                readOnly,
              }}
              onChange={onChange}
            />
          )}
        />
        <TextField
          InputLabelProps={{shrink: true}}
          className={classes.wrapper}
          label="Quantity distributed"
          variant="standard"
          placeholder={!readOnly ? 'Enter quantity distributed' : ''}
          // value={results?.pvQuantityDistributed || ''}
          value={totalDistributed}
          InputProps={{
            inputComponent: NumberFormatInteger,
            readOnly: true,
          }}
          hidden={!pvDataID}
        />
        <Controller
          name={'pvUnitOfMeasure'}
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <FormControl
              error={Boolean(error)}
              variant="standard"
              className={classes.wrapper}
              size="small"
              sx={{paddingBottom: '8px'}}>
              <InputLabel shrink>Unit of measure</InputLabel>
              <NativeSelect
                value={value}
                placeholder={!readOnly ? 'Select unit of measure' : ''}
                className={classes.selectWrapper}
                sx={{minHeight: 'auto'}}
                disabled={readOnly}
                onChange={onChange}>
                {UNIT_OF_MEASURE_OPTIONS.map((opt, index) => (
                  <option
                    key={index}
                    value={opt}
                    disabled={index === 0}>
                    {opt}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        />
        <Controller
          name={'pvReqDetCostEst'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              label="Cost per unit"
              variant="standard"
              placeholder={!readOnly ? 'Enter cost' : ''}
              value={value}
              InputProps={{
                inputComponent: DecimalFormatCustom,
                readOnly,
              }}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <Controller
          name={'pvStorageRequirements'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <FormControl
              variant="standard"
              className={classes.wrapper}
              size="small"
              sx={{width: '50%', paddingBottom: '8px'}}>
              <InputLabel>Storage requirements</InputLabel>
              <NativeSelect
                value={value}
                className={classes.selectWrapper}
                placeholder={!readOnly ? 'Select storage requirements' : ''}
                disabled={readOnly}
                inputProps={{
                  name: 'selectStorageRequirements',
                }}
                onChange={onChange}>
                {STORAGE_REQ.map((opt, index) => (
                  <option
                    key={index}
                    value={opt}
                    disabled={index === 0}>
                    {opt}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        />
      </div>
      <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
        <Controller
          name={'pvItemNotes'}
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              multiline
              InputLabelProps={{shrink: true}}
              className={classes.wrapper}
              label="Notes"
              variant="standard"
              placeholder={!readOnly ? 'Enter notes' : ''}
              value={value}
              inputProps={{
                readOnly,
              }}
              onChange={onChange}
            />
          )}
        />
      </div>
    </>
  );
};

export default ReceivedItemDetailsForm;
