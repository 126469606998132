import React from 'react';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
// import { LocationProps, LocationPublicProps } from './Location.props';
import ItemEditView from './ItemEdit.view';
import {useNavigate} from 'react-router-dom';
import {ItemEditProps} from './ItemEdit.props';

const ItemEditContainer: React.FC = (ownProps) => {
  const appStore = useSelectorSafe((state) => state);
  const pvLocation = appStore?.location?.data || null;

  const combinedProps: ItemEditProps = {
    ...ownProps,
    pvLocation,
  };

  return <ItemEditView {...combinedProps} />;
};

export default ItemEditContainer;
