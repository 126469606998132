import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: '#4C7BFC',
        fontSize: '14px !important',
        fontWeight: '700 !important',
        // '& .Mui-selected': {
        //   backgroundColor: '#fff!important',
        //   color: '#2961FD!important'
        // }
        '& .Mui-selected': {
          background: 'white !important',
          fontSize: '14px !important',
          fontWeight: '700 !important',
          color: '#2961fd !important',
          // backgroundColor: '#fff!important',
          // color: '#2961FD!important'
        },
      },
    },
  };
};
