import React from 'react';
import {ArrowBack as ArrowBackIcon} from '@mui/icons-material';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

const ArrowBackButtonView: React.FC<{iconColor?: string} & IconButtonProps> = ({
  iconColor,
  ...props
}) => {
  return (
    <IconButton
      aria-label="close"
      {...props}>
      <ArrowBackIcon htmlColor={iconColor || '#ffffff'} />
    </IconButton>
  );
};

export default ArrowBackButtonView;
