import axios, {AxiosRequestHeaders} from 'axios';
import merge from 'deepmerge';
import storeconfig from 'store';

import {IEndpoint} from 'common/interfaces';
import apiConfig from './apiConfig';
import {AuthProtect} from '../common/enums';
import {constants} from 'fs';

export * from './apiParams';
export * from './apiEndpoints';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

const apiFetch = (api: IEndpoint) => {
  const {store} = storeconfig;
  const params = api.params || {};
  const headers: AxiosRequestHeaders = {};

  if (api.authorization === AuthProtect.REQUIRED) {
    const {token} = store.getState();
    if (token) {
      if (api?.headers) Object.assign(headers, api?.headers);

      params.access_token = token.id;
      headers.Authorization = token.id;
    } else {
      // Throw error message: missing token
    }
  }

  return axios({
    ...merge.all([apiConfig, api, {headers, params}]),
    validateStatus: (status: number) => {
      return status < 500;
    },
  })
    .catch((e) => {
      const errorResp = {
        message: genericErrorMessage,
      };
      return {
        status: e.response?.status,
        data: e.response?.data,
      };
    })
    .then((response) => ({
      status: response.status,
      data: response.data,
    }));
};

export default apiFetch;
