import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
    },
    page: {
      flex: '1 1 1px',
    },
    tabs: {
      flex: '0 1 auto',
    },
  })
);

export default useStyles;
