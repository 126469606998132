import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import AppTheme from 'theming/theme';
import StoreConfig from 'store';

import 'typeface-roboto';

import Pages from './pages';

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={StoreConfig.store}>
            <PersistGate persistor={StoreConfig.persistor}>
              <CssBaseline />
              <BrowserRouter>
                <Pages />
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
