/* eslint-disable operator-linebreak */
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  HeaderButton,
  DualTitleHeaderComponent,
  DeleteButton,
} from '@zawarski/palmetto-ui-components';
import {getEntry} from 'helpers/functions';
import {MainLayout} from 'components/layouts';
import useWindowDimensions, {
  MOBILE_SIZE,
} from 'common/utils/UseWindowDimensions/UseWindowDimensions';
import {ArrowBackButton} from 'components/primitives';
import {Loading, YesCancelDialog} from 'components/fragments';
import {toShortDateOnly, toISOFormat} from 'common/utils/DateTime';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import apiFetch, {updateBayEntry, deleteBayEntry, updateItemEntry} from 'services/apiFetch';
import BaysEditForm from './BaysEditForm';
import useStyle from './BaysEdit.styles';
import {IBays, IItem} from 'common/interfaces';
import {SubmitHandler} from 'react-hook-form';
import useBayForm from 'helpers/forms/useBayForm';
import {EMPTY_BAYS_RESULTS} from 'pages/ItemEdit/ItemEdit.view';

const EMPTY_BAYS: IBays = {
  pvApproximateLocation: null,
  pvBarcode: null,
  pvBay: null,
  pvBayID: null,
  pvBayInfo: null,
  pvBayQuantity: 0,
  pvBenefactor: null,
  pvCreateDate: null,
  pvDataID: null,
  pvDescription: null,
  pvDomainID: null,
  pvEntryDate: null,
  pvExpirationDate: null,
  pvFederallyDonatedBy: null,
  pvFirstName: null,
  pvGroupID: null,
  pvGroupName: null,
  pvItemID: null,
  pvItemNotes: null,
  pvItemQuantity: null,
  pvItemSpecialInstructions: null,
  pvItemType: null,
  pvLastName: null,
  pvLocationID: null,
  pvLotNumber: null,
  pvManufacturer: null,
  pvPOReferenceNumber: null,
  pvProjectMission: null,
  pvPurchaseOrderRefNumber: null,
  pvQuantityDistributed: 0,
  pvQuantityReceived: 0,
  pvRRNumber: null,
  pvReceivedDate: null,
  pvReqDetCostEst: null,
  pvSiteName: null,
  pvStatus: null,
  pvStorageRequirements: null,
  pvSubmittedBy: null,
  pvTechSpecs: null,
  pvTotalCases: null,
  pvType: 'item',
  pvUnitOfMeasure: null,
  pvUserID: null,
  pvUsername: null,
  pvVoid: 0,
  v2: null,
};

const BaysEdit: React.FC = () => {
  // const { isEditFromComponent = false } = props;
  const readOnly = true;
  const navigate = useNavigate();
  const {width} = useWindowDimensions();
  const params = useParams();
  const classes = useStyle();
  const appState = useSelectorSafe((state) => state);
  const {control, watch, reset, setValue, handleSubmit} = useBayForm();
  const pvLocation = appState?.location?.data;
  const roles = appState?.app?.appPermissions || [];
  const roleEdit = !(roles.indexOf('UPDATE') > -1);
  const [itemEntry, setItemEntry] = useState<Partial<IBays>>(EMPTY_BAYS);
  const [results, setResults] = useState<Partial<IBays>>(EMPTY_BAYS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showQDError, setShowQDError] = useState<boolean>(false);
  const [bayQuantity, setBayQuantity] = useState<number>(0);
  const [quantityDistributed, setQuantityDistributed] = useState<number>(0);
  const [whItem, setWHItem] = useState<Partial<IItem>>({});

  const onChangeBayQty = (num: number) => {
    return num;
  };

  const monitorBayQty = useMemo(() => onChangeBayQty(bayQuantity), [bayQuantity]);

  useEffect(() => {
    const subscription =
      watch &&
      watch((value, {name}) => {
        if (name === 'pvQuantityDistributed') {
          let bQty = monitorBayQty;
          if (monitorBayQty !== bayQuantity) {
            bQty = Number(value?.pvBayQuantity || 0);
            setValue('pvBayQuantity', bayQuantity);
          }
          const newBayQty = bQty - Number(value?.pvQuantityDistributed || 0);
          setValue('pvBayQuantity', newBayQty);
          setQuantityDistributed(Number(value?.pvQuantityDistributed || 0));
          if (newBayQty < 0) {
            setShowQDError(true);
          }
        }

        const tempRes = {
          ...results,
          ...value,
        };
        setResults(tempRes);
      });
    return () => subscription.unsubscribe();
  }, [watch, bayQuantity]);

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'bay', (data, error) => {
      if (!error) {
        // Get Warehouse Items
        getWHItems(data.pvItemID);
        let tempData = {
          ...data,
        };
        if (!isNaN(data?.pvQuantityDistributed)) {
          tempData = {
            ...tempData,
            pvQuantityDistributed: 0,
          };
        }
        setItemEntry(tempData);
        reset(tempData);
        setResults(tempData);
        setBayQuantity(data?.pvBayQuantity);
        setIsLoading(false);
      } else {
        console.error('getReceivedEntry caught an error: ', error);
        setIsLoading(false);
      }
    });
  };

  const parseData = (data: Partial<IItem>) => {
    if (data) {
      const parsedData = ['pvEntryDate', 'pvExpirationDate', 'pvReceivedDate'].reduce(
        (obj, o: string) => {
          Object.assign(obj, {[o]: toShortDateOnly(obj[o])});
          return obj;
        },
        data
      );

      return parsedData;
    }

    return {};
  };

  const getWHItems = async (itemId: string) => {
    getEntry(params?.locationId || null, itemId || null, 'item', (data, error) => {
      if (!error) {
        const parsedData: Partial<IItem> = parseData(data);
        setWHItem(parsedData);
      } else {
        throw new Error(`[getWHItems] caught an error: ${error.status} - ${error.message}`);
      }
    });
  };

  const handleClickSave: SubmitHandler<Partial<IBays>> = async (data) => {
    setIsLoading(true);

    const newQuantityDist = Number(data?.pvQuantityDistributed) + Number(quantityDistributed);

    const toSaveObj: Partial<IBays> = {
      ...data,
      pvReceivedDate: data?.pvReceivedDate ? toISOFormat(data?.pvReceivedDate || new Date()) : '',
      pvExpirationDate: data?.pvExpirationDate ? toISOFormat(data?.pvExpirationDate) : '',
      pvQuantityDistributed: newQuantityDist,
      pvUserID: appState?.token?.userId || null,
      pvGroupID: appState?.account?.selectedGroupID || null,
    };

    const newBays = whItem.bays?.reduce((acc, curr) => {
      if (curr.pvDataID === toSaveObj.pvDataID) {
        return acc.concat(toSaveObj);
      } else {
        return acc.concat(curr);
      }
    }, []);

    const res = await apiFetch(
      updateBayEntry(toSaveObj, params?.locationId || '', params?.pvDataID || '')
    );

    // Update Item Quantity Distributed
    const itemsToSaveObj: Partial<IItem> = {...whItem};
    itemsToSaveObj.bays = newBays;
    itemsToSaveObj.pvQuantityDistributed = newQuantityDist;

    const itemSaveRes = await apiFetch(
      updateItemEntry(itemsToSaveObj, params?.locationId || '', `${data.pvItemID}`)
    );

    if (res.status === 200) {
      setItemEntry(toSaveObj);
      setResults(toSaveObj);
      setIsLoading(false);

      setTimeout(
        () =>
          navigate(
            `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/bays${
              params?.pvDataID ? `/${params?.pvDataID}` : ''
            }`
          ),
        2000
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleDeleteItemEntry = async () => {
    if (params?.locationId && params?.pvDataID) {
      setIsLoading(true);

      const res = await apiFetch(deleteBayEntry(params?.locationId, params?.pvDataID));

      if (res && res.status === 200) {
        setIsLoading(false);
        setShowDeleteDialog(false);

        // Redirect To ListView Page
        setTimeout(
          () => navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/bays`),
          1000
        );
      }
    }
  };

  useEffect(() => {
    if (params?.locationId && params?.pvDataID) {
      getItems();
    }
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={
            <ArrowBackButton
              onClick={() =>
                navigate(
                  `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/bays/${params?.pvDataID}`
                )
              }
            />
          }
          titleBig={<span>{pvLocation?.pvSiteName} - Edit</span>}
          titleSmall={
            <span>
              {'Bay' +
                (isLoading
                  ? '   Loading'
                  : (itemEntry?.pvPurchaseOrderRefNumber
                      ? '  PO/Ref# ' + itemEntry?.pvPurchaseOrderRefNumber
                      : itemEntry?.pvBenefactor
                      ? '  Benefactor ' + itemEntry?.pvBenefactor
                      : '') +
                    (itemEntry?.pvItemType ? '  ' + itemEntry?.pvItemType : '') +
                    (itemEntry?.pvBay ? '  ' + itemEntry?.pvBay : ''))}
            </span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleEdit}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={handleSubmit(handleClickSave)}>
                  SAVE
                </HeaderButton>
              </div>
            </div>
          }
        />
        {isLoading ? (
          <Loading message="Please wait . . ." />
        ) : (
          <div className="flex form-shell container-overflow-y">
            <BaysEditForm
              isItemNew={false}
              control={control}
              setValue={setValue}
              fromBay={true}
              results={results}
              quantityDistributed={quantityDistributed}
              setQuantityDistributed={setQuantityDistributed}
            />
            {results?.pvDataID ? (
              <div className="layout horizontal form-margin">
                {width && width >= MOBILE_SIZE ? <div className="flex" /> : ''}
                <DeleteButton
                  setwidth={width && width >= MOBILE_SIZE ? 'true' : 'false'}
                  onClick={() => setShowDeleteDialog(true)}>
                  DELETE
                </DeleteButton>
              </div>
            ) : (
              <></>
            )}
            <YesCancelDialog
              show={showDeleteDialog}
              title="Delete Entry Confirmation"
              message="Deleting this entry cannot be undone. Are you sure you want to continue?"
              handleClose={() => setShowDeleteDialog(false)}
              handleAgree={() => {
                handleDeleteItemEntry();
              }}
            />
            <YesCancelDialog
              show={showQDError}
              title="Bay Error!"
              message="Quantity Distributed cannot be more than Bays Quantity!"
              haveCancelButton={false}
              handleAgree={() => {
                setShowQDError(false);
                setQuantityDistributed(0);
              }}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default BaysEdit;
