/* eslint-disable operator-linebreak */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {
  HeaderButton,
  DualTitleHeaderComponent,
  CardComponentDownloadCard,
} from '@zawarski/palmetto-ui-components';
import {getEntry, getFiles, getFileImage} from 'helpers/functions';
import {NormalTextField as Field} from 'components/zawarski';
import {MainLayout} from 'components/layouts';
import {ArrowBackButton} from 'components/primitives';
import {ActivityFeed, Loading} from 'components/fragments';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import useWindowDimensions from 'common/utils/UseWindowDimensions/UseWindowDimensions';
import {toShortDateOnly} from 'common/utils/DateTime';
import {Nullable} from 'common/utils';
import {IFile, IEquipments} from 'common/interfaces';
import {DEPLOYED_OPTIONS} from 'pages/EquipmentEdit/EquipmentEdit.view';

const useStyles = makeStyles({
  layoutWrapper: {
    marginBottom: '10px',
  },
});

const EquipmentView: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const appState = useSelectorSafe((state) => state);
  const pvLocation = appState?.location?.data;
  const roles = appState?.app?.appPermissions || [];
  const roleEdit = !(roles.indexOf('UPDATE') > -1);
  const serviceID = appState?.app.pvServiceID;
  const [itemEntry, setItemEntry] = useState<Nullable<IEquipments>>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = appState?.token?.id;
  const classes = useStyles();

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'equipment', (data, error) => {
      if (!error) {
        setItemEntry(data);

        getFiles(data.pvGlobalID, (data, error) => {
          if (!error) setFiles(data);
          else {
            throw new Error(
              `[getEquipmentFile]: caught an error: ${error.status} - ${error.message}`
            );
          }

          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        throw new Error(`[getEquipmentEntry]: caught an error: ${error.status} - ${error.message}`);
      }
    });
  };

  const handleClickEdit = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/equipment/${params?.pvDataID}/edit`
    );
  };

  const parseFileName = (fileName: Nullable<string> = null) => {
    if (fileName) {
      const hasValue = fileName.includes('_DUMMYSEARCHSTRING_');

      if (!!hasValue) {
        const wArr = fileName.split('_');
        return wArr[2];
      }
    }

    return 'No_File_Name.png';
  };

  const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  const getDeployedValue = (value: number) => {
    const result = DEPLOYED_OPTIONS[value];
    return result.label;
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={
            <ArrowBackButton
              onClick={() =>
                navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/equipment`)
              }
            />
          }
          titleBig={<span>{pvLocation?.pvSiteName} - View</span>}
          titleSmall={
            <span>
              {'Equipment' +
                (isLoading
                  ? '  - Loading'
                  : (itemEntry?.pvItemType ? ' - ' + itemEntry?.pvItemType : '') +
                    (itemEntry?.pvExpirationDate
                      ? ' - Expires ' + toShortDateOnly(itemEntry?.pvExpirationDate)
                      : ''))}
            </span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleEdit}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={() => handleClickEdit()}>
                  EDIT
                </HeaderButton>
              </div>
            </div>
          }
        />
        {isLoading ? (
          <Loading message="Please wait . . ." />
        ) : (
          <div className="flex form-shell container-overflow-y">
            <div className="layout horizontal center section-title form-margin">
              Order Information
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Equipment Name"
                field="pvItemType"
                value={itemEntry?.pvItemType}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Quantity"
                field="pvItemQuantity"
                value={itemEntry?.pvItemQuantity}
              />
              <Field
                label="Service/Inspection Due Date"
                field="pvExpirationDate"
                value={toShortDateOnly(itemEntry?.pvExpirationDate || new Date())}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Asset Tag #"
                field="pvQuantityReceived"
                value={itemEntry?.pvQuantityReceived}
              />
              <Field
                label="Deployed"
                field="pvQuantityDistributed"
                value={getDeployedValue(Number(itemEntry?.pvQuantityDistributed) || 0)}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Resource request number"
                field="pvRRNumber"
                value={itemEntry?.pvRRNumber}
              />
              <Field
                label="Status"
                field="pvStatus"
                value={itemEntry?.pvStatus}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                multiline
                label="Comments"
                field="pvItemNotes"
                value={itemEntry?.pvItemNotes}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Est. Value"
                field="pvItemSpecialInstructions"
                value={itemEntry?.pvItemSpecialInstructions}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                multiline
                label="Technical specifications"
                field="pvTechSpecs"
                value={itemEntry?.pvTechSpecs}
              />
            </div>
            <div className="layout horizontal wrap">
              <Field
                label="Storage Location"
                field="pvStorageRequirements"
                value={itemEntry?.pvStorageRequirements}
              />
            </div>
            <div className="layout horizontal center section-title form-margin">
              File Attachments
            </div>
            <div
              className={`${classes.layoutWrapper} layout horizontal wrap`}
              style={{gap: '10px'}}>
              {files.map((file: IFile) => {
                const newURIID = file?.cbrnDataFileURIID
                  ? file?.cbrnDataFileURIID.replace('/api', '')
                  : '';
                const dlLink = `${process.env.REACT_APP_PALMETTO_ENDPOINT}${newURIID}?access_token=${token}`;
                return (
                  <a
                    key={file?.pvDataID}
                    href={dlLink}
                    target="_blank"
                    rel="noreferrer"
                    title={getFileImage(file)}
                    download>
                    <CardComponentDownloadCard
                      className="set-width"
                      icon={
                        <img
                          style={{width: 40, height: 40}}
                          alt=" "
                          src={getFileImage(file)}
                        />
                      }
                      title={
                        <div className="layout vertical">
                          <span className="truncate">{parseFileName(file?.cbrnDataFileName)}</span>
                          <span className="opacity-54">
                            {bytesToSize(Number(file?.cbrnDataFileSize))}
                          </span>
                        </div>
                      }
                    />
                  </a>
                );
              })}
            </div>
            <div className="layout horizontal center section-title form-margin">Activity Feed</div>
            <div className="layout horizontal wrap">
              {pvLocation && (
                <div
                  className="layout vertical form-margin"
                  style={{width: '100%'}}>
                  <ActivityFeed
                    tab="equipment"
                    locationID={Number(params?.locationId) || null}
                    pvServiceName="warehouse"
                    pvDataID={Number(params?.pvDataID)}
                    pvServiceID={serviceID}
                    pvSubEntryID="equipment"
                    commentsFieldReference={{
                      __overrides: {
                        author: ['pvPersonGivenName', 'pvPersonSurName'],
                        datetime: 'pvCreateDate',
                        comment: 'pvComment',
                      },
                    }}
                    historyFieldReference={{
                      __overrides: {
                        author: ['pvFirstName', 'pvLastName'],
                        datetime: 'pvEntryDate',
                      },
                      pvItemType: 'Item type updated',
                      pvItemQuantity: 'Item quantity updated',
                      pvUnitOfMeasure: ' Unit of measure updated',
                      pvItemNotes: 'Item notes updated',
                      pvItemSpecialInstructions: 'Item special instructions updated',
                      pvTechSpecs: 'Tech specs updated',
                      pvStorageRequirements: 'Storage Location updated',
                      pvStatus: 'Status updated',
                      pvQuantityReceived: 'Quantity received updated',
                      pvQuantityDistributed: 'Est. Value updated',
                      pvRRNumber: 'Resource request number updated',
                      pvLotNumber: 'Lot number updated',
                      pvPOReferenceNumber: 'PO reference number updated',
                      pvPurchaseOrderRefNumber: 'Purchase order ref number updated',
                      pvBenefactor: 'Benefactor updated',
                      pvReqDetCostEst: 'Cost per unit updated',
                      pvManufacturer: 'Manufacturer updated',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default EquipmentView;
