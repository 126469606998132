import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: theme.palette.common.white,
    },
    page: {
      width: '100%',
      height: '100%',
    },
  })
);

export default useStyles;
