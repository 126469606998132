import {Nullable} from 'common/utils';

export type AppState = {
  appName: string;
  appPermissions: string[];
  path: Nullable<string>;
  pvDatasources: Nullable<string>;
  pvFeatures: Nullable<string>;
  pvParentServiceID: Nullable<number>;
  pvServiceID: number;
  route: Nullable<string>;
  settings: unknown[];
  title: string;
};

export const AppEmptyState: Partial<AppState> = {
  appName: process.env.REACT_APP_APP_NAME,
  appPermissions: [],
  title: process.env.REACT_APP_APP_TITLE,
};
