import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {YesCancelDialogPublicProps} from './YesCancelDialog.props';

const YesCancelDialog = (props: YesCancelDialogPublicProps) => {
  const {
    haveAgreeButton = true,
    haveCancelButton = true,
    agreeLabel = 'OKAY',
    cancelLabel = 'CANCEL',
  } = props;

  return (
    <Dialog
      open={props?.show}
      onClose={props?.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props?.title || 'Confirmation'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props?.message || ''}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props?.handleClose}
          autoFocus
          sx={{display: haveCancelButton ? 'block' : 'none'}}>
          {cancelLabel}
        </Button>
        <Button
          variant="contained"
          onClick={props?.handleAgree}
          sx={{display: haveAgreeButton ? 'block' : 'none'}}>
          {agreeLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YesCancelDialog;
