import {createTheme} from '@mui/material/styles';
import MuiAppBarStyles from './overrides/MuiAppBar';
import MuiButtonStyles from './overrides/MuiButton';
import MuiInputLabel from './overrides/MuiInputLabel';
import MuiLinkStyles from './overrides/MuiLink';
import MuiOutlinedInputStyles from './overrides/MuiOutlinedInput';
import MuiPaperStyles from './overrides/MuiPaper';
import MuiTextField from './overrides/MuiTextField';
import MuiNativeSelect from './overrides/MuiNativeSelect';

import lightPalette from './palettes/lightPallete';
import typography from './typography';
import MuiFab from './overrides/MuiFab';
import MuiTabs from './overrides/MuiTabs';
import MuiFormControlLabel from './overrides/MuiFormControlLabel';

const theme = createTheme({
  palette: lightPalette,
  components: {
    MuiAppBar: MuiAppBarStyles(lightPalette),
    MuiButton: MuiButtonStyles(lightPalette),
    MuiLink: MuiLinkStyles(lightPalette),
    MuiOutlinedInput: MuiOutlinedInputStyles(lightPalette),
    MuiPaper: MuiPaperStyles(lightPalette),
    MuiInputLabel: MuiInputLabel(lightPalette),
    MuiTextField: MuiTextField(lightPalette),
    MuiNativeSelect: MuiNativeSelect(lightPalette),
    MuiFab: MuiFab(lightPalette),
    MuiTabs: MuiTabs(lightPalette),
    MuiFormControlLabel: MuiFormControlLabel(lightPalette),
  },
  typography,
});

export default theme;
