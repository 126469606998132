/* eslint-disable quote-props */
import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '50vh',
      height: '28vh',
      background: '#eee',
      position: 'relative',
      resize: 'both',
    },
    loader: {
      animation: '1s linear infinite dbrScanner-rotate',
      width: '40%',
      height: '40%',
      position: 'absolute',
      margin: 'auto',
      left: '0',
      top: '0',
      right: '0',
      bottom: '0',
      fill: '#aaa',
    },
    camera: {
      width: '40%',
      height: '40%',
      position: 'absolute',
      margin: 'auto',
      left: '0',
      top: '0',
      right: '0',
      bottom: '0',
      fill: '#aaa',
    },
    video: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    drawArea: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    scanArea: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    scanlight: {
      width: '100%',
      height: '3%',
      position: 'absolute',
      animation: '3s infinite dbrScanner-scanlight',
      borderRadius: '50%',
      boxShadow: '0px 0px 2vw 1px #00e5ff',
      background: '#fff',
    },
    selCamera: {
      margin: '0 auto',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    selResolution: {
      position: 'absolute',
      left: '0',
      top: '20px',
    },
    'dbr-msg-poweredby': {
      display: 'none',
    },
    '@keyframes dbrScanner-rotate': {
      from: {
        transform: 'rotate(0turn)',
      },
      to: {
        transform: 'rotate(1turn)',
      },
    },
    '@keyframes dbrScanner-scanlight': {
      from: {
        top: '0',
      },
      to: {
        top: '97%',
      },
    },
  })
);

const styles = makeStyles((theme: Theme) => ({
  appBarButton: {
    backgroundColor: theme.palette.common.white,
    color: `${theme.palette.text.primary}`,
  },
}));

export {styles};
export default useStyles;
