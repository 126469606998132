/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {toShortDateOnly} from 'common/utils/DateTime';

const EquipmentTableColumns: any = [
  {
    flex: 1,
    field: 'pvExpirationDate',
    numeric: false,
    headerName: 'Service/Inspection Due Date',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any): string => toShortDateOnly(p.value),
  },
  {
    flex: 1,
    field: 'pvItemType',
    numeric: false,
    headerName: 'Equipment Type',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvQuantityDistributed',
    numeric: true,
    headerName: 'Deployed',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any) => (p.value === 1 ? 'Yes' : 'No'),
  },
  {
    flex: 1,
    field: 'pvQuantityReceived',
    numeric: true,
    headerName: 'Asset Tag #',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvReceivedDate',
    numeric: false,
    headerName: 'Received Date',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any): string => toShortDateOnly(p.value),
  },
  {
    flex: 1,
    field: 'pvRRNumber',
    numeric: false,
    headerName: 'Resource Request',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvItemSpecialInstructions',
    numeric: false,
    headerName: 'Est. Value',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvStatus',
    numeric: false,
    headerName: 'Status',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvStorageRequirements',
    numeric: false,
    headerName: 'Storage Location',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvTechSpecs',
    numeric: false,
    headerName: 'Tech Specs',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvEntryDate',
    numeric: false,
    headerName: 'Update Date',
    enabled: true,
    minWidth: 200,
    renderCell: (p: any): string => toShortDateOnly(p.value),
  },
];

export default EquipmentTableColumns;
