/* eslint-disable operator-linebreak */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  HeaderButton,
  DualTitleHeaderComponent,
  CardComponentDownloadCard,
} from '@zawarski/palmetto-ui-components';
import {getEntry, getFiles, getFileImage} from 'helpers/functions';
import {Nullable} from 'common/utils';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {ArrowBackButton} from 'components/primitives';
import {ActivityFeed, Loading} from 'components/fragments';
import {MainLayout} from 'components/layouts';
import ReceivedForm from './ReceivedForm.view';
import ReceivedItems from './ReceivedItems.view';
import {IReceivedItem, IFile} from 'common/interfaces';
import useStyles from './ReceivedView.styles';

const ReceivedView: React.FC = () => {
  const [itemEntry, setItemEntry] = useState<Nullable<IReceivedItem>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<IFile[]>([]);
  const navigate = useNavigate();
  const params = useParams();
  const appState = useSelectorSafe((state) => state);
  const pvLocation = appState?.location?.data;
  const roles = appState?.app?.appPermissions || [];
  const roleEdit = !(roles.indexOf('UPDATE') > -1);
  const serviceID = appState?.app.pvServiceID;
  const token = appState?.token?.id;
  const classes = useStyles();

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'received', (data, error) => {
      if (!error) {
        setItemEntry(data);

        // Get All Uploaded Files
        getFiles(data.pvGlobalID, (data, error) => {
          if (!error) setFiles(data || []);
          else {
            throw new Error(
              `[getFiles - ReceivedView] caught an error: ${error?.status} - ${error?.message}`
            );
          }

          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        throw new Error(
          `[getReceivedEntry - ReceivedView] caught an error: ${error?.status} - ${error?.message}`
        );
      }
    });
  };

  const handleClickEdit = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/received/${params?.pvDataID}/edit`
    );
  };

  const parseFileName = (fileName: Nullable<string> = null) => {
    if (fileName) {
      const hasValue = fileName.includes('_DUMMYSEARCHSTRING_');

      if (!!hasValue) {
        const wArr = fileName.split('_');
        return wArr[2];
      }
    }

    return 'No_File_Name.png';
  };

  const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (params?.locationId && params?.pvDataID) {
      getItems();
    } else setIsLoading(false);
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={
            <ArrowBackButton
              onClick={() =>
                navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/received`)
              }
            />
          }
          titleBig={<span>{pvLocation?.pvSiteName} - View</span>}
          titleSmall={
            <span>{`Received - PO/Ref# ${itemEntry?.pvPurchaseOrderRefNumber || '--'}`}</span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleEdit}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={() => handleClickEdit()}>
                  EDIT
                </HeaderButton>
              </div>
            </div>
          }
        />
        {isLoading ? (
          <Loading message="Please wait . . ." />
        ) : (
          <div className="flex form-shell container-overflow-y">
            <ReceivedForm itemEntry={itemEntry} />
            <div className="layout horizontal center section-title form-margin">
              File Attachements
            </div>
            <div
              className={`${classes.layoutWrapper} layout horizontal wrap`}
              style={{gap: '10px'}}>
              {files.map((file: IFile) => {
                const newURIID = file?.cbrnDataFileURIID
                  ? file?.cbrnDataFileURIID.replace('/api', '')
                  : '';
                const dlLink = `${process.env.REACT_APP_PALMETTO_ENDPOINT}${newURIID}?access_token=${token}`;
                return (
                  <a
                    key={file?.pvDataID}
                    href={dlLink}
                    target="_blank"
                    rel="noreferrer"
                    title={getFileImage(file)}
                    download>
                    <CardComponentDownloadCard
                      className="set-width"
                      icon={
                        <img
                          style={{width: 40, height: 40}}
                          alt=" "
                          src={getFileImage(file)}
                        />
                      }
                      title={
                        <div className="layout vertical">
                          <span className="truncate">{parseFileName(file?.cbrnDataFileName)}</span>
                          <span className="opacity-54">
                            {bytesToSize(file?.cbrnDataFileSize || 0)}
                          </span>
                        </div>
                      }
                    />
                  </a>
                );
              })}
            </div>
            <ReceivedItems itemEntry={itemEntry} />
            <div className="layout horizontal center section-title form-margin">Activity Feed</div>
            <div className="layout horizontal wrap">
              {pvLocation && (
                <div
                  className="layout vertical form-margin"
                  style={{width: '100%'}}>
                  <ActivityFeed
                    tab="received"
                    locationID={Number(params?.locationId) || null}
                    pvServiceName="warehouse"
                    pvDataID={Number(params?.pvDataID)}
                    pvServiceID={serviceID}
                    pvSubEntryID="received"
                    commentsFieldReference={{
                      __overrides: {
                        author: ['pvPersonGivenName', 'pvPersonSurName'],
                        datetime: 'pvCreateDate',
                        comment: 'pvComment',
                      },
                    }}
                    historyFieldReference={{
                      __overrides: {
                        author: ['pvFirstName', 'pvLastName'],
                        datetime: 'pvEntryDate',
                      },
                      pvPurchaseOrderRefNumber: 'Purchase order number updated',
                      pvBenefactor: 'Benefactor updated',
                      pvDescription: 'Description updated',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default ReceivedView;
