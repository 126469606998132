import {handleActions} from 'redux-actions';
import {presetReducers} from 'store/utils/Redux';
import {AccountState} from 'store/state/AccountState';
import {accountActions} from '../actions';

const DEFAULT_STATE: AccountState = null;

const reducer = handleActions<Partial<AccountState>, any>(
  {
    [String(accountActions.register)]: presetReducers.makeSetter(),
    [String(accountActions.clear)]: presetReducers.empty,
  },
  DEFAULT_STATE
);

export default reducer;
