/* eslint-disable react/display-name */
import React from 'react';
import NumberFormat from 'react-number-format';

const DecimalFormatCustom = React.forwardRef((props: any, ref: any) => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      inputMode="decimal"
      pattern="[0-9]*"
      prefix="$"
    />
  );
});

export default DecimalFormatCustom;
