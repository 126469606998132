import React from 'react';
import {AppBar} from '@mui/material';

import useStyles from './MainLayout.styles';

const MainLayoutView: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.page}>{props.children}</div>
    </div>
  );
};

export default MainLayoutView;
