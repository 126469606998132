import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flex: '1 1',
      flexBasis: '1e-9px',
      minWidth: '250px',
      marginLeft: '6px',
      marginRight: '6px',
      // minHeight: '56px'
    },
    layoutWrapper: {
      // marginBottom: '20px'
    },
    selectInputProps: {
      minHeight: '48px !important',
    },
    inputLabelProps: {
      color: 'rgba(0, 0, 0, 0.60)',
    },
    inputProps: {
      fontWeight: '400',
      lineHeight: '1.1876em',
    },
  })
);

const styles = makeStyles((theme: Theme) => ({
  appBarButton: {
    backgroundColor: theme.palette.common.white,
    color: `${theme.palette.text.primary}`,
  },
}));

export {styles};
export default useStyles;
