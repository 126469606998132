import {makeCreateActions, presetActions} from 'store/utils/Redux';
import {AccountState} from 'store/state/AccountState';

const ns = 'ACCOUNT';
const createAccountActions = makeCreateActions(ns);

const accountActions = createAccountActions({
  register: presetActions.makeIdentity<Partial<AccountState>>(),
  clear: presetActions.noPayload,
});

export default accountActions;
