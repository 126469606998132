import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {ProtectedPage} from 'components/core';
import Locations from './LocationsList';
import ItemsView from './ItemsView';
import ReceivedView from './ReceivedView';
import Main from './Main';
// Public Pages
import Login from './Login';
import LocationEditContainer from './LocationEdit';
import ReceivedEdit from './ReceivedEdit';
import ItemEditContainer from './ItemEdit';
import BaysView from './BaysView';
import BaysEdit from './BaysEdit';
import EquipmentView from './EquipmentView';
import EquipmentEdit from './EquipmentEdit';

const Pages: React.FC = () => {
  return (
    <Routes>
      <Route
        path={`${process.env.REACT_APP_APP_URL_PATH}/login`}
        element={<Login />}
      />
      {/* Protected routes */}
      <Route
        path="/"
        element={
          <Navigate
            to={`${process.env.REACT_APP_APP_URL_PATH}`}
            replace
          />
        }
      />
      <Route
        path={`${process.env.REACT_APP_APP_URL_PATH}`}
        element={<ProtectedPage />}>
        {/* <Route path="" element={<Main />} /> */}
        <Route
          path=""
          element={<Locations />}
        />
        <Route
          path="new"
          element={<LocationEditContainer />}
        />
        <Route
          path=":locationId"
          element={<Main />}
        />
        <Route
          path=":locationId/edit"
          element={<LocationEditContainer />}
        />
        <Route
          path=":locationId/:tabName"
          element={<Main />}
        />
        <Route
          path=":locationId/received/:pvDataID"
          element={<ReceivedView />}
        />
        <Route
          path=":locationId/received/new"
          element={<ReceivedEdit />}
        />
        <Route
          path=":locationId/received/:pvDataID/edit"
          element={<ReceivedEdit />}
        />
        <Route
          path=":locationId/items/:pvDataID"
          element={<ItemsView />}
        />
        <Route
          path=":locationId/items/new"
          element={<ItemEditContainer />}
        />
        <Route
          path=":locationId/items/:pvDataID/edit"
          element={<ItemEditContainer />}
        />
        <Route
          path=":locationId/bays/:pvDataID"
          element={<BaysView />}
        />
        <Route
          path=":locationId/bays/:pvDataID/edit"
          element={<BaysEdit />}
        />
        <Route
          path=":locationId/equipment/:pvDataID"
          element={<EquipmentView />}
        />
        <Route
          path=":locationId/equipment/new"
          element={<EquipmentEdit />}
        />
        <Route
          path=":locationId/equipment/:pvDataID/edit"
          element={<EquipmentEdit />}
        />
      </Route>
    </Routes>
  );
};

export default Pages;
