/* eslint-disable operator-linebreak */
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {MdMoreVert} from 'react-icons/md';
import {
  DualTitleHeaderComponent,
  HeaderButton,
  DeleteButton,
} from '@zawarski/palmetto-ui-components';
import {
  FormControl,
  IconButton,
  InputLabel,
  NativeSelect,
  Alert,
  InputBaseComponentProps,
} from '@mui/material';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {locationActions} from 'store/actions';
import apiFetch, {
  updateLocationEntry,
  createNewLocationdEntry,
  deleteLocationEntry,
} from 'services/apiFetch';
import {ArrowBackButton} from 'components/primitives';
import {MainLayout} from 'components/layouts';
import AddressModule from 'components/fragments/AddressModule';
import YesCancelDialog from 'components/fragments/YesCancelDialog';
import {LocationEditProps} from './LocationEdit.props';
import LoadingView from '../../components/fragments/Loading';
import SiteTypes from '../../common/arrays/SiteTypes';
import TextField from '../../components/primitives/TextField';
import NumberFormatPhone from '../../components/primitives/NumberFormatPhone';
import useWindowDimensions, {
  MOBILE_SIZE,
} from '../../common/utils/UseWindowDimensions/UseWindowDimensions';
import useStyles from './LocationEdit.styles';
import {ILocation} from 'common/interfaces';
import {Nullable} from 'common/utils';

import useLocationForm, {ILocationForm} from 'helpers/forms/useLocationForm';
import {SubmitHandler, Controller} from 'react-hook-form';

const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EMPTY_LOCATION: ILocation = {
  pvAddress: null,
  pvAddress2: null,
  pvCellPhoneNumber: null,
  pvCity: null,
  pvCounty: null,
  pvCreateDate: null,
  pvDataID: 0,
  pvDomainID: null,
  pvEmailAddress: null,
  pvEntryDate: null,
  pvFaxNumber: null,
  pvGroupID: 0,
  pvGroupName: null,
  pvLatitude: '',
  pvLocationID: null,
  pvLocationType: null,
  pvLongitude: '',
  pvPhoneNumber: null,
  pvSiteID: null,
  pvSiteName: null,
  pvSitePOC: null,
  pvState: null,
  pvUserID: null,
  pvUserName: null,
  pvVoid: null,
  pvZip: null,
};

const LocationEditView: React.FC<LocationEditProps> = ({
  pvLocation,
  ...props
}: LocationEditProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();
  const appStore = useSelectorSafe((state) => state);
  const classes = useStyles();
  const params = useParams();
  const [results, setResults] = useState<ILocation | Partial<ILocation>>(
    pvLocation || EMPTY_LOCATION
  );
  const [deleting, setDeleting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const roleEdit = useSelectorSafe((state) => {
    const roles = state.app.appPermissions;
    return !(roles.indexOf('UPDATE') > -1);
  });
  const {control, reset, handleSubmit} = useLocationForm();

  let addressModuleGetData = null;
  const id = pvLocation?.pvDataID;

  const NotiAlert = () => {
    return (
      <Alert
        sx={{color: '#fff'}}
        onClose={() => setShowAlert(false)}
        variant="filled"
        severity={hasError ? 'error' : 'success'}>
        {hasError ? 'Save Failed! Problem Occurred While Saving.' : 'Successfully Save!'}
      </Alert>
    );
  };

  const onSaveEntry: SubmitHandler<ILocationForm> = async (data) => {
    setLoading(true);
    const toSaveObj: Partial<ILocation> = {
      ...results,
      ...data,
      ...{pvUserID: appStore?.token?.userId, pvGroupID: appStore?.account?.selectedGroupID},
    };

    const res = await apiFetch(
      params?.locationId
        ? updateLocationEntry(toSaveObj, params?.locationId || '')
        : createNewLocationdEntry(toSaveObj)
    );

    if (res && res.status === 200) {
      setHasError(false);
      setShowAlert(true);

      // Update appStore
      dispatch(locationActions.update({data: toSaveObj}));

      // Redirect To View Page
      setTimeout(
        () =>
          params?.locationId
            ? navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}`)
            : navigate('/'),
        2000
      );
    } else {
      setHasError(true);
      setShowAlert(true);
    }

    setLoading(false);
  };

  const onDeleteEntry = async () => {
    if (params?.locationId) {
      setLoading(true);

      const res = await apiFetch(deleteLocationEntry(params?.locationId));

      if (res && res.status === 200) {
        setLoading(false);
        setHasError(false);
        setShowAlert(true);
        setShowDialog(false);

        // Redirect To ListView Page
        setTimeout(() => navigate('/'), 1000);
      } else {
        setHasError(true);
      }
    }
  };

  const onResultsChange = (field: string, value: any) => {
    const temp: ILocation | Partial<ILocation> = {...results};

    if (field === 'pvEmailAddress') {
      const isValid = value.match(EMAIL_REGEX);
      setIsEmailValid(isValid);
    }

    temp[field] = value;
    setResults(temp);
  };

  const getDataFunc = (func: any) => {
    if (func) addressModuleGetData = func;
  };

  const addressDataCallback = (data: any) => {
    const fields = [
      'pvAddress',
      'pvAddress2',
      'pvCity',
      'pvCounty',
      'pvState',
      'pvZip',
      'pvLatitude',
      'pvLongitude',
    ];

    results!.pvAddress = data.address;
    results!.pvAddress2 = data.address2;
    results!.pvCity = data.city;
    results!.pvCounty = data.county;
    results!.pvState = data.state;
    results!.pvZip = data.zipCode;
    results!.pvLatitude = data.latitude;
    results!.pvLongitude = data.longitude;
  };

  const onClickMore = (e: any) => {
    setAnchorEl(e.target);
  };

  useEffect(() => {
    if (params?.locationId) {
      if (pvLocation) {
        const payload: ILocationForm = {
          pvCellPhoneNumber: pvLocation?.pvCellPhoneNumber || null,
          pvEmailAddress: pvLocation?.pvEmailAddress || null,
          pvFaxNumber: pvLocation?.pvFaxNumber || null,
          pvLocationType: pvLocation?.pvLocationType || null,
          pvPhoneNumber: pvLocation?.pvPhoneNumber || null,
          pvSiteID: pvLocation?.pvSiteID || null,
          pvSiteName: pvLocation?.pvSiteName || null,
          pvSitePOC: pvLocation?.pvSitePOC || null,
        };
        reset(payload);
        setResults(pvLocation || EMPTY_LOCATION);
      }
    } else {
      reset({});
      setResults(EMPTY_LOCATION);
    }
  }, [pvLocation]);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={
            <ArrowBackButton
              onClick={() => {
                setResults(EMPTY_LOCATION);
                params?.locationId
                  ? navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}`)
                  : navigate('/');
              }}
            />
          }
          titleBig={
            <span>
              {' '}
              {pvLocation?.pvSiteName || appStore?.app.appName.toUpperCase()} -{' '}
              {params?.locationId ? 'Edit' : 'Add'}{' '}
            </span>
          }
          titleSmall={
            <span>
              {pvLocation?.pvSiteID ? ` SITE ${pvLocation?.pvSiteID} ->` : ''}
              {pvLocation?.pvSitePOC ? ` POC ${pvLocation?.pvSitePOC}` : ''}
            </span>
          }
          suffix={
            <>
              <div
                className="form-margin-16"
                hidden={roleEdit}>
                <HeaderButton
                  style={{fontSize: '16px', marginRight: 15, paddingRight: 0}}
                  onClick={handleSubmit(onSaveEntry)}>
                  SAVE
                </HeaderButton>
              </div>
              <IconButton
                aria-label="menu"
                style={{marginRight: 5}}
                onClick={onClickMore}>
                <MdMoreVert className="icon" />
              </IconButton>
            </>
          }
        />
        <div className="flex form-shell container-overflow-y">
          {loading ? (
            <LoadingView message={'Loading entry, please wait...'} />
          ) : (
            <>
              {showAlert && <NotiAlert />}
              <div className="layout horizontal center section-title form-margin">
                General Information
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <div className="flex">
                  <Controller
                    name={'pvLocationType'}
                    control={control}
                    rules={{required: true}}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                      <FormControl
                        variant="standard"
                        size="small"
                        fullWidth
                        className={classes.selectInputProps}>
                        <InputLabel htmlFor="uncontrolled-native">Type of site</InputLabel>
                        <NativeSelect
                          error={Boolean(error)}
                          value={value}
                          inputProps={{
                            name: 'selectType',
                            id: 'uncontrolled-native',
                          }}
                          placeholder={'Select type'}
                          required={true}
                          className={classes.wrapper}
                          onChange={onChange}>
                          {SiteTypes.map((type, index) => (
                            <option
                              key={index}
                              value={type}>
                              {type}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    )}
                  />
                </div>
                <Controller
                  name={'pvSiteName'}
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      error={Boolean(error)}
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      style={{marginLeft: '12px'}}
                      required={true}
                      variant="standard"
                      label="Site name"
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name={'pvSiteID'}
                  control={control}
                  rules={{required: false}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      variant="standard"
                      label="Site ID"
                      placeholder="Enter site ID"
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={'pvSitePOC'}
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      error={Boolean(error)}
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      required={true}
                      variant="standard"
                      label="Site POC"
                      placeholder="Enter site POC"
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              <div className="height-10" />

              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <Controller
                  name={'pvPhoneNumber'}
                  control={control}
                  rules={{required: false}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      variant="standard"
                      label="Phone number"
                      placeholder="Enter phone number"
                      InputProps={{
                        inputComponent: NumberFormatPhone,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={'pvFaxNumber'}
                  control={control}
                  rules={{required: false}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      variant="standard"
                      label="Fax number"
                      placeholder="Enter fax number"
                      InputProps={{
                        inputComponent: NumberFormatPhone,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              <div className="height-10" />

              <div className="layout horizontal wrap">
                <Controller
                  name={'pvCellPhoneNumber'}
                  control={control}
                  rules={{required: false}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      variant="standard"
                      label="Cell phone number"
                      placeholder="Enter cell phone number"
                      InputProps={{
                        inputComponent: NumberFormatPhone,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={'pvEmailAddress'}
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      error={Boolean(error)}
                      value={value}
                      InputLabelProps={{shrink: true}}
                      className={classes.wrapper}
                      required={true}
                      variant="standard"
                      label="Email address"
                      placeholder="Enter email address"
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              <div className="height-10" />

              <div className="layout horizontal center section-title form-margin">
                Location Details
              </div>

              <div className="height-10" />

              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <AddressModule
                  getDataFunc={getDataFunc}
                  callback={addressDataCallback}
                  displaySelectPointOnMap={false}
                  displayUseCurrentLocation={true}
                  address={results?.pvAddress || ''}
                  address2={results?.pvAddress2 || ''}
                  city={results?.pvCity || ''}
                  county={results?.pvCounty || ''}
                  state={results?.pvState || ''}
                  zipCode={results?.pvZip || ''}
                  latitude={results?.pvLatitude || ''}
                  longitude={results?.pvLongitude || ''}
                />
              </div>
              <div className="height-20" />
              {results?.pvDataID ? (
                <div className="layout horizontal form-margin">
                  {width && width >= MOBILE_SIZE ? <div className="flex" /> : ''}
                  {deleting ? (
                    <DeleteButton setwidth={'true'}>Please wait...</DeleteButton>
                  ) : (
                    <DeleteButton
                      setwidth={'true'}
                      onClick={() => setShowDialog(true)}>
                      DELETE
                    </DeleteButton>
                  )}
                </div>
              ) : (
                <></>
              )}

              <YesCancelDialog
                show={showDialog}
                title="Delete Entry Confirmation"
                message="Deleting this entry cannot be undone. Are you sure you want to continue?"
                handleClose={() => setShowDialog(false)}
                handleAgree={() => {
                  onDeleteEntry();
                }}
              />
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default LocationEditView;
