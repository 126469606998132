import {handleActions} from 'redux-actions';
import {presetReducers} from 'store/utils/Redux';
import {AppState, AppEmptyState} from 'store/state/AppState';
import {appActions} from '../actions';

const reducer = handleActions<Partial<AppState>, any>(
  {
    [String(appActions.update)]: presetReducers.makeMerge(),
    [String(appActions.clear)]: presetReducers.makeReset(AppEmptyState),
  },
  AppEmptyState
);

export default reducer;
