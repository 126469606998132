import {handleActions} from 'redux-actions';
import {presetReducers} from 'store/utils/Redux';
import {ItemState} from 'store/state/ItemState';
import {itemActions} from '../actions';

const DEFAULT_STATE: ItemState = null;

const reducer = handleActions<ItemState, any>(
  {
    [String(itemActions.update)]: presetReducers.makeSetter(),
    [String(itemActions.clear)]: presetReducers.empty,
  },
  DEFAULT_STATE
);

export default reducer;
