import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      color: palette.primary?.dark,
      textDecoration: 'none',
    },
  },
});
