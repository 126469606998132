import {IFile} from 'interfaces/File';

const getFileImage = (file: IFile) => {
  let img = 'https://www.palmettoeoc.com';

  if (!file) return img + '/images/icons/mime/unknownextension.png';

  switch (file && file?.cbrnMIMEContentCodeSimpleType?.toLowerCase()) {
    case 'image/png':
      img += '/images/icons/mime/png.png';
      break;
    case 'image/jpeg':
      img += '/images/icons/mime/jpeg.png';
      break;
    case 'image/gif':
      img += '/images/icons/mime/gif.png';
      break;
    case 'application/vnd.google-earth.kmz':
    case 'application/vnd.google-earth.kml+xml':
      img += '/images/icons/mime/kml.png';
      break;
    case 'text/html':
      img += '/images/icons/mime/html.png';
      break;
    case 'application/x-mswebsite':
      img += '/images/icons/mime/web.png';
      break;
    case 'image/svg+xml':
      img += '/images/icons/mime/svg.png';
      break;
    case 'text/plain':
      img += '/images/icons/mime/txt.png';
      break;
    case 'application/pdf':
      img += '/images/icons/mime/pdf.png';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      img += '/images/icons/mime/xls.png';
      break;
    case 'application/x-zip-compressed':
      img += '/images/icons/mime/zip.png';
      break;
    default:
      img += '/images/icons/mime/unknownextension.png';
      break;
  }
  return img;
};

export default getFileImage;
