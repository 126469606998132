import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {toShortDateOnly} from 'common/utils/DateTime';

interface ReceivedProps {
  itemEntry: any;
}

const ReceivedItems: React.FC<ReceivedProps> = ({itemEntry}) => {
  return (
    <>
      <div className="layout horizontal center section-title form-margin">Items</div>
      <div className="layout horizontal wrap">
        {(itemEntry?.items || []).map((item: any) => {
          if (!item?.pvVoid) {
            return (
              <Card
                onClick={(e) => null}
                variant="outlined"
                key={item.pvDataID || item.key}
                className="cursor-pointer"
                style={{width: 288, margin: 10}}>
                <CardContent>
                  <div className="layout horizontal">
                    <div className="layout horizontal center flex">
                      <Typography variant="h5">{`Item Type: ${item.pvItemType || ''}`}</Typography>
                    </div>
                  </div>
                  <Typography
                    variant="body1"
                    className="truncate">
                    {`Quantity Received: ${item.pvQuantityReceived || '0'}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="truncate">
                    {`Quantity Distributed: ${item.pvQuantityDistributed || '0'}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="truncate">
                    {`Manufacturer: ${item.pvManufacturer || ''}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="truncate">
                    {`Product/Lot #: ${item.pvLotNumber || ''}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="truncate">
                    {`Expiration Date: ${toShortDateOnly(item.pvExpirationDate)}`}
                  </Typography>
                </CardContent>
              </Card>
            );
          }
        })}
      </div>
    </>
  );
};

export default ReceivedItems;
