/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
// noinspection JSIgnoredPromiseFromCall

import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {
  DeleteButton,
  DualTitleHeaderComponent,
  HeaderButton,
  SecondaryButton,
} from '@zawarski/palmetto-ui-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import {MdClear} from 'react-icons/md';
import {toShortDateOnly} from 'common/utils/DateTime';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import apiFetch, {
  createNewItemEntry,
  deleteBayEntry,
  deleteItemEntry,
  updateItemEntry,
} from 'services/apiFetch';
import {Nullable} from 'common/utils';
import useWindowDimensions, {
  MOBILE_SIZE,
} from 'common/utils/UseWindowDimensions/UseWindowDimensions';
import {getEntry} from 'helpers/functions';
import {MainLayout} from 'components/layouts';
import {FormDialog, Loading} from 'components/fragments';
import YesCancelDialog from 'components/fragments/YesCancelDialog';
import {CloseButton} from 'components/primitives';

import BaysEditForm from 'pages/BaysEdit/BaysEditForm';
import ItemDetailsForms from './ItemDetailsForms.view';
import {IBays, IItem} from 'common/interfaces';
import {ItemEditProps} from './ItemEdit.props';
import {SubmitHandler} from 'react-hook-form';
import useBayForm from 'helpers/forms/useBayForm';
import useItemDetailsForm from 'helpers/forms/useItemDetailsForm';
import _ from 'lodash';

export const EMPTY_BAYS_RESULTS: Partial<IBays> = {
  pvBay: '',
  pvBayInfo: '',
  pvBayQuantity: 0,
  pvQuantityDistributed: 0,
  pvDataID: null,
  pvExpirationDate: '',
  pvReceivedDate: '',
  temp: 1,
};

export const EMPTY_RESULTS: Partial<IItem> = {
  bays: [],
  pvDataID: null,
  pvDomainID: null,
  pvEntryDate: null,
  pvExpirationDate: null,
  pvFederallyDonatedBy: null,
  pvLocationID: '',
  pvGlobalID: null,
  pvGroupID: null,
  pvItemNotes: null,
  pvItemQuantity: null,
  pvItemSpecialInstructions: null,
  pvItemType: null,
  pvLotNumber: null,
  pvManufacturer: null,
  pvPOReferenceNumber: null,
  pvProjectMission: null,
  pvQuantityDistributed: 0,
  pvQuantityReceived: 0,
  pvRRNumber: null,
  pvReceivedID: null,
  pvReqDetCostEst: null,
  pvStatus: null,
  pvStorageRequirements: null,
  pvTechSpecs: null,
  pvTotalCases: null,
  pvType: 'item',
  pvUnitOfMeasure: null,
  pvVoid: 0,
};

const ItemEditView: React.FC<ItemEditProps> = (props: ItemEditProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const {width} = useWindowDimensions();
  const {control, watch, reset, setValue, handleSubmit} = useBayForm();
  const watchBayQty = watch('pvBayQuantity');
  const watchBayQuantityDistributed = Number(watch('pvQuantityDistributed')) || 0;
  const useIForm = useItemDetailsForm();
  const controlIForm = useIForm.control;
  const watchItemQtyReceived = Number(useIForm.watch('pvQuantityReceived')) || 0;
  const errorsIForm = useIForm.formState.errors;
  const setValueIForm = useIForm.setValue;
  const resetIForm = useIForm.reset;
  const handleSubmitIForm = useIForm.handleSubmit;

  const appState = useSelectorSafe((state) => state);
  const [itemEntry, setItemEntry] = useState<Partial<IItem>>(EMPTY_RESULTS);
  const [results, setResults] = useState<Partial<IItem>>(EMPTY_RESULTS);
  const [baysResult, setBaysResult] = useState<Partial<IBays>>(EMPTY_BAYS_RESULTS);
  const [isBayEdit, setIsBayEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showBayDialog, setShowBayDialog] = useState<boolean>(false);
  const [showBayErrorDialog, setShowBayErrorDialog] = useState<boolean>(false);
  const [showQDError, setShowQDError] = useState<boolean>(false);
  const [showBayNotCompleteDialog, setShowBayNotCompleteDialog] = useState<boolean>(false);
  const [remainingBayQtyBalance, setRemainingBayQtyBalance] = useState<number>(0);
  const [totalDistributed, setTotalDistributed] = useState<number>(0);
  const [quantityDistributed, setQuantityDistributed] = useState<number>(0);
  const [isItemNew, setIsItemNew] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<Nullable<any>>(null);
  const [totalBayQty, setTotalBayQty] = useState<number>(0);
  const roles = appState?.app?.appPermissions || [];
  const roleSave = !(roles.indexOf('UPDATE') > -1);

  const getResultsForQuantityDistributed = () => {
    let quantityDistributed = 0;
    let sumTotalBayQty = 0;

    if (itemEntry && _.isArray(itemEntry?.bays) && itemEntry?.bays.length) {
      sumTotalBayQty = _.reduce(itemEntry.bays, (sum, b) => sum + Number(b.pvBayQuantity || 0), 0);
      if (
        Number(itemEntry?.pvQuantityDistributed || 0) > 0 &&
        Number(itemEntry?.pvQuantityDistributed || 0) === sumTotalBayQty
      ) {
        quantityDistributed = Number(itemEntry?.pvQuantityDistributed || 0);
      } else {
        quantityDistributed = watchItemQtyReceived - sumTotalBayQty;
      }

      setTotalDistributed(Number(quantityDistributed));
      setTotalBayQty(sumTotalBayQty);
    }
  };

  useEffect(() => {
    getResultsForQuantityDistributed();
  }, [itemEntry]);

  useEffect(() => {
    if (watchBayQuantityDistributed && watchItemQtyReceived > 0) {
      const newBayQtyValue = Number(baysResult.pvBayQuantity) - watchBayQuantityDistributed;
      if (newBayQtyValue > -1) {
        setValue('pvBayQuantity', newBayQtyValue);
      }
    }
  }, [watchBayQuantityDistributed]);

  useEffect(() => {
    if (watchItemQtyReceived && isItemNew && remainingBayQtyBalance === 0 && !isBayEdit) {
      setRemainingBayQtyBalance(watchItemQtyReceived);
    }
  }, [watchItemQtyReceived]);

  useEffect(() => {
    if (_.isObject(errorsIForm) && !_.isEmpty(errorsIForm)) {
      let errMsg = '';
      for (let i = 0; i < Object.keys(errorsIForm).length; i++) {
        const objName = Object.keys(errorsIForm)[i];
        errMsg = errMsg + ', ' + errorsIForm[objName]?.message;
      }
      errMsg = errMsg.slice(2);
      setHasFormError(true);
      setHasError(true);
      setErrMsg(errMsg);
      setShowAlert(true);
    }
  }, [errorsIForm]);

  const NotifyAlert = () => {
    return (
      <Alert
        sx={{color: '#fff'}}
        onClose={() => setShowAlert(false)}
        variant="filled"
        severity={hasError ? 'error' : 'success'}>
        {hasError ? errMsg : 'Successfully Save!'}
      </Alert>
    );
  };

  const parseData = (data: Partial<IItem>) => {
    if (data) {
      return ['pvEntryDate', 'pvExpirationDate', 'pvReceivedDate'].reduce((obj, o: string) => {
        Object.assign(obj, {[o]: toShortDateOnly(obj[o])});
        return obj;
      }, data);
    }
    return EMPTY_RESULTS;
  };

  const getItems = async () => {
    await getEntry(params?.locationId || null, params?.pvDataID || null, 'item', (data, error) => {
      if (!error) {
        const parsedData: Partial<IItem> = parseData(data);
        if (parsedData.pvGroupID === null || parsedData.pvGlobalID === undefined) {
          parsedData.pvGroupID = appState?.account?.selectedGroupID;
        }

        const totalDistributedValue =
          parsedData && parsedData?.pvQuantityDistributed ? parsedData.pvQuantityDistributed : 0;
        setTotalDistributed(Number(totalDistributedValue));
        setItemEntry(parsedData);
        resetIForm(parsedData);
        setResults(parsedData);
      } else {
        setIsLoading(false);
        throw new Error(`[getItemEntry] caught an error: ${error.status} - ${error.message}`);
      }
    });
  };

  const handleClickSave: SubmitHandler<Partial<IItem>> = async (formData) => {
    // const ttlQtyReceived: number = Number(formData?.pvQuantityReceived) || 0;
    let bayTemp = formData.bays || [];
    if (bayTemp.length) {
      // Fix bays change of pvItemType
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      bayTemp = bayTemp.map((item: any) => ({...item, pvItemType: formData.pvItemType}));
      formData.bays = bayTemp;
    }

    const newResult = {
      ...results,
      ...formData,
    };
    setResults(newResult);

    if (totalBayQty === 0) {
      return setShowBayNotCompleteDialog(true);
    }

    if (watchItemQtyReceived !== totalBayQty + totalDistributed) {
      return setShowBayNotCompleteDialog(true);
    }

    if (!hasError || !hasFormError) {
      setIsLoading(true);
      const toSaveObj: Partial<IItem> = {
        ...formData,
        pvQuantityDistributed: totalDistributed,
      };
      const res = await apiFetch(
        !params?.pvDataID
          ? createNewItemEntry(toSaveObj, params?.locationId || '')
          : updateItemEntry(toSaveObj, params?.locationId || '', params?.pvDataID || '')
      );

      if (res && res?.status === 200) {
        setItemEntry(toSaveObj);
        setResults(toSaveObj);
        setErrMsg(null);
        setHasFormError(false);
        setHasError(false);
        setShowAlert(true);
        setIsLoading(false);

        if ((formData?.bays || []).length > 0) {
          await Promise.all(
            (formData?.bays || []).map((bay) => {
              // Check if Void === true then remove bay else create new bay entry
              if (bay && bay?.pvVoid) {
                return apiFetch(deleteBayEntry(params?.locationId || '', bay.pvDataID));
              }
            })
          );

          setTimeout(
            () =>
              navigate(
                `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items${
                  params?.pvDataID ? `/${params?.pvDataID}` : ''
                }`
              ),
            1000
          );
        } else {
          setTimeout(
            () =>
              navigate(
                `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items${
                  params?.pvDataID ? `/${params?.pvDataID}` : ''
                }`
              ),
            1000
          );
        }
      } else {
        setErrMsg('Save Failed! Problem Occurred While Saving.');
        setHasError(true);
        setShowAlert(true);
        setIsLoading(false);
      }
    }
  };

  const handleDeleteItemEntry = async () => {
    if (params?.locationId && params?.pvDataID) {
      setIsLoading(true);

      const res = await apiFetch(deleteItemEntry(params?.locationId, params?.pvDataID));

      if (res && res.status === 200) {
        setIsLoading(false);
        setHasError(false);
        setShowAlert(false);
        setShowDialog(false);

        // Redirect To ListView Page
        setTimeout(
          () => navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items`),
          1000
        );
      } else {
        setHasError(true);
      }
    }
  };

  const handleSaveBay: SubmitHandler<Partial<IBays>> = (data) => {
    let bayValue = EMPTY_BAYS_RESULTS;
    bayValue = {
      ...bayValue,
      ...data,
    };

    bayValue = {
      ...bayValue,
      pvExpirationDate: bayValue?.pvExpirationDate
        ? toShortDateOnly(bayValue?.pvExpirationDate)
        : '',
      pvReceivedDate: bayValue?.pvReceivedDate ? toShortDateOnly(bayValue?.pvReceivedDate) : '',
      temp: 1,
      pvDataID: isBayEdit ? bayValue.pvDataID : Date.now(),
    };

    const enteredBayDistributedQty = bayValue?.pvQuantityDistributed || 0;
    let newTtlRemainDistributionBalance = 0;
    let newTtlDistributedQty = 0;
    let newTtlBayQty = 0;

    if (isBayEdit) {
      const b = results?.bays || [];

      const idx: number | undefined = results.bays?.findIndex((b) => {
        return (
          b?.pvBay.toLowerCase() === bayValue?.pvBay?.toLowerCase() ||
          b?.pvDataID === bayValue?.pvDataID
        );
      });

      if (idx === -1 || isBayEdit) {
        results.bays = b?.map((b) => {
          return b?.pvBay.toLowerCase() === bayValue?.pvBay?.toLowerCase() ||
            b?.pvDataID === bayValue?.pvDataID
            ? bayValue
            : b;
        });

        results.bays?.map((b) => {
          newTtlBayQty += Number(b.pvBayQuantity);
        });

        // newTtlDistributedQty = watchItemQtyReceived - newTtlBayQty;
        newTtlDistributedQty = totalDistributed + enteredBayDistributedQty;
        newTtlRemainDistributionBalance =
          watchItemQtyReceived - newTtlBayQty - newTtlDistributedQty;

        if (isBayEdit) {
          if (newTtlRemainDistributionBalance < 0) {
            setShowBayDialog(false);
            return setShowBayErrorDialog(true);
          }
        } else {
          if (newTtlRemainDistributionBalance < 0) {
            setShowBayDialog(false);
            return setShowBayErrorDialog(true);
          }
          results.bays = [...(results.bays || []), bayValue];
        }

        setValue('pvQuantityDistributed', newTtlDistributedQty);
        setRemainingBayQtyBalance(newTtlRemainDistributionBalance);
        setTotalDistributed(newTtlDistributedQty);
        setTotalBayQty(newTtlBayQty);

        const cloneRes = {
          ...results,
          pvQuantityDistributed: newTtlDistributedQty,
        };

        setQuantityDistributed(0); // Reset Quantity Distributed
        setResults(cloneRes);
        setIsBayEdit(false);
        setValueIForm('bays', cloneRes.bays);
        setValueIForm('pvQuantityDistributed', newTtlDistributedQty);
        reset(EMPTY_BAYS_RESULTS);
      }

      setShowBayDialog(false);
    } else {
      results.bays = [...(results.bays || []), bayValue];
      results.bays?.map((b) => {
        newTtlBayQty += Number(b.pvBayQuantity);
      });

      newTtlRemainDistributionBalance = watchItemQtyReceived - newTtlBayQty - totalDistributed;
      setRemainingBayQtyBalance(newTtlRemainDistributionBalance);
      setTotalBayQty(newTtlBayQty);
      if (newTtlRemainDistributionBalance < 0) {
        setShowBayDialog(false);
        return setShowBayErrorDialog(true);
      }

      const cloneRes = {
        ...results,
        pvQuantityDistributed: totalDistributed,
      };
      setResults(cloneRes);
      setValueIForm('bays', cloneRes.bays);
      setValueIForm('pvQuantityDistributed', totalDistributed);
      reset(EMPTY_BAYS_RESULTS);

      setShowBayDialog(false);

      // setShowBayErrorDialog(true);
    }
  };

  const handleRemoveBay = (e: any, bay: Partial<IBays>) => {
    const currentBayQty: number = Number(bay?.pvBayQuantity || 0);
    if (currentBayQty > 0) {
      setErrMsg(`Cannot delete / remove Bay No: ${bay.pvBay} as Quantity is not zero(0)`);
      setHasError(true);
      setShowAlert(true);
      return e.stopPropagation();
    }

    let tempRes = {...results};
    const bays = tempRes?.bays || [];
    const idx = bays.findIndex((b) => b?.pvDataID === bay?.pvDataID);
    (tempRes?.bays || [])[idx] = {...bay, pvVoid: 1};
    // if (bay?.temp && idx > -1) {
    //   // Removed to array
    //   tempRes?.bays?.splice(idx, 1);
    // } else {
    //   // Update Bays on selected Index
    //   (tempRes?.bays || [])[idx] = {...bay, pvVoid: 1};
    // }
    let newTotalDistributed =
      tempRes &&
      tempRes?.bays?.reduce((total, o) => {
        if (o?.pvVoid === 0) {
          total = total + Number(o?.pvBayQuantity) || 0;
        }
        return total;
      }, 0);

    newTotalDistributed = (Number(tempRes?.pvQuantityReceived) || 0) - newTotalDistributed;
    tempRes = {...tempRes, pvQuantityDistributed: newTotalDistributed};

    setResults(tempRes);
    setValueIForm('bays', tempRes.bays);
    e.stopPropagation();
  };

  useEffect(() => {
    setIsLoading(true);
    if (params?.pvDataID && params?.pvDataID) {
      getItems().then(() => {
        setIsItemNew(false);
        setIsLoading(false);
      });
    } else {
      reset(EMPTY_BAYS_RESULTS);
      resetIForm({
        ...EMPTY_RESULTS,
        pvGlobalID: uuidv4(),
        pvLocationID: params?.locationId,
        pvItemType: '',
        pvManufacturer: '',
        pvLotNumber: '',
        pvExpirationDate: toShortDateOnly(new Date(), 'YYYY-MM-DD'),
        pvQuantityReceived: 0,
        pvUnitOfMeasure: 'Each',
        pvReqDetCostEst: '0',
        pvStorageRequirements: '',
        pvItemNotes: '',
        pvGroupID: appState?.account?.selectedGroupID,
      });
      setIsItemNew(true);
      setIsLoading(false);
    }
  }, []);

  const handleClickClose = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items${
        params?.pvDataID ? `/${params?.pvDataID}` : ''
      }`
    );
  };

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={<CloseButton onClick={() => handleClickClose()} />}
          titleBig={
            <span>
              {props?.pvLocation?.pvSiteName} - {params?.pvDataID ? 'Edit' : 'Add'}
            </span>
          }
          titleSmall={
            <span>
              {'Item' +
                (itemEntry?.pvPurchaseOrderRefNumber
                  ? ' PO/Ref# ' + itemEntry?.pvPurchaseOrderRefNumber
                  : itemEntry?.pvBenefactor
                  ? ' Benefactor ' + itemEntry?.pvBenefactor
                  : '') +
                (itemEntry?.pvItemType ? ' - ' + itemEntry?.pvItemType : '')}
            </span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleSave}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={handleSubmitIForm(handleClickSave)}>
                  SAVE
                </HeaderButton>
              </div>
            </div>
          }
        />
        <div className="flex form-shell container-overflow-y">
          {isLoading ? (
            <Loading message="Please Wait . . ." />
          ) : (
            <>
              {showAlert && <NotifyAlert />}
              <ItemDetailsForms
                control={controlIForm}
                setValue={setValueIForm}
                readOnly={false}
                totalDistributed={totalDistributed}
                pvDataID={params?.pvDataID || ''}
              />
              <div className="layout horizontal center section-title form-margin">
                Bays Assigned
              </div>
              <div className="layout horizontal wrap">
                {results?.bays &&
                  results?.bays?.map((bay: Partial<IBays>) => {
                    if (!bay.pvVoid) {
                      return (
                        <Card
                          variant="outlined"
                          onClick={(_) => {
                            const tempBay = {...bay, pvQuantityDistributed: 0};
                            reset(tempBay);
                            setBaysResult(bay);
                            // setBayQuantity(Number(bay?.pvBayQuantity) || 0);
                            setIsBayEdit(true);
                            setShowBayDialog(true);
                          }}
                          key={bay.pvDataID || bay.key}
                          className="cursor-pointer"
                          style={{width: 288, margin: 10}}>
                          <CardContent>
                            <div className="layout horizontal">
                              <div className="layout horizontal center flex">
                                <Typography variant="h5">Bay: {bay.pvBay}</Typography>
                              </div>
                              <IconButton onClick={(e) => handleRemoveBay(e, bay)}>
                                <MdClear />
                              </IconButton>
                            </div>
                            <Typography
                              variant="body1"
                              className="truncate">
                              Quantity: {bay?.pvBayQuantity ? bay.pvBayQuantity : 0}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    }
                  })}
              </div>
              <div className="height-20" />
              <div
                className="layout horizontal form-margin"
                style={{marginBottom: '10px'}}>
                {width && width >= MOBILE_SIZE ? <div className="flex" /> : ''}
                {remainingBayQtyBalance > 0 && (
                  <SecondaryButton
                    setwidth={width && width >= MOBILE_SIZE ? 'true' : 'false'}
                    onClick={() => {
                      reset(EMPTY_BAYS_RESULTS);
                      // setBayQuantity(0);
                      setIsBayEdit(false);
                      setShowBayDialog(true);
                      setHasError(false);
                      setErrMsg(null);
                      setShowAlert(false);
                    }}>
                    ASSIGN BAY
                  </SecondaryButton>
                )}
              </div>
              {results.pvDataID ? (
                <div className="layout horizontal form-margin">
                  {width && width >= MOBILE_SIZE ? <div className="flex" /> : ''}
                  <DeleteButton
                    setwidth={width && width >= MOBILE_SIZE ? 'true' : 'false'}
                    sx={{marginTop: '10px'}}
                    onClick={() => setShowDialog(true)}>
                    DELETE
                  </DeleteButton>
                </div>
              ) : (
                <></>
              )}
              <FormDialog
                show={showBayDialog}
                title="Assign Bay"
                handleClose={() => {
                  reset(EMPTY_BAYS_RESULTS);
                  setBaysResult(EMPTY_BAYS_RESULTS);
                  setQuantityDistributed(0);
                  setIsBayEdit(false);
                  // setBayQuantity(0);
                  setShowBayDialog(false);
                  getResultsForQuantityDistributed();
                }}
                handleSave={handleSubmit(handleSaveBay)}>
                <BaysEditForm
                  control={control}
                  setValue={setValue}
                  fromBay={false}
                  results={baysResult}
                  quantityDistributed={quantityDistributed}
                  setQuantityDistributed={setQuantityDistributed}
                  isItemNew={isItemNew}
                />
              </FormDialog>
              <YesCancelDialog
                show={showDialog}
                title="Delete Entry Confirmation"
                message="Deleting this entry cannot be undone. Are you sure you want to continue?"
                handleClose={() => setShowDialog(false)}
                handleAgree={() => {
                  handleDeleteItemEntry();
                }}
              />
              <YesCancelDialog
                show={showBayErrorDialog}
                title="Assign Bay Error!"
                message={`You entered ${watchBayQty} now you have a total of (${remainingBayQtyBalance}) bay quantity
                  which exceed the total quantity received of ${watchItemQtyReceived}.`}
                haveCancelButton={false}
                handleAgree={() => {
                  setShowBayErrorDialog(false);
                  setShowBayDialog(true);
                }}
              />
              <YesCancelDialog
                show={showQDError}
                title="Bay Error!"
                message="Quantity Distributed cannot be more than Bays Quantity!"
                haveCancelButton={false}
                handleAgree={() => {
                  setShowQDError(false);
                  setQuantityDistributed(0);
                  setShowBayDialog(true);
                }}
              />
              <YesCancelDialog
                show={showBayNotCompleteDialog}
                title="Bay Quantity Not Complete!"
                message={`Total Bay Quantity of ${
                  totalBayQty + totalDistributed
                } is less than the Quantity Received ${results?.pvQuantityReceived}.`}
                haveCancelButton={false}
                handleAgree={() => {
                  setShowBayNotCompleteDialog(false);
                }}
              />
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ItemEditView;
