import React, {useState, useEffect, useRef} from 'react';
import {NormalTextField} from 'components/zawarski';
// import { v4 as uuidv4 } from 'uuid';

/**
 * This function will destructure address into the following components
 * @param {Object} results The object returned by google maps api
 * @returns {Object}
 * {
        address1: streetNumber + ' ' + streetName,
        address2: '',
        city: city,
        zipCode: zipCode,
        fullAddress: results[0].formatted_address,
        state: state,
        county: county,
        lat: latitude,
        lon: longitude,
 * }
 */

export async function destructureAddressIntoComponents(
  resultsWithoutLatLng: any,
  callback: (e: any) => void
) {
  const results: any = await geocodeByAddress(resultsWithoutLatLng[0].formatted_address);
  let streetNumber;
  let streetName;
  let address2 = '';
  let city = '';
  let county = '';
  let state = '';
  let zipCode = '';

  for (let i = 0; i < results[0].address_components.length; i++) {
    if (results[0].address_components[i].types[0] === 'street_number') {
      streetNumber = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'route') {
      streetName = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'neighborhood') {
      address2 = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'locality') {
      city = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'administrative_area_level_1') {
      state = results[0].address_components[i].long_name;
    } else if (results[0].address_components[i].types[0] === 'administrative_area_level_2') {
      county = results[0].address_components[i].long_name;
      county =
        county.indexOf(' County') !== -1 ? county.substring(0, county.indexOf(' County')) : county;
    } else if (results[0].address_components[i].types[0] === 'postal_code') {
      zipCode = results[0].address_components[i].long_name;
    }
  }

  const a = {
    address1: [streetNumber, streetName].filter(Boolean).join(' '),
    address2: address2,
    city: city,
    zipCode: zipCode,
    state: state,
    county: county,
    fullAddress: results[0].formatted_address,
    latitude: (results[0].geometry && results[0].geometry.location.lat().toFixed(8)) || undefined,
    longitude: (results[0].geometry && results[0].geometry.location.lng().toFixed(8)) || undefined,
  };
  callback && callback(a);
}

export const geocodeByAddress = (address: any) => {
  if (!window.google) return;
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({address}, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

export const geoCodeFromLatLonInput = (lat: any, lon: any, callback: any) => {
  if (!window.google) return;
  const latlng = {lat: lat, lng: lon};
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({location: latlng}, (results, status) => {
    if (status === 'OK') {
      destructureAddressIntoComponents(results, (addressObj: any) => {
        callback(addressObj);
      });
    }
  });
};

export const SearchLocationInput = (props: any) => {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  let autoComplete: any;
  let placeChangedListener: any;

  // To prevent the error below we destructured props object like this
  // Warning: Invalid value for prop`callback` on <div> tag.Either remove it from the element, or pass a string or number value to keep it in the DOM.
  const {callback, ...others} = props;

  const handleChange = (event: any) => {
    setQuery(event.target.value);
    callback &&
      callback({
        address: event.target.value,
      });
  };

  const handleAddressClick = (addresses: any) => {
    destructureAddressIntoComponents([addresses], (formattedAddress) => {
      setQuery(formattedAddress.address1);
      props.callback && props.callback(formattedAddress);
    });
  };

  const loadScript = (url: string, callback: () => void) => {
    if (document.querySelector('#pcl-sci-gm-api-v3')) {
      callback();
      return;
    }
    const script: any = document.createElement('script');
    script.id = 'pcl-sci-gm-api-v3';
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
    if (!window.google || !autoCompleteRef) return;
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions: {country: 'us'},
    });
    autoComplete.setFields(['address_components', 'formatted_address']);
    placeChangedListener = autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery: any) {
    const addressObject = autoComplete.getPlace();
    // This returns address is in a comma seperated format
    // const query = addressObject.formatted_address;
    updateQuery(addressObject);
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${
        'AIzaSyDpfXqpJ4ieDYmvrZcUBG_arjixqjTJ06s' || process.env.REACT_APP_GOOGLE_API_KEY
      }&libraries=drawing,geometry,places,visualization`,
      () => handleScriptLoad(handleAddressClick, autoCompleteRef)
    );

    return () => {
      // Anything in here is fired on component unmount.
      placeChangedListener && placeChangedListener.remove();
    };
  }, []);

  useEffect(() => {
    setQuery(props.updatedvalue);
  }, [props.updatedvalue]);

  return (
    <NormalTextField
      inputRef={autoCompleteRef}
      value={query}
      onChange={handleChange}
      {...others}
      placeholder={props.InputProps && props.InputProps.readOnly ? ' ' : props.placeholder}
    />
  );
};

export default SearchLocationInput;
