import {Theme} from '@mui/material';

const ButtonStyles = (theme: Theme): any => ({
  root: {},
  containedSecondary: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
});

export {ButtonStyles};
