import React from 'react';
import {NormalTextField as Field} from 'components/zawarski';
import {toShortDateOnly} from 'common/utils/DateTime';

interface ReceivedProps {
  itemEntry: any;
}

const ReceivedForm: React.FC<ReceivedProps> = ({itemEntry}) => {
  return (
    <>
      <div className="layout horizontal center section-title form-margin">Received Details</div>
      <div className="layout horizontal wrap">
        <Field
          label="Received"
          field="itemReceivedDate"
          value={toShortDateOnly(itemEntry?.pvReceivedDate || '')}
        />
        <Field
          label="Submitted By"
          field="itemSubmittedBy"
          value={itemEntry?.pvSubmittedBy || ''}
        />
      </div>
      <div className="layout horizontal wrap">
        <Field
          label="Purchase order / ref #"
          field="itemPO"
          value={itemEntry?.pvPurchaseOrderRefNumber || ''}
          InputProps={{readOnly: false}}
        />
      </div>
      <div className="layout horizontal wrap">
        <Field
          multiline
          label="Description"
          field="itemDescription"
          value={itemEntry?.pvDescription || ''}
        />
      </div>
    </>
  );
};

export default ReceivedForm;
