import React, {useEffect, useState} from 'react';

import {merge} from 'common/utils/ActivityFeed';
import {Nullable} from 'common/utils';
import apiFetch, {getComments, getHistory, getLocationHistory, putComment} from 'services/apiFetch';
import {ActivityFeedEntry, ActivityFeedProps, ActivityFeedPublicProps} from './ActivityFeed.props';
import ActivityFeedView from './ActivityFeed.view';

const ActivityFeedContainer: React.FC<ActivityFeedPublicProps> = (
  ownProps: ActivityFeedPublicProps
) => {
  const {tab, pvDataID, pvServiceID, locationID, pvServiceName} = ownProps;
  const [comments, setComments] = useState<Nullable<string>>(null);
  const [entries, setEntries] = useState<Partial<ActivityFeedEntry>[]>([]);

  const getData = async () => {
    if (pvDataID && pvServiceID) {
      const {commentsFieldReference, historyFieldReference} = ownProps;
      const promise = Promise.all([
        tab === ''
          ? apiFetch(getLocationHistory(pvServiceName, pvDataID))
          : apiFetch(getHistory(pvServiceName, locationID || 0, pvDataID, tab)),
        apiFetch(getComments(pvServiceID, pvDataID)),
      ]).then((res) => ({
        history: res[0]?.data || [],
        comments: res[1]?.data || [],
      }));
      const res = await promise;
      const mergeResult: Partial<ActivityFeedEntry>[] = merge(
        res.history,
        res.comments,
        historyFieldReference,
        commentsFieldReference
      );

      setEntries(mergeResult);
    }
  };

  const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value || '');
  };

  const handleSaveComment = async () => {
    if (comments && comments?.length > 3) {
      setComments(null);
      apiFetch(
        putComment({
          pvComment: comments,
          pvUserID: ownProps.pvUserID,
          pvServiceID: ownProps.pvServiceID,
          pvEntryDataID: ownProps.pvDataID,
          pvSubEntryID: ownProps.pvSubEntryID,
        })
      ).then(() => getData());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const combinedProps: ActivityFeedProps = {
    ...ownProps,
    comments,
    entries,
    handleChangeComment,
    handleSaveComment,
  };

  return <ActivityFeedView {...combinedProps} />;
};

export default ActivityFeedContainer;
