import DefaultPaletteOptions from 'theming/defaultPalette';

const palette: DefaultPaletteOptions = {
  primary: {
    light: '#5D88FD',
    main: '#2961FD',
    dark: '#01195B',
  },
  secondary: {
    light: '#0066ff',
    main: '#0044ff',
    // dark: will be calculated from palette.secondary.main,
    contrastText: '#ffcc00',
  },
  text: {
    primary: '#01195B',
    secondary: '#767676',
  },
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
};

export default palette;
