import React from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {MdAdd} from 'react-icons/md';
import {makeStyles} from '@mui/styles';
import {PaperFabButton} from '@zawarski/palmetto-ui-components';
import {joinPathFormat} from 'common/utils/String';
import {itemActions} from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {TableList} from 'components/fragments';
import {ItemsTableColumns} from 'helpers/tables';

const useStyles = makeStyles({
  fab: {
    bottom: '80px !important',
  },
});

const ItemsListView: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const appState = useSelectorSafe((state) => state);
  const roles = appState?.app?.appPermissions || [];
  const roleSave = !(roles.indexOf('UPDATE') > -1);

  const handleRowSelect = (item: any) => {
    dispatch(itemActions.update(item));
    navigate(joinPathFormat([location.pathname, item.pvDataID]));
  };

  return (
    <>
      <TableList
        auth={{
          accessToken: appState?.token?.id,
          userId: appState?.token?.userId,
          rooms: ['warehouse_item'],
        }}
        columns={ItemsTableColumns}
        location={appState?.location?.data}
        reqEndpoint="item"
        subtitlePrefix={'Items'}
        onRowSelect={(r) => handleRowSelect(r.row)}
      />
      <PaperFabButton
        hidden={roleSave}
        onClick={() => {
          navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/items/new`);
        }}
        className={classes.fab}>
        <MdAdd className="icon" />
      </PaperFabButton>
    </>
  );
};

export default ItemsListView;
