/* eslint-disable react/display-name */
import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatPhone = React.forwardRef((props: any, ref: any) => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              value: `${values.value}` || '',
            },
          });
        }
      }}
      format="###-###-####"
    />
  );
});

export default NumberFormatPhone;
