/* eslint-disable operator-linebreak */
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import {Card, CardContent, Typography, Alert, IconButton} from '@mui/material';
import {FormControlLabel, Radio, RadioGroup, TextField, Modal} from '@mui/material';
import {MdClear} from 'react-icons/md';
import useWindowDimensions, {
  MOBILE_SIZE,
} from 'common/utils/UseWindowDimensions/UseWindowDimensions';
import {
  HeaderButton,
  DualTitleHeaderComponent,
  CardComponentDownloadCard,
  SecondaryButton,
  DeleteButton,
} from '@zawarski/palmetto-ui-components';
import apiFetch, {
  createNewReceivedEntry,
  uploadFile,
  deleteFile,
  updateReceivedEntry,
  deleteItemEntry,
  createNewItemEntry,
  deleteReceiveEntry,
} from 'services/apiFetch';
import {getEntry, getFiles, getFileImage} from 'helpers/functions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {toShortDateOnly, toISOFormat} from 'common/utils/DateTime';
import {Nullable} from 'common/utils';
import {Loading, FormDialog} from 'components/fragments';
import {MainLayout} from 'components/layouts';
import {CloseButton, UploadFile} from 'components/primitives';
import YesCancelDialog from 'components/fragments/YesCancelDialog';
import useStyles from './ReceivedEdit.styles';
import axios, {AxiosRequestHeaders} from 'axios';
import ReceivedItemDetailsForm from './ReceivedItemDetailsForm.view';
import {ReceivedProps} from './ReceivedEdit.props';
import {IReceivedItem, IFile, IItem} from 'common/interfaces';
import {SubmitHandler} from 'react-hook-form';
import useReceivedItemForm, {defaultValueReceivedItem} from 'helpers/forms/useReceivedItemForm';

const EMPTY_ITEMS: Partial<IItem> = {
  bays: [],
  pvDataID: null,
  pvExpirationDate: '',
  pvItemNotes: null,
  pvItemType: null,
  pvLocationID: '',
  pvLotNumber: null,
  pvManufacturer: null,
  pvQuantityReceived: 0,
  pvReceivedDate: null,
  pvReqDetCostEst: null,
  pvStatus: null,
  pvStorageRequirements: null,
  pvUnitOfMeasure: null,
  temp: 1,
};

const EMPTY_RESULTS: ReceivedProps = {
  items: [],
  pvBenefactor: '',
  pvDescription: '',
  pvExpirationDate: '',
  pvFileCount: 0,
  pvGlobalID: '',
  pvGroupID: 0,
  pvItemsDonatedBoughtRadio: '0',
  pvLocationID: '',
  pvReceivedDate: toShortDateOnly(new Date()),
  pvSubmittedBy: '',
  pvUserID: 0,
  pvPurchaseOrderRefNumber: '',
};

const ReceivedEdit: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const {width} = useWindowDimensions();
  const appState = useSelectorSafe((state) => state);
  const pvLocation = appState?.location?.data;
  const roles = appState?.app?.appPermissions || [];
  const roleSave = !(roles.indexOf('UPDATE') > -1);
  const [itemEntry, setItemEntry] = useState<ReceivedProps>(EMPTY_RESULTS);
  const [results, setResults] = useState<ReceivedProps>(EMPTY_RESULTS);
  const [sFiles, setSFiles] = useState<IFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [po, setPO] = useState<number>(0);
  const [showItemDialog, setShowItemDialog] = useState<boolean>(false);
  const {control, setValue, reset, handleSubmit} = useReceivedItemForm();

  if (!params?.pvDataID) {
    if (!_.isEmpty(results)) {
      results.pvGlobalID = uuidv4();
      results.pvLocationID = params.locationId || '';
    }
    // Object.assign(results, {pvGlobalID: uuidv4(), pvLocationID: params?.locationId});
  }

  const NotiAlert = () => {
    return (
      <Alert
        sx={{color: '#fff'}}
        onClose={() => setShowAlert(false)}
        variant="filled"
        severity={hasError ? 'error' : 'success'}>
        {hasError ? 'Save Failed! Problem Occurred While Saving.' : 'Successfully Save!'}
      </Alert>
    );
  };

  const getItems = async () => {
    getEntry(params?.locationId || null, params?.pvDataID || null, 'received', (data, error) => {
      if (!error) {
        setItemEntry(data);
        setResults(data);
        const poNum =
          data?.pvPurchaseOrderRefNumber && parseInt(data?.pvPurchaseOrderRefNumber) > 0 ? 1 : 0;
        setPO(poNum);

        // Get All Uploaded Files
        getFiles(data.pvGlobalID, (data, error) => {
          if (!error) {
            setSFiles(data);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            results!.pvFileCount = data.length || 0;
          } else {
            throw new Error(
              `[getReceivedEntry]: caught an error: ${error.status} - ${error.message}`
            );
          }

          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        throw new Error(`[getReceivedEntry]: caught an error: ${error.status} - ${error.message}`);
      }
    });
  };

  const onResultsChange = (field: string, value: unknown) => {
    const temp: ReceivedProps = {...results};
    temp[field] = value;
    setResults(temp);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPO(Number(event.target.value));
  };

  const addFileToUploadQueue = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      const headers: AxiosRequestHeaders = {
        'Content-Type': 'multipart/form-data',
        uuid: results?.pvGlobalID || 0,
        userid: appState?.token?.userId || 0,
        username: pvLocation?.pvUserName || '',
      };

      formData.append('file', files[i]);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_PALMETTO_ENDPOINT}/files/uploadWithoutID`,
        data: formData,
        headers,
        params: {
          access_token: appState?.token?.id,
          filter: {where: {pvGlobalGroupID: results?.pvGlobalID}},
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            const ftUpload = [...filesToUpload];
            ftUpload.push(resp.data);
            setFilesToUpload(ftUpload);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            results!.pvFileCount = filesToUpload.length + 1;
          }
        })
        .catch((resp) => {
          throw new Error('AddFileToUpload caught an error: ', resp);
        });
    }
  };

  const handleClickSave = async () => {
    if (results?.pvSubmittedBy?.length === 0) {
      setHasFormError(true);
      return;
    }

    setIsLoading(true);
    const toSaveObj: ReceivedProps = {
      ...results,
      pvReceivedDate: toShortDateOnly(results?.pvReceivedDate || new Date()) || null,
      ...{
        pvUserID: appState?.token?.userId || null,
        pvGroupID: appState?.account?.selectedGroupID || null,
      },
    };

    // Upload File
    if (filesToUpload.length > 0) {
      for (let i = 0; i < filesToUpload.length; i++) {
        const filePayload = {
          pvDataID: filesToUpload[i].pvDataID,
          pvGlobalGroupID: results.pvGlobalID,
        };

        const fileRes = await apiFetch(uploadFile(filePayload));

        if (!fileRes || fileRes.status !== 200) {
          setHasError(true);
          setShowAlert(true);
          setIsLoading(false);
        }
      }
    }

    // Delete File
    if (filesToDelete.length > 0) {
      for (let i = 0; i < filesToDelete.length; i++) {
        const file = filesToDelete[i];

        if (file) {
          const fileRes = await apiFetch(deleteFile(file?.pvDataID || ''));

          if (!fileRes || fileRes?.status !== 200) {
            setHasError(true);
            setShowAlert(true);
            setIsLoading(false);
          }
        }
      }
    }

    const res = await apiFetch(
      !params?.pvDataID
        ? createNewReceivedEntry(toSaveObj, params?.locationId || '')
        : updateReceivedEntry(toSaveObj, params?.locationId || '', params?.pvDataID || '')
    );

    if (res && res?.status === 200) {
      setItemEntry(toSaveObj);
      setResults(toSaveObj);
      setHasFormError(false);
      setHasError(false);
      setShowAlert(true);
      setIsLoading(false);

      if (results?.items.length > 0) {
        const newBaysResult = await Promise.all(
          results?.items.map((item) => {
            // Check if Void === true then remove bay else create new bay entry
            if (item && item?.pvVoid) {
              return apiFetch(deleteItemEntry(params?.locationId || '', item.pvDataID));
            } else {
              if (item && item?.temp) {
                // Delete temporary properties
                delete item.temp;
                delete item.pvDataID;

                const itemsToSaveObj = {
                  ...item,
                  pvUserID: appState?.token?.userId,
                  pvGroupID: appState?.account?.selectedGroupID,
                  pvLocationID: params?.locationId,
                  pvReceivedID: params?.pvDataID || res?.data?.pvDataID,
                };

                return apiFetch(createNewItemEntry(itemsToSaveObj, params?.locationId || ''));
              }
            }
          })
        );
        setResults(EMPTY_RESULTS);
        setItemEntry(EMPTY_RESULTS);
        results.items = [];
        itemEntry.items = [];
        setTimeout(
          () =>
            navigate(
              `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/received${
                params?.pvDataID ? `/${params?.pvDataID}` : ''
              }`
            ),
          1000
        );
      } else {
        setResults(EMPTY_RESULTS);
        setItemEntry(EMPTY_RESULTS);
        results.items = [];
        itemEntry.items = [];
        setTimeout(
          () =>
            navigate(
              `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/received${
                params?.pvDataID ? `/${params?.pvDataID}` : ''
              }`
            ),
          1000
        );
      }
    } else {
      setHasError(true);
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  const handleClickClose = () => {
    navigate(
      `${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/received${
        params?.pvDataID ? `/${params?.pvDataID}` : ''
      }`
    );
  };

  const handleDeleteFile = (file: IFile) => {
    const idx = sFiles.findIndex((f: IFile) => f.pvDataID === file.pvDataID);
    const newSFiles = [...sFiles];

    if (idx > -1) newSFiles.splice(idx, 1);

    const ftDelete = [...filesToDelete];
    ftDelete.push(file);
    setFilesToDelete(ftDelete);
    setSFiles(newSFiles);
  };

  const handleRemoveItem = (item: IItem) => {
    const tempRes = {...results};
    const items = tempRes?.items || [];
    const idx = items.findIndex((i: IReceivedItem) => i?.pvDataID === item?.pvDataID);

    if (item?.temp && idx > -1) {
      tempRes?.items.splice(idx, 1);
      setResults(tempRes);
    } else {
      const newBayObj = {...item, pvVoid: 1};
      // Update Item on selected Index
      tempRes.items[idx] = newBayObj;
      setResults(tempRes);
    }
  };

  const parseFileName = (fileName: Nullable<string> = null) => {
    if (fileName) {
      const hasValue = fileName.includes('_DUMMYSEARCHSTRING_');

      if (hasValue) {
        const wArr = fileName.split('_');
        return wArr[2];
      }
    }

    return 'No_File_Name.png';
  };

  const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  const handleDeleteItemEntry = async () => {
    if (params?.locationId && params?.pvDataID) {
      setIsLoading(true);
      setShowDialog(false);
      const res = await apiFetch(deleteReceiveEntry(params?.locationId, params?.pvDataID));

      if (res && res.status === 200) {
        // Delete all received items
        await Promise.all(
          results.items.map((item) => {
            return apiFetch(deleteItemEntry(params?.locationId || '', item.pvDataID));
          })
        );
        setIsLoading(false);
        setHasError(false);
        setShowAlert(false);
        // Redirect To ListView Page
        setTimeout(
          () => navigate(`${process.env.REACT_APP_APP_URL_PATH}/${params?.locationId}/received`),
          500
        );
      } else {
        setHasError(true);
      }
    }
  };

  const handleFormSave: SubmitHandler<Partial<IItem>> = (data) => {
    const item = {
      ...EMPTY_ITEMS,
      ...data,
      pvQuantityDistributed: 0,
      pvExpirationDate: data.pvExpirationDate ? toShortDateOnly(data.pvExpirationDate) : '',
      pvReceivedDate: data.pvReceivedDate ? toShortDateOnly(data.pvReceivedDate) : '',
      pvLocationID: params?.locationId,
      temp: 1,
      pvDataID: Date.now(),
    };

    results.items.push(item);
    setShowItemDialog(false);
    reset(defaultValueReceivedItem);
  };

  useEffect(() => {
    setIsLoading(true);
    reset(defaultValueReceivedItem);
    results.items = [];
    itemEntry.items = [];
    if (params?.pvDataID && params?.pvDataID) {
      getItems();
    } else setIsLoading(false);
  }, []);

  return (
    <MainLayout>
      <div className="layout vertical full-height">
        <DualTitleHeaderComponent
          icon={<CloseButton onClick={() => handleClickClose()} />}
          titleBig={
            <span>
              {pvLocation?.pvSiteName} - {params?.pvDataID ? 'Edit' : 'Add'}
            </span>
          }
          titleSmall={
            <span>{`Received - PO/Ref# ${itemEntry?.pvPurchaseOrderRefNumber || '--'}`}</span>
          }
          suffix={
            <div className="form-margin-16">
              <div
                className="form-margin-16"
                hidden={roleSave}>
                <HeaderButton
                  style={{fontSize: '16px'}}
                  onClick={() => handleClickSave()}>
                  SAVE
                </HeaderButton>
              </div>
            </div>
          }
        />
        <div className="flex form-shell container-overflow-y">
          {isLoading ? (
            <Loading message="Please Wait . . ." />
          ) : (
            <>
              {showAlert && <NotiAlert />}
              <div className="layout horizontal center section-title form-margin">
                Received Details
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <TextField
                  InputLabelProps={{shrink: true}}
                  type="date"
                  className={classes.wrapper}
                  required={true}
                  label="Received Date"
                  variant="standard"
                  value={toShortDateOnly(results?.pvReceivedDate || new Date()) || ''}
                  onChange={(e) => onResultsChange('pvReceivedDate', e.target.value)}
                />
                <TextField
                  error={hasFormError}
                  InputLabelProps={{shrink: true}}
                  className={classes.wrapper}
                  required={true}
                  label="Entry submitted by"
                  variant="standard"
                  placeholder="Enter Name"
                  value={results?.pvSubmittedBy || ''}
                  onChange={(e) => onResultsChange('pvSubmittedBy', e.target.value)}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={po}
                  onChange={handleRadioChange}>
                  <FormControlLabel
                    style={{marginLeft: '0px'}}
                    value={0}
                    control={<Radio />}
                    label={<Typography style={{fontSize: '16px'}}>Items were donated</Typography>}
                  />
                  <FormControlLabel
                    style={{marginLeft: '0px'}}
                    value={1}
                    control={<Radio />}
                    label={<Typography style={{fontSize: '16px'}}>Items were bought</Typography>}
                  />
                </RadioGroup>
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <TextField
                  InputLabelProps={{shrink: true}}
                  className={classes.wrapper}
                  label={po === 0 ? 'Benefactor' : 'Purchase order / ref #'}
                  variant="standard"
                  placeholder={po === 0 ? 'Enter benefactor name' : 'Enter purchase order / ref #'}
                  value={po === 0 ? results?.pvBenefactor : results?.pvPurchaseOrderRefNumber || ''}
                  onChange={(e) => {
                    onResultsChange(
                      ['pvBenefactor', 'pvPurchaseOrderRefNumber'][po],
                      e.target.value
                    );
                  }}
                />
              </div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                <TextField
                  multiline
                  InputLabelProps={{shrink: true}}
                  className={classes.wrapper}
                  required={true}
                  label="Description"
                  variant="standard"
                  placeholder="Enter description"
                  value={results?.pvDescription || ''}
                  onChange={(e) => onResultsChange('pvDescription', e.target.value)}
                />
              </div>
              <div className="layout horizontal center section-title form-margin">
                File Attachments
              </div>
              <div
                className={`${classes.layoutWrapper} layout horizontal wrap`}
                style={{gap: '10px'}}>
                {sFiles.map((file: IFile) => {
                  return (
                    <div
                      key={file?.pvDataID}
                      className={classes.fileAttachementContainer}>
                      <CardComponentDownloadCard
                        className="set-width"
                        icon={
                          <img
                            style={{width: 40, height: 40}}
                            alt=" "
                            src={getFileImage(file)}
                          />
                        }
                        title={
                          <div className="layout vertical">
                            <span className="truncate">
                              {parseFileName(file?.cbrnDataFileName)}
                            </span>
                            <span className="opacity-54">
                              {bytesToSize(Number(file?.cbrnDataFileSize))}
                            </span>
                          </div>
                        }
                        suffix={
                          <IconButton
                            aria-label="Delete"
                            aria-haspopup="true"
                            onClick={(e) => handleDeleteFile(file)}>
                            <MdClear className="icon-2" />
                          </IconButton>
                        }
                      />
                    </div>
                  );
                })}
                <UploadFile
                  previewText="Selected files"
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  onChange={(files) => {
                    addFileToUploadQueue(files);
                  }}
                />
              </div>
              <div className="layout horizontal center section-title form-margin">Items</div>
              <div className={`${classes.layoutWrapper} layout horizontal wrap`}>
                {(results?.items || []).map((item: IItem) => {
                  if (!item?.pvVoid) {
                    return (
                      <Card
                        onClick={(e) => null}
                        variant="outlined"
                        key={item.pvDataID || item.key}
                        className="cursor-pointer"
                        style={{width: 288, margin: 10}}>
                        <CardContent>
                          <div className="layout horizontal">
                            <div className="layout horizontal center flex">
                              <Typography variant="h5">{`Item Type: ${
                                item.pvItemType || ''
                              }`}</Typography>
                            </div>
                            <IconButton onClick={(e) => handleRemoveItem(item)}>
                              <MdClear />
                            </IconButton>
                          </div>
                          <Typography
                            variant="body1"
                            className="truncate">
                            {`Quantity Received: ${item.pvQuantityReceived || ''}`}
                          </Typography>
                          <Typography
                            variant="body1"
                            className="truncate">
                            {`Quantity distributed: ${item.pvQuantityDistributed || 0}`}
                          </Typography>
                          <Typography
                            variant="body1"
                            className="truncate">
                            {`Manufacturer: ${item.pvManufacturer || ''}`}
                          </Typography>
                          <Typography
                            variant="body1"
                            className="truncate">
                            {`Product/Lot #: ${item.pvLotNumber || ''}`}
                          </Typography>
                          <Typography
                            variant="body1"
                            className="truncate">
                            {`Expiration Date: ${toShortDateOnly(
                              item.pvExpirationDate || new Date()
                            )}`}
                          </Typography>
                        </CardContent>
                      </Card>
                    );
                  }
                })}
              </div>
              <div
                className="layout horizontal "
                style={{marginBottom: '10px'}}>
                <div className="flex"></div>
                <SecondaryButton
                  setwidth={width && width >= MOBILE_SIZE ? 'true' : 'false'}
                  onClick={() => {
                    setShowItemDialog(true);
                  }}>
                  ADD ITEMS
                </SecondaryButton>
              </div>
              {results?.pvDataID ? (
                <div className="layout horizontal form-margin">
                  {width && width >= MOBILE_SIZE ? <div className="flex" /> : ''}
                  <DeleteButton
                    setwidth={width && width >= MOBILE_SIZE ? 'true' : 'false'}
                    onClick={() => setShowDialog(true)}>
                    DELETE
                  </DeleteButton>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <FormDialog
          show={showItemDialog}
          title="Add Item"
          handleClose={() => {
            reset(defaultValueReceivedItem);
            setShowItemDialog(false);
          }}
          handleSave={handleSubmit(handleFormSave)}>
          <ReceivedItemDetailsForm
            control={control}
            setValue={setValue}
            readOnly={false}
            pvDataID={params?.pvDataID || ''}
          />
        </FormDialog>
        <YesCancelDialog
          show={showDialog}
          title="Delete Entry Confirmation"
          message="Deleting this entry cannot be undone. Are you sure you want to continue?"
          handleClose={() => setShowDialog(false)}
          handleAgree={() => {
            handleDeleteItemEntry();
          }}
        />
      </div>
    </MainLayout>
  );
};

export default ReceivedEdit;
