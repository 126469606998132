import React from 'react';
import {PalmettoReactTable} from '@zawarski/palmetto-direflow-react-table';

import {ArrowBackButton} from 'components/primitives';
import {TableListProps} from './TableList.props';
import {useNavigate} from 'react-router-dom';
import './style.css';

const TableListView: React.FC<TableListProps> = ({
  auth = {},
  apiBaseUrl = null,
  columns = [],
  idField,
  location,
  reqEntry = 'warehouse',
  reqEndpoint = null,
  subtitlePrefix,
  subtitleSuffix,
  title = null,
  ...props
}) => {
  const navigate = useNavigate();
  const dataUrl = [
    apiBaseUrl || `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api`,
    reqEntry,
    location?.pvDataID,
    reqEndpoint,
  ].filter(Boolean);

  const filterManageColUrl = [
    apiBaseUrl || `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api`,
    reqEntry,
    reqEndpoint,
  ].filter(Boolean);

  const handleNavigate = () => {
    if (props.onBack) props.onBack();
    else navigate('/');
  };

  const handleRowClick = (p: any) => {
    if (props.onRowSelect) props.onRowSelect(p);
  };

  return (
    <PalmettoReactTable
      auth={{
        groupId: location?.pvGroupID,
        wsUrl: process.env.REACT_APP_PALMETTO_WEBSOCKET_CONNECTION,
        ...auth,
      }}
      title={title || location?.pvSiteName || 'View entry'}
      subtitlePrefix={subtitlePrefix || ''}
      subtitleSuffix={subtitleSuffix || 'entries'}
      dataUrl={dataUrl.join('/')}
      filterUrl={[...filterManageColUrl, 'filter'].join('/') + '?'}
      manageColumnsUrl={[...filterManageColUrl, 'manage-columns'].join('/')}
      limit={35}
      columns={columns}
      idField={idField || 'pvDataID'}
      loading={true}
      hideFilter={false}
      extraHeaders={<></>}
      TableProps={{
        headerHeight: 41,
      }}
      headerIcon={<ArrowBackButton onClick={handleNavigate} />}
      onRowClick={handleRowClick}
    />
  );
};

export default TableListView;
