import account from './account';
import app from './app';
import item from './item';
import location from './location';
import token from './token';

export default {
  account,
  app,
  item,
  location,
  token,
};
