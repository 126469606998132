import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {LocationEditProps, LocationPublicProps} from './LocationEdit.props';
import LocationEditView from './LocationEdit.view';
import {ILocation} from 'common/interfaces';
import {Nullable} from 'common/utils';

const LocationEditContainer: React.FC<LocationPublicProps> = (ownProps: LocationPublicProps) => {
  const location = useLocation();
  const appStore = useSelectorSafe((state) => state);
  const gotData = appStore?.location?.data || null;
  const [pvLocation, setpvLocation] = useState<
    Nullable<ILocation> | Partial<ILocation> | ILocation
  >(null);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('new')) {
      setpvLocation(null);
    } else {
      console.log('is Edit');
      setpvLocation(gotData);
    }
  }, [gotData]);

  const combinedProps: LocationEditProps = {
    ...ownProps,
    pvLocation,
  };
  // const dispatch = useDispatch();
  // const navigation = useNavigate();
  //
  // const handleRowSelect = (data: any) => {
  //   dispatch(locationActions.update({ data }));
  //   navigation(`/${data.pvDataID}`);
  // };

  return <LocationEditView {...combinedProps} />;
};

export default LocationEditContainer;
